import React, { Component } from 'react';

// import axios
import axios from 'axios';

// import Calendar
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

// import Sweetalert2
import Swal from 'sweetalert2'

// import Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
    Loader
} from 'rsuite';

export default class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem('token')).token,
            content: [],
            loading: false
        };
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {
        await this.onRenderContent({ read: true })
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    onRenderContent = async (obj) => {

        const CustomHeader = ({ title }) => {
            return <h6 style={{ margin: 0 }}>{title}</h6>;
        };

        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        const handleAuthFailure = (error) => {
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        };

        if (obj.read) {
            try {

                await this.promisedSetState({ loading: true, modal: [] }); // Start loading

                await axios.get(`${process.env.REACT_APP_API}/activities`, authHeader).then(async (response) => {
                    this.data = []
                    await response.data.map(async (item) => {
                        let dateStart = this.formatDate(new Date(`${item.date_period.split(',')[0].split(' ')[1]} ${item.date_period.split(',')[0].split(' ')[2]}, ${item.date_period.split(',')[0].split(' ')[3]} ${item.date_period.split(',')[0].split(' ')[4]}`))

                        let dateEnd = new Date(`${item.date_period.split(',')[1].split(' ')[1]} ${item.date_period.split(',')[1].split(' ')[2]}, ${item.date_period.split(',')[1].split(' ')[3]} ${item.date_period.split(',')[1].split(' ')[4]}`)

                        // Add one day to the end date
                        dateEnd.setDate(dateEnd.getDate() + 1);

                        // Format the updated end date
                        dateEnd = this.formatDate(dateEnd);

                        this.color = ''
                        switch (item.status) {
                            case 'completed':
                            case 'cancel':
                                this.color = '#C0C0C0';
                                break;
                            case 'active':
                                switch (item.type) {
                                    case 'camp':
                                        this.color = '#0d6efd';
                                        break;
                                    case 'trip':
                                        this.color = '#d63384';
                                        break;
                                    case 'workshop':
                                        this.color = '#fd7e14';
                                        break;
                                    default:
                                        this.color = '#000000'; // Default color for unknown types
                                        break;
                                }
                                break;
                            default:
                                this.color = '#000000'; // Default color for unknown statuses
                                break;
                        }

                        this.data.push({
                            id: item.id,
                            title: item.name,
                            start: dateStart,
                            end: dateEnd,
                            color: this.color
                        })
                    })

                    await this.promisedSetState({
                        content: <div>
                            <FullCalendar
                                eventClick={e => window.location = `/activities/details/${e.event.id}`}
                                plugins={[dayGridPlugin]}
                                initialView="dayGridMonth"
                                weekends={true}
                                events={this.data}
                                headerToolbar={{
                                    left: '', // Navigation buttons
                                    center: 'title', // Leave empty to use custom title
                                    right: 'prev,next' // View switcher or other buttons
                                }}
                                titleFormat={{ year: 'numeric', month: 'long' }} // Format the title if needed
                                customButtons={{
                                    customTitle: {
                                        text: 'Custom Title',
                                        click: () => { },
                                    }
                                }}
                                // Render the custom header title
                                eventDidMount={info => {
                                    const headerElement = info.el.closest('.fc-toolbar');
                                    if (headerElement) {
                                        headerElement.querySelector('.fc-toolbar-title').replaceWith(<CustomHeader title={info.view.title} />);
                                    }
                                }}
                            />
                        </div>
                    })

                })

                await this.promisedSetState({ loading: false }); // Stop loading
                
            } catch (error) {
                handleAuthFailure(error);
            }
        }
    }

    render() {
        return (
            <>
                <Row className='mt-3'>
                    <Col>
                        {this.state.content}
                    </Col>
                </Row>
    
                {/* Full-Page Loader */}
                {this.state.loading && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999 // Ensure it’s on top of other content
                    }}>
                        <Loader content="Loading..." vertical />
                    </div>
                )}
            </>
        );
    }    
}