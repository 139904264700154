import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import Rsuite
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import {
    Panel
    , Stack
    , DatePicker
    , InputGroup
    , Loader
} from 'rsuite';

// import Bootstrap
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import axios
import axios from 'axios';

// import Charts
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Pie, Bar } from 'react-chartjs-2';

import MobileDetect from 'mobile-detect';

export default class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem('token')).token,
            content: null,
            start: new Date('2024-01-01T00:00:00Z'),
            end: new Date(),
            loading: false
        };

        // Initialize MobileDetect
        this.md = new MobileDetect(window.navigator.userAgent);

        ChartJS.register(
            ArcElement,
            CategoryScale,
            LinearScale,
            BarElement,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend
        );
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {
        // Add resize listener only for mobile devices
        if (this.md.mobile()) {
            window.addEventListener('orientationchange', this.handleResize);
        } else if (!this.md.mobile()) {
            window.addEventListener('resize', this.handleResize);
        }

        await this.onRenderContent({ read: true });
    }

    handleResize = this.debounce(() => {
        console.log('Window resized');
        this.onRenderContent({ read: true });
    }, 300);

    debounce(func, delay) {
        let timeoutId;
        return (...args) => {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    }

    onRenderContent = async (obj) => {

        if (obj.read) {

            const labels = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
            await this.promisedSetState({ content: null });

            try {

                await this.promisedSetState({ loading: true }); // Start loading

                // Total
                const totalData = await axios.post(`${process.env.REACT_APP_API}/dashboard/total`, {
                    dateStartQuery: this.state.start,
                    dateEndQuery: this.state.end
                }, {
                    headers: {
                        'Authorization': `Bearer ${this.state.token}`
                    }
                });
                const total = totalData.data.count;

                // Payment
                const paymentData = await axios.post(`${process.env.REACT_APP_API}/dashboard/payment`, {
                    dateStartQuery: this.state.start,
                    dateEndQuery: this.state.end
                }, {
                    headers: {
                        'Authorization': `Bearer ${this.state.token}`
                    }
                });
                const payment = paymentData.data.count;

                // Age
                const ageData = await axios.post(`${process.env.REACT_APP_API}/dashboard/age`, {
                    dateStartQuery: this.state.start,
                    dateEndQuery: this.state.end
                }, {
                    headers: {
                        'Authorization': `Bearer ${this.state.token}`
                    }
                });
                const ageLabel = [ageData.data.age_below_9, ageData.data.age_9_to_12, ageData.data.age_13_to_18, ageData.data.age_19_to_35, ageData.data.age_36_to_60, ageData.data.age_above_60];

                // Month
                const monthData = await axios.post(`${process.env.REACT_APP_API}/dashboard/month`, {
                    dateStartQuery: this.state.start,
                    dateEndQuery: this.state.end
                }, {
                    headers: {
                        'Authorization': `Bearer ${this.state.token}`
                    }
                });

                await this.promisedSetState({
                    content: <div>
                        <Row className='mt-4'>
                            <Col sm>
                                <Breadcrumb size='lg' separator={<AngleRightIcon />} >
                                    <Breadcrumb.Item><Link to="/"><i className="bi bi-house-fill"></i></Link></Breadcrumb.Item>
                                    <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col sm>
                                <InputGroup style={{ width: '100%' }}>
                                    <InputGroup.Addon>ตั้งแต่</InputGroup.Addon>
                                    <DatePicker
                                        oneTap
                                        format="dd/MM/yyyy"
                                        block
                                        appearance="subtle"
                                        style={{ width: '50%' }} // Set width to 50%
                                        defaultValue={this.state.start}
                                        onChange={async (e) => {
                                            await this.promisedSetState({ start: e });
                                            await this.onRenderContent({ read: true });
                                        }}
                                    />
                                    <InputGroup.Addon>ถึง</InputGroup.Addon>
                                    <DatePicker
                                        oneTap
                                        format="dd/MM/yyyy"
                                        block
                                        appearance="subtle"
                                        style={{ width: '50%' }} // Set width to 50%
                                        defaultValue={this.state.end}
                                        onChange={async (e) => {
                                            await this.promisedSetState({ end: e });
                                            await this.onRenderContent({ read: true });
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>


                        <Row className='mt-4'>
                            <Col md className='mb-3'>
                                <Panel
                                    header="จำนวนผู้สมัคร"
                                    className='shadow-sm'
                                    style={{ backgroundColor: '#FFF', height: '100%' }}
                                    bordered>
                                    <div>
                                        <Stack direction={'column'}>
                                            <br />
                                            <i style={{ fontSize: '4rem' }} className="bi bi-emoji-smile"></i>
                                            <br />
                                            <div style={{ fontSize: '6rem' }}>
                                                {total}
                                            </div>
                                        </Stack>
                                    </div>
                                </Panel>
                            </Col>
                            <Col md className='mb-3'>
                                <Panel
                                    header="จำนวนผู้เข้าร่วมกิจกรรม"
                                    className='shadow-sm'
                                    style={{ backgroundColor: '#FFF', height: '100%' }}
                                    bordered>
                                    <div>
                                        <Stack direction={'column'}>
                                            <br />
                                            <i style={{ fontSize: '4rem' }} className="bi bi-palette"></i>
                                            <br />
                                            <div style={{ fontSize: '6rem' }}>
                                                {payment}
                                            </div>
                                        </Stack>
                                    </div>
                                </Panel>
                            </Col>
                            <Col xl className='mb-3'>
                                <Panel
                                    header="ช่วงอายุผู้เข้าร่วมกิจกรรม"
                                    className='shadow-sm'
                                    style={{ backgroundColor: '#FFF' }}
                                    bordered>
                                    <Pie data={{
                                        labels: ['ต่ำกว่า 9 ปี', '9-12 ปี', '13-18 ปี', '19-35 ปี', '36-60 ปี', '60 ปีขั้นไป'],
                                        datasets: [{
                                            label: 'ผู้เข้าร่วม',
                                            data: ageLabel,
                                            backgroundColor: [
                                                'rgba(75, 192, 192, 1)',
                                                'rgba(255, 206, 86, 1)',
                                                'rgba(165, 82, 0, 1)',
                                                'rgba(255, 99, 132, 1)',
                                                'rgba(115, 0, 255, 1)',
                                                'rgba(54, 162, 235, 1)',
                                            ],
                                            borderWidth: 2,
                                        },
                                        ],
                                    }} />
                                </Panel>
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col className='mb-3'>
                                <Panel
                                    header="จำนวนผู้สมัครของแต่ละเดือน"
                                    className='shadow-sm'
                                    style={{ backgroundColor: '#FFF' }}
                                    bordered>
                                    <Bar
                                        options={{
                                            responsive: true,
                                            plugins: {
                                                legend: {
                                                    position: 'top',
                                                },
                                            },
                                        }}
                                        data={{
                                            labels, // Ensure this is defined
                                            datasets: [{
                                                type: 'bar',
                                                label: 'จำนวนผู้สมัคร',
                                                data: [
                                                    monthData.data.january || 0,
                                                    monthData.data.february || 0,
                                                    monthData.data.march || 0,
                                                    monthData.data.april || 0,
                                                    monthData.data.may || 0,
                                                    monthData.data.june || 0,
                                                    monthData.data.july || 0,
                                                    monthData.data.august || 0,
                                                    monthData.data.september || 0,
                                                    monthData.data.october || 0,
                                                    monthData.data.november || 0,
                                                    monthData.data.december || 0,
                                                ],
                                                backgroundColor: Array(12).fill('rgba(30,144,255, 1)'),
                                            },
                                            ],
                                        }}

                                    />
                                </Panel>
                            </Col>
                        </Row>

                    </div>
                });

                await this.promisedSetState({ loading: false }); // Stop loading

            } catch (error) {
                if (error.response && error.response.data && error.response.data.message === "AuthenticationFailed") {
                    // Redirect to login page if authentication fails
                    window.location.replace('/login');
                } else {
                    // Handle other errors or display an error message
                    console.error("An error occurred:", error);
                }
            }
        }
    }

    render() {
        return (
            <>
                {this.state.loading && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999 // Ensure it’s on top of other content
                    }}>
                        <Loader content="Loading..." vertical />
                    </div>
                )}
                {this.state.content}
            </>
        );
    }    
}
