import React, { useEffect } from 'react';
import { Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import { Container, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Avatar } from 'rsuite'; // Import Avatar from rsuite

// Banner images
import Banner from '../images/banner_1600x200-02.png';

// Pages
import DashboardPage from './dashboard';
import ActivitiesPage from './activities';
import EditPage from './edit';
import DetailsPage from './details';
import ManagePage from './manage';
import ParticipantsPage from './participants';
import AssessmentPage from './assessment';
import ApplicationPage from './application';
import QuestionsPage from './questions';
import HistoryPage from './history';
import ReportPage from './report';
import AdminPage from './admin';

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Use navigate for redirection

  useEffect(() => {
    // Check if token exists in localStorage
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to login if token is absent
      navigate('/login');
    }
  }, [navigate]);

  const handleSignOut = () => {
    // Remove the token from localStorage
    localStorage.removeItem('token');

    // Redirect to login page
    navigate('/login');
  };

  // Render nothing while redirecting
  if (!localStorage.getItem('token')) {
    return null;
  }

  return (
    <Container>
      <Image src={Banner} fluid />
      <Navbar expand="md" bg="dark" variant="dark">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/">Dashboard</Link>
            <Link className={`nav-link ${location.pathname === '/activities' ? 'active' : ''}`} to="/activities">ข้อมูลกิจกรรม</Link>
            <Link className={`nav-link ${location.pathname === '/application' ? 'active' : ''}`} to="/application">แบบฟอร์มใบสมัคร</Link>
            <Link className={`nav-link ${location.pathname === '/report' ? 'active' : ''}`} to="/report">รายงาน</Link>
            <Link className={`nav-link ${location.pathname === '/admin' ? 'active' : ''}`} to="/admin">ผู้ดูแลระบบ</Link>
          </Nav>
          <Nav className="ms-auto">
            <NavDropdown
              title={
                <span>
                  <Avatar size="sm" circle style={{ marginRight: '8px' }}>
                    <i className="bi bi-person-fill"></i>
                  </Avatar>
                  <span>{JSON.parse(localStorage.getItem('token')).username}</span>
                </span>
              }
            >
              <NavDropdown.Item onClick={handleSignOut}>Sign out</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Routes>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/activities" element={<ActivitiesPage />} />
        <Route path="/activities/:id" element={<EditPage />} />
        <Route path="/activities/details/:id" element={<DetailsPage />} />
        <Route path="/activities/participants/:id" element={<ParticipantsPage />} />
        <Route path="/activities/assessment/:id" element={<AssessmentPage />} />
        <Route path="/application" element={<ApplicationPage />} />
        <Route path="/application/manage/:id" element={<ManagePage />} />
        <Route path="/application/questions" element={<QuestionsPage />} />
        <Route path="/history" element={<HistoryPage />} />
        <Route path="/report" element={<ReportPage />} />
        <Route path="/admin" element={<AdminPage />} />
      </Routes>
    </Container>
  );
};

export default Home;