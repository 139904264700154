import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withRouter from './withRouter';

// import axios
import axios from 'axios';

// Bootstrap
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Rsuite
import {
    IconButton
    , ButtonToolbar
    , Stack
    , Form
    , Table
    , Pagination
    , Text
    , Button
    , Modal
    , Toggle
    , Uploader
    , InputGroup
    , AutoComplete
    , Loader
} from 'rsuite';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';

// import Sweetalert2
import Swal from 'sweetalert2'

import { CSVLink } from "react-csv";

class Participants extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem('token')).token,
            username: JSON.parse(localStorage.getItem('token')).username,
            tables: [],
            searchQuery: [],
            searchDataset: [],
            files: [],
            modal: [],
            sortColumn: '',
            sortType: '',
            tableLoading: false,
            limit: 10,
            page: 1,
            data: [],
            payment: '',
            loading: false
        };
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {
        await this.onRenderContent({ read: true })
    }

    onRenderContent = async (obj) => {
        if (obj.read) {
            const { Column, HeaderCell, Cell } = Table;

            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            const handleAuthFailure = (error) => {
                if (error.response && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: error.message,
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                }
            };

            try {
                // Fetch activities
                const activitiesResponse = await axios.get(`${process.env.REACT_APP_API}/activities/${this.props.params.id}`, authHeader);
                const activities = activitiesResponse.data;

                for (const activity of activities) {
                    // Fetch forms for each activity
                    const formsResponse = await axios.get(`${process.env.REACT_APP_API}/forms/${activity.forms_id}`, authHeader);
                    const forms = formsResponse.data;

                    this.csvData = [];
                    this.arr = [];

                    for (const form of forms) {
                        const questions = JSON.parse(form.forms_json);
                        questions.forEach(questionGroup => {
                            questionGroup.questions.forEach(label => this.arr.push(label.label));
                        });
                    }

                    this.csvData[0] = this.arr;

                    // Fetch participants for each activity
                    const participantsResponse = await axios.post(`${process.env.REACT_APP_API}/participants/activities`, {
                        id: activity.id,
                        searchQuery: this.state.searchQuery
                    }, authHeader);

                    const participants = participantsResponse.data;

                    for (const [index, item] of participants.entries()) {
                        const results = JSON.parse(item.forms_results);

                        this.csvData[index + 1] = results.map(result => {
                            if (result.type === 'address') {
                                const { house_no = '', village_no = '', village = '', lane = '', road = '', tambon, amphur, province } = result.result;
                                return [
                                    house_no ? `บ้านเลขที่ ${house_no}` : '',
                                    village_no ? `หมู่ที่ ${village_no}` : '',
                                    village ? `หมู่บ้าน ${village}` : '',
                                    lane ? `ตรอก/ซอย ${lane}` : '',
                                    road ? `ถนน ${road}` : '',
                                    `ตำบล/แขวง ${tambon}`,
                                    `อำเภอ/เขต ${amphur}`,
                                    `จังหวัด ${province}`
                                ].filter(Boolean).join(' ');
                            }
                            else if (result.type === 'multiple_choice') {

                                return result.result.more && result.result.more[0] !== null ? `${result.result.value}/${result.result.more}` : result.result.value;
                            }
                            else if (result.type === 'checkboxes') {
                                let listItem = [];
                                result.result.forEach(item => {
                                    if (item.value) {
                                        item.value.forEach(name => {
                                            const moreData = result.result
                                                .flatMap(r => r.option
                                                    .filter(option => option.name === name)
                                                    .map(option => option.more))
                                                .filter(more => more); // Filter out undefined or null values

                                            listItem.push(moreData.length && JSON.stringify(moreData[0]) !== '[]' ? `${name}/${moreData.join('/')}` : name);
                                        });
                                    }
                                });
                                return listItem.join(', ');
                            } else if (result.type === 'date') {

                                return this.onDate({ data: result.result });
                            }
                            else {
                                return result.result;
                            }
                        });

                        // Filter and update state
                        await this.promisedSetState(prevState => {
                            const newDataset = [
                                item.id_card_number,
                                JSON.parse(item.prefix).value,
                                item.first_name,
                                item.last_name,
                                item.tambon,
                                item.amphur,
                                item.province,
                                item.tel,
                                item.line,
                            ];

                            const searchDatasetSet = new Set(prevState.searchDataset);
                            const isAlreadyInDataset = newDataset.some(data => searchDatasetSet.has(data));

                            if (isAlreadyInDataset) {
                                return null;
                            } else {
                                return {
                                    searchDataset: [...prevState.searchDataset, ...newDataset]
                                };
                            }
                        });
                    }

                    await this.promisedSetState({
                        data: participants.map((item, index) => ({
                            index: index + 1,
                            id: item.id,
                            prefix: JSON.parse(item.prefix).value,
                            firstName: item.first_name,
                            lastName: item.last_name,
                            age: item.age,
                            province: item.province,
                            tel: item.tel,
                            line: item.line,
                            payment: item.payment === 1 && <i className="bi bi-check-circle-fill text-success"></i>
                        }))
                    });

                    const data = await this.getData();

                    await this.promisedSetState({
                        tables: (
                            <div>
                                <Row className='mt-4'>
                                    <Breadcrumb size='lg' separator={<AngleRightIcon />}>
                                        <Breadcrumb.Item><Link to="/"><i className="bi bi-house-fill"></i></Link></Breadcrumb.Item>
                                        <Breadcrumb.Item><Link to="/activities">ข้อมูลกิจกรรม</Link></Breadcrumb.Item>
                                        <Breadcrumb.Item>{activity.name}</Breadcrumb.Item>
                                        <Breadcrumb.Item active>ข้อมูลผู้สมัคร</Breadcrumb.Item>
                                    </Breadcrumb>
                                </Row>

                                <Form onKeyDown={this.onKeyDownHandler} layout="inline">
                                    <Row>
                                        <Col lg className='mt-2'>
                                            <Stack wrap spacing={6}>
                                                <InputGroup styles={{ width: 300, marginBottom: 10 }} tabIndex={-1}>
                                                    <InputGroup.Addon>
                                                        <i className="bi bi-search"></i>
                                                    </InputGroup.Addon>
                                                    <AutoComplete
                                                        style={{ width: 300 }}
                                                        data={this.state.searchDataset}
                                                        onChange={async e => await this.promisedSetState({ searchQuery: e })} />
                                                </InputGroup>
                                                <Button
                                                    color="blue"
                                                    appearance="primary"
                                                    onClick={async () => await this.onRenderContent({ read: true })}>
                                                    ค้นหา
                                                </Button>
                                            </Stack>
                                        </Col>
                                        <Col lg className='mt-2'>
                                            <Stack className='d-flex justify-content-end' wrap spacing={6}>
                                                <CSVLink data={this.csvData} filename={`${this.randString(5)}.csv`}>
                                                    <Button color="green" appearance="primary" startIcon={<i className="bi bi-table"></i>}>
                                                        Download
                                                    </Button>
                                                </CSVLink>
                                            </Stack>
                                        </Col>
                                    </Row>
                                </Form>

                                <Table
                                    autoHeight
                                    data={data}
                                    bordered
                                    cellBordered
                                    sortColumn={this.state.sortColumn}
                                    sortType={this.state.sortType}
                                    onSortColumn={this.handleSortColumn}
                                    loading={this.state.tableLoading}
                                    className='rounded shadow-sm mt-3'
                                >
                                    <Column width={60} align="center" sortable>
                                        <HeaderCell>ลำดับ</HeaderCell>
                                        <Cell dataKey="index" />
                                    </Column>

                                    <Column width={110} sortable>
                                        <HeaderCell>คำนำหน้า</HeaderCell>
                                        <Cell dataKey="prefix" />
                                    </Column>

                                    <Column width={175} sortable>
                                        <HeaderCell>ชื่อ</HeaderCell>
                                        <Cell dataKey="firstName" />
                                    </Column>

                                    <Column width={170} sortable>
                                        <HeaderCell>นามสกุล</HeaderCell>
                                        <Cell dataKey="lastName" />
                                    </Column>

                                    <Column align='center' width={80} sortable>
                                        <HeaderCell>อายุ</HeaderCell>
                                        <Cell align='center' dataKey="age" />
                                    </Column>

                                    <Column width={140} sortable>
                                        <HeaderCell>จังหวัด</HeaderCell>
                                        <Cell dataKey="province" />
                                    </Column>

                                    <Column width={160}>
                                        <HeaderCell>เบอร์โทรศัพท์</HeaderCell>
                                        <Cell dataKey="tel" />
                                    </Column>

                                    <Column width={160}>
                                        <HeaderCell>Line ID</HeaderCell>
                                        <Cell dataKey="line" />
                                    </Column>

                                    <Column width={80}>
                                        <HeaderCell>ชำระเงิน</HeaderCell>
                                        <Cell dataKey="payment" align='center' />
                                    </Column>

                                    <Column align='center' width={160} fixed="right">
                                        <HeaderCell>...</HeaderCell>

                                        <Cell align='center' style={{ padding: '6px' }}>
                                            {rowData => (
                                                <>
                                                    <ButtonToolbar>
                                                        <Button
                                                            color="violet"
                                                            appearance="primary"
                                                            startIcon={<i className="bi bi-wallet2"></i>}
                                                            onClick={() => this.onModal({ method: 'update', id: rowData.id })} >
                                                            ชำระเงิน
                                                        </Button>
                                                        <IconButton
                                                            size='sm'
                                                            color="red"
                                                            appearance="primary"
                                                            icon={<i className="bi bi-trash3-fill"></i>}
                                                            onClick={() => this.onRemove({ id: rowData.id })} />
                                                    </ButtonToolbar>
                                                </>
                                            )}
                                        </Cell>
                                    </Column>
                                </Table>
                                <div style={{ padding: 20 }}>
                                    <Pagination
                                        prev
                                        next
                                        first
                                        last
                                        ellipsis
                                        boundaryLinks
                                        maxButtons={5}
                                        size="xs"
                                        layout={['total', '|', 'pager']}
                                        total={this.state.data.length}
                                        limit={this.state.limit}
                                        activePage={this.state.page}
                                        onChangePage={(e) => this.setState({ page: e })}
                                    />
                                </div>
                            </div>
                        )
                    });
                }
            } catch (error) {
                handleAuthFailure(error);
            }
        }
    };

    onDate = ({ data }) => {
        const date = new Date(data);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    onRemove = async ({ id }) => {

        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        const handleAuthFailure = (error) => {
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        };

        // Display confirmation dialog
        Swal.fire({
            title: "คุณต้องการลบผู้สมัครใช่หรือไม่?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            cancelButtonText: `<div style="font-size: 1.2rem">ยกเลิก</div>`
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // Perform the delete request
                    const response = await axios.delete(`${process.env.REACT_APP_API}/participants/${id}`, authHeader);

                    // Check for success response
                    if (response.data.message === 'successfully') {
                        Swal.fire({
                            title: "ลบข้อมูลเรียบร้อยแล้ว!",
                            icon: "success",
                            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                        }).then(async () => {
                            await this.promisedSetState({ modal: [] }); // Update state within the same block
                            await this.onRenderContent({ read: true });
                        });

                        // Optionally, you may want to refresh the data or state here
                        // await this.onRenderContent({ read: true });
                    }
                } catch (error) {
                    // Handle errors, including authentication failures
                    handleAuthFailure(error);
                }
            }
        });
    };

    randString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    onModal = async (obj) => {
        if (obj.method === 'update') {

            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            const handleAuthFailure = (error) => {
                if (error.response && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: error.message,
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                }
            };

            try {
                // Fetch payment files
                const filesResponse = await axios.get(`${process.env.REACT_APP_API}/payment/participants/${obj.id}`, authHeader);

                // Set files empty
                this.setState({ files: [] });

                // List default files
                this.defaultFileList = [];
                filesResponse.data.forEach(item => {
                    this.defaultFileList.push({
                        name: item.originalname,
                        fileKey: item.id,
                        url: `${process.env.REACT_APP_UPLOAD}/${item.path}`
                    });

                    this.setState(prevState => ({
                        files: [...prevState.files, {
                            files: {
                                mode: 'reading',
                                file_id: item.id,
                                participants_id: item.participants_id,
                                key: item.id,
                                name: item.filename,
                                mimetype: item.mimetype,
                                file_data: null
                            }
                        }]
                    }));
                });

                // Fetch participant details
                const participantResponse = await axios.get(`${process.env.REACT_APP_API}/participants/${obj.id}`, authHeader);

                participantResponse.data.forEach(item => {
                    this.setState({ payment: item.payment });
                    this.setState({
                        modal: (
                            <Modal size='sm'
                                backdrop={'static'}
                                open={true}
                                onClose={() => this.setState({ modal: [], files: [] })} >
                                <Modal.Header>
                                    <Modal.Title>ชำระเงิน</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Stack spacing={10} childrenRenderMode="clone">
                                        <label>สถานะ : </label>
                                        {item.payment === 1 ? (
                                            <Toggle
                                                defaultChecked
                                                checkedChildren="จ่ายแล้ว"
                                                unCheckedChildren="รอจ่าย"
                                                onChange={(e) => this.setState({ payment: e })} />
                                        ) : (
                                            <Toggle
                                                checkedChildren="จ่ายแล้ว"
                                                unCheckedChildren="รอจ่าย"
                                                onChange={(e) => this.setState({ payment: e })} />
                                        )}
                                    </Stack>

                                    <label className='mt-4'>อัปโหลดไฟล์ : </label>
                                    <Uploader
                                        action={`${process.env.REACT_APP_API}/participants/files`}
                                        fileList={this.defaultFileList}
                                        draggable
                                        renderFileInfo={(file, fileElement) => (
                                            <>
                                                {file.url ? (
                                                    <Stack wrap spacing={6}>
                                                        <Text onClick={() => this.onPreview({ url: file.url, type: file.url.split('.').pop() })} color="blue">
                                                            {file.name}
                                                        </Text>
                                                        <IconButton
                                                            size='sm'
                                                            circle
                                                            icon={<i className="bi bi-download"></i>}
                                                            appearance="default"
                                                            onClick={() => this.onDownload({ url: file.url, fileName: file.name })} />
                                                    </Stack>
                                                ) : (
                                                    <Stack wrap spacing={6}>
                                                        {file.name}
                                                    </Stack>
                                                )}
                                            </>
                                        )}
                                        onSuccess={(o, e) => this.onUpload({
                                            method: 'insert',
                                            key: e.fileKey,
                                            response: o,
                                            event: e
                                        })}
                                        onRemove={(e) => this.onUpload({
                                            method: 'delete',
                                            key: e.fileKey
                                        })}
                                    >
                                        <div style={{ height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span>คลิ๊กหรือลากไฟล์เพื่ออัปโหลด</span>
                                        </div>
                                    </Uploader>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        color="blue"
                                        appearance="primary"
                                        startIcon={<i className="bi bi-floppy2-fill"></i>}
                                        className='ps-2 pe-2'
                                        onClick={() => this.onPayment({
                                            id: obj.id,
                                            payment: this.state.payment,
                                            participants_id: item.id,
                                            activity_id: item.activity_id,
                                            id_card_number: item.id_card_number
                                        })}
                                    >
                                        บันทึก
                                    </Button>
                                    <Button
                                        color="red"
                                        appearance="subtle"
                                        startIcon={<i className="bi bi-trash3-fill"></i>}
                                        className='ps-2 pe-2'
                                        onClick={() => this.setState({ modal: [], files: [] })}
                                    >
                                        ยกเลิก
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        )
                    });
                });

            } catch (error) {
                // Handle errors, including authentication failures
                handleAuthFailure(error);
            }
        }
    };

    onPreview = async (obj) => {
        if (obj.type === 'jpg' || obj.type === 'jpeg' || obj.type === 'png' || obj.type === 'gif') {
            await this.promisedSetState({
                preview: <Modal size='md' open={true} onClose={() => this.setState({ preview: [] })}>
                    <Modal.Header>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Image width='100%' height='100%' src={obj.url} rounded />
                    </Modal.Body>
                </Modal>
            })
        } else if (obj.type === 'pdf') {
            await this.promisedSetState({
                preview: <Modal size='md' open={true} onClose={() => this.setState({ preview: [] })}>
                    <Modal.Header>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <iframe
                            src={obj.url}
                            width='100%'
                            height='500'
                            title={`${obj.url}`} // Ensure this title is unique and descriptive
                        ></iframe>
                    </Modal.Body>
                </Modal>
            })
        } else {
            Swal.fire({
                icon: "warning",
                title: "ไม่สามารถ Preview",
                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                timer: 1500
            })
        }
    }

    onDownload = (obj) => {
        fetch(obj.url)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = obj.fileName || "downloaded-file";
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    }

    onUpload = (obj) => {
        if (obj.method === 'insert') {
            this.setState(prevState => ({
                files: [...prevState.files, {
                    files: {
                        mode: 'inserting',
                        file_id: null,
                        key: obj.key,
                        name: obj.event.name,
                        mimetype: obj.response.mimetype,
                        file_data: obj.response.buffer
                    }
                }]
            }))
        } else if (obj.method === 'delete') {
            this.state.files
                .filter(res => res.files.key === obj.key)
                .map(item => item.files.mode = 'deleting')
        }
    }

    bufferToBlob = (obj) => {
        const byteArray = new Uint8Array(obj.data)
        return new Blob([byteArray], { type: obj.mimetype })
    }

    onPayment = async (obj) => {

        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        const handleAuthFailure = (error) => {
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        };

        try {

            // await this.promisedSetState({ modal: [] }); // Update state within the same block
            await this.promisedSetState({ loading: true, modal: [] }); // Start loading

            // Update payment status
            const updateResponse = await axios.put(`${process.env.REACT_APP_API}/participants/payment`, {
                id: obj.id
                , payment: obj.payment
                , username: this.state.username
            }, authHeader);

            if (updateResponse.data.message === 'successfully') {
                if (this.state.files.length > 0) {
                    let message = 0;
                    for (let i = 0; i < this.state.files.length; i++) {
                        const file = this.state.files[i].files;

                        if (file.mode === 'inserting') {
                            const formData = new FormData();
                            formData.append('participants_id', obj.participants_id);
                            formData.append('activity_id', obj.activity_id);
                            formData.append('id_card_number', obj.id_card_number);
                            formData.append('file_participants_id', file.participants_id);
                            formData.append('file_key', file.key);
                            formData.append('file_name', file.name);
                            formData.append('file_data', this.bufferToBlob({ mimetype: file.mimetype, data: file.file_data.data }));
                            formData.append('username', this.state.username);

                            try {
                                const uploadResponse = await axios.post(`${process.env.REACT_APP_API}/participants/uploads`, formData, authHeader);
                                if (uploadResponse.data.message === 'successfully') {
                                    message++;
                                }
                            } catch (error) {
                                console.log(error);
                            }
                        } else if (file.mode === 'deleting' && file.file_id !== null) {
                            try {
                                const deleteResponse = await axios.delete(`${process.env.REACT_APP_API}/payment/${file.file_id}`, authHeader);
                                if (deleteResponse.data.message === 'successfully') {
                                    message++;
                                }
                            } catch (error) {
                                console.log(error);
                            }
                        } else {
                            message++;
                        }
                    }

                    if (message === this.state.files.length) {

                        await this.promisedSetState({ loading: false }); // Stop loading

                        Swal.fire({
                            title: "คุณอัพเดทข้อมูลเรียบร้อยแล้ว",
                            icon: "success",
                            timer: 2000,
                            timerProgressBar: true,
                            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                        }).then(async () => {

                            await this.onRenderContent({ read: true });
                        });
                    }
                } else {

                    await this.promisedSetState({ loading: false }); // Stop loading

                    await this.promisedSetState({ modal: [] }); // Update state within the same block

                    Swal.fire({
                        title: "คุณอัพเดทข้อมูลเรียบร้อยแล้ว",
                        icon: "success",
                        timer: 2000,
                        timerProgressBar: true,
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    }).then(async () => {

                        await this.onRenderContent({ read: true });
                    });
                }
            }
        } catch (error) {
            // Handle errors, including authentication failures
            handleAuthFailure(error);
        }
    };

    onKeyDownHandler = async e => {
        if (e.keyCode === 13) {
            await this.onRenderContent({ read: true })
        }
    };

    handleSortColumn = (sortColumn, sortType) => {
        this.setState({ tableLoading: true })
        setTimeout(() => {
            this.setState({ tableLoading: false })
            this.setState({ sortColumn: sortColumn })
            this.setState({ sortType: sortType })
        }, 500);
    };

    getData = async () => {
        if (this.state.sortColumn && this.state.sortType) {
            return this.state.data.sort((a, b) => {
                let x = a[this.state.sortColumn];
                let y = b[this.state.sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (this.state.sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }

        return this.state.data.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });
    };

    render() {
        return (
            <>
                {this.state.tables}
                {this.state.modal}
                {this.state.preview}
    
                {/* Full-Page Loader */}
                {this.state.loading && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999 // Ensure it’s on top of other content
                    }}>
                        <Loader content="Loading..." vertical />
                    </div>
                )}
            </>
        );
    }    
}

export default withRouter(Participants);