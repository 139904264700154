import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import axios
import axios from 'axios';

// import $
import $ from 'jquery';

// import Rsuite
import {
    Form
    , Stack
    , Modal
    , Button
    , Table
    , Pagination
    , AutoComplete
    , Input
    , InputGroup
    , ButtonToolbar
    , IconButton
    , Tooltip
    , Whisper
    , Text
    , Loader
} from 'rsuite';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';

// import Bootstrap
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import Sweetalert2
import Swal from 'sweetalert2'

export default class History extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem('token')).token,
            Breadcrumb: null,
            modal: null,
            tableContent: [],
            searchContent: [],
            searchDataset: [],
            typeQuery: 0,
            applicationQuery: 0,
            searchQuery: '',
            dateStartQuery: '',
            dateEndQuery: '',
            sortColumn: '',
            sortType: '',
            userLoading: false,
            limit: 10,
            page: 1,
            data: [],
            visiblePassword: false,
            visibleCpassword: false,
            isPasswordchange: false,
            name: '',
            tel: '',
            username: '',
            password: '',
            cpassword: '',
            logs: null,
            logsData: [],
            logsSortColumn: '',
            logsSortType: '',
            logsLoading: false,
            logsLimit: 10,
            logsPage: 1,
            loading: false
        };
    }

    async componentDidMount() {
        await this.onRenderContent({ read: true })
        await this.onLogs({ read: true })
    }

    promisedSetState(newState) {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve();
            });
        });
    }

    onRenderContent = async (obj) => {

        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        const handleAuthFailure = (error) => {
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        };

        if (obj.read) {
            const { Column, HeaderCell, Cell } = Table;

            try {
                const searchResponse = await axios.get(`${process.env.REACT_APP_API}/users/search`, authHeader);

                // Breadcrumb
                await this.promisedSetState({
                    Breadcrumb: (
                        <Row className='mt-4'>
                            <Breadcrumb size='lg' separator={<AngleRightIcon />}>
                                <Breadcrumb.Item><Link to="/"><i className="bi bi-house-fill"></i></Link></Breadcrumb.Item>
                                <Breadcrumb.Item active>ผู้ดูแลระบบ</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    )
                });

                // Search empty
                await this.promisedSetState({ searchDataset: [] });

                // Populate searchDataset
                const newDataset = searchResponse.data.rows.flatMap(item => [
                    {
                        label: item.name,
                        value: item.name.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;"),
                    },
                    {
                        label: item.username,
                        value: item.username
                    }
                ]);

                await this.promisedSetState(prevState => {
                    // Create a Set for existing values for quick lookup
                    const existingValues = new Set(prevState.searchDataset.map(ds => ds.value));

                    // Filter out duplicates
                    const filteredDataset = newDataset.filter(ds => !existingValues.has(ds.value));

                    return {
                        searchDataset: [
                            ...prevState.searchDataset,
                            ...filteredDataset
                        ]
                    };
                });


                await this.promisedSetState({
                    searchContent: (<>
                        <Row className='mt-2'>
                            <Col sm>
                                <Button size='sm'
                                    color="green"
                                    appearance="primary"
                                    startIcon={<i className="bi bi-plus-lg"></i>}
                                    onClick={() => this.onModal({ mode: 'insert', visible: false })}
                                >
                                    เพิ่มสมาชิก
                                </Button>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Form onKeyDown={this.onKeyDownHandler} layout="inline">
                                <Form.Group>
                                    <InputGroup styles={{ width: 300, marginBottom: 10 }} tabIndex={-1}>
                                        <InputGroup.Addon>
                                            <i className="bi bi-search"></i>
                                        </InputGroup.Addon>
                                        <AutoComplete
                                            onChange={async e => await this.promisedSetState({ searchQuery: e.replace(/&amp;/g, "&").replace(/&gt;/g, ">").replace(/&lt;/g, "<").replace(/&quot;/g, '"') })}
                                            style={{ width: 300 }}
                                            data={this.state.searchDataset.map(item => ({ label: item.label, value: item.value }))}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Button
                                    color="blue"
                                    appearance="primary"
                                    onClick={async () => await this.onRenderContent({ read: true })}
                                >
                                    ค้นหา
                                </Button>
                            </Form>
                        </Row>
                    </>
                    )
                });

                const dataResponse = await axios.post(`${process.env.REACT_APP_API}/users/search`, {
                    searchQuery: this.state.searchQuery
                }, authHeader);

                // Clear current state
                // await this.promisedSetState({ tableContent: [], data: [] });

                // Process data
                const processedData = await Promise.all(dataResponse.data.map(async (item, index) => {
                    // Parse the date string to a Date object
                    const date = new Date(item.latest_access);

                    // Extract components
                    const day = String(date.getUTCDate()).padStart(2, '0');
                    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
                    const year = date.getUTCFullYear() + 543;
                    const hours = String(date.getUTCHours()).padStart(2, '0');
                    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
                    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

                    // Format the date
                    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

                    return {
                        index: index + 1,
                        id: item.id,
                        name: item.name,
                        username: item.username,
                        tel: item.tel,
                        time: formattedDate,
                    };
                }));

                // Set state with processed data
                await this.promisedSetState({ data: processedData });

                const data = await this.getData()

                // Set up table content
                await this.promisedSetState({
                    name: '',
                    tel: '',
                    username: '',
                    password: '',
                    cpassword: '',
                    tableContent: (
                        <div>
                            <Row>
                                <Col sm>
                                    <Table
                                        autoHeight
                                        data={data}
                                        bordered
                                        cellBordered
                                        sortColumn={this.state.sortColumn}
                                        sortType={this.state.sortType}
                                        onSortColumn={this.handleSortColumn}
                                        loading={this.state.userLoading}
                                        className='rounded shadow-sm'
                                    >
                                        <Column width={60} align="center" sortable>
                                            <HeaderCell>ลำดับ</HeaderCell>
                                            <Cell dataKey="index" />
                                        </Column>

                                        <Column width={330} sortable>
                                            <HeaderCell>ชื่อ - นามสกุล</HeaderCell>
                                            <Cell dataKey="name" />
                                        </Column>

                                        <Column width={280} sortable>
                                            <HeaderCell>Username</HeaderCell>
                                            <Cell dataKey="username" />
                                        </Column>

                                        <Column width={235} sortable>
                                            <HeaderCell>เบอร์โทรศัพท์</HeaderCell>
                                            <Cell dataKey="tel" />
                                        </Column>

                                        <Column width={300} sortable>
                                            <HeaderCell>เข้าใช้งานล่าสุด</HeaderCell>
                                            <Cell dataKey="time" />
                                        </Column>

                                        <Column align='center' width={90} fixed="right">
                                            <HeaderCell>...</HeaderCell>
                                            <Cell align='center' style={{ padding: '6px' }}>
                                                {rowData => (
                                                    <ButtonToolbar>
                                                        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>แก้ไข</Tooltip>}>
                                                            <IconButton
                                                                size='sm'
                                                                color="yellow"
                                                                appearance="primary"
                                                                icon={<i className="bi bi-pencil-square"></i>}
                                                                onClick={async () => {
                                                                    await this.promisedSetState({ isPasswordchange: false });
                                                                    await this.onModal({ mode: 'update', id: rowData.id, visible: false });
                                                                }} />
                                                        </Whisper>
                                                        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>ลบ</Tooltip>}>
                                                            <IconButton
                                                                size='sm'
                                                                color="red"
                                                                appearance="primary"
                                                                icon={<i className="bi bi-trash3-fill"></i>}
                                                                onClick={() => this.onRemove({ mode: 'delete', id: rowData.id })} />
                                                        </Whisper>
                                                    </ButtonToolbar>
                                                )}
                                            </Cell>
                                        </Column>
                                    </Table>
                                    <div style={{ padding: 20 }}>
                                        <Pagination
                                            prev
                                            next
                                            first
                                            last
                                            ellipsis
                                            boundaryLinks
                                            maxButtons={5}
                                            size="xs"
                                            layout={['total', '|', 'pager']}
                                            total={this.state.data.length}
                                            limit={this.state.limit}
                                            activePage={this.state.page}
                                            onChangePage={async (e) => {
                                                await this.promisedSetState({ page: e })
                                                await this.onRenderContent({ read: true })
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )
                });

            } catch (error) {
                handleAuthFailure(error);
            }
        }
    };

    onLogs = async (obj) => {

        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        const handleAuthFailure = (error) => {
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        };

        if (obj.read) {
            const { Column, HeaderCell, Cell } = Table;

            try {
                // Logs table
                const logsResponse = await axios.get(`${process.env.REACT_APP_API}/logs`, authHeader);

                // Clear current state
                // await this.promisedSetState({ logs: [], logsData: [] });

                // Process data
                const processedLogsData = await Promise.all(logsResponse.data.map(async (item, index) => {
                    // Parse the date string to a Date object
                    const date = new Date(item.time_access);

                    // Extract components
                    const day = String(date.getUTCDate()).padStart(2, '0');
                    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
                    const year = date.getUTCFullYear() + 543;
                    const hours = String(date.getUTCHours()).padStart(2, '0');
                    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
                    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

                    // Format the date
                    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

                    return {
                        id: item.id,
                        username: item.username,
                        time_access: formattedDate,
                    };
                }));

                // Set state with processed data
                await this.promisedSetState({ logsData: processedLogsData });

                const logsData = await this.getLogsData()

                // Set up table content
                await this.promisedSetState({
                    logs: <div>
                        <Row className='mt-3'>
                            <Col sm>
                                <Text>ประวัติการเข้าใช้ระบบ</Text>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col sm>
                                <Table
                                    autoHeight
                                    data={logsData}
                                    bordered
                                    cellBordered
                                    sortColumn={this.state.logsSortColumn}
                                    sortType={this.state.logsSortType}
                                    onSortColumn={this.logsHandleSortColumn}
                                    loading={this.state.logsLoading}
                                    className='rounded shadow-sm'
                                >
                                    <Column width={200} align="center" sortable>
                                        <HeaderCell>ครั้งที่</HeaderCell>
                                        <Cell dataKey="id" />
                                    </Column>

                                    <Column width={420} sortable>
                                        <HeaderCell>Username</HeaderCell>
                                        <Cell dataKey="username" />
                                    </Column>

                                    <Column width={675} sortable>
                                        <HeaderCell>วันที่และเวลา เข้าใช้ระบบ</HeaderCell>
                                        <Cell dataKey="time_access" />
                                    </Column>

                                </Table>
                                <div style={{ padding: 20 }}>
                                    <Pagination
                                        prev
                                        next
                                        first
                                        last
                                        ellipsis
                                        boundaryLinks
                                        maxButtons={5}
                                        size="xs"
                                        layout={['total', '|', 'pager']}
                                        total={this.state.logsData.length}
                                        limit={this.state.logsLimit}
                                        activePage={this.state.logsPage}
                                        onChangePage={async (e) => {
                                            await this.promisedSetState({ logsPage: e })
                                            await this.onLogs({ read: true })
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                });

            } catch (error) {
                handleAuthFailure(error);
            }
        }
    };

    onModal = async (obj) => {
        if (obj.mode === 'insert' && obj.visible === false) {
            await this.promisedSetState({
                name: '', tel: '', username: '', password: '', cpassword: '',
                modal: <Modal size='sm' backdrop={'static'} open={true} onClose={() => this.setState({ modal: [] })}>
                    <Modal.Header>
                        <Modal.Title>สมาชิก</Modal.Title>
                    </Modal.Header>
                    <hr />
                    <Modal.Body>
                        <Container>
                            <Form fluid>
                                <Row>
                                    <Col sm>
                                        <Form.Group>
                                            <Form.ControlLabel><b>ชื่อ - นามสกุล</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                            <Input
                                                className='0ZWTS'
                                                size='md'
                                                onChange={(e) => this.setState({ name: e })} />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm>
                                        <Form.Group>
                                            <Form.ControlLabel><b>เบอร์โทรศัพท์</b></Form.ControlLabel>
                                            <Input
                                                className='LJpHG'
                                                size='md'
                                                onChange={(e) => this.setState({ tel: e })} />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <br />

                                <Row className='mt-3'>
                                    <Col sm>
                                        <Form.Group>
                                            <Form.ControlLabel><b>Username</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                            <Input
                                                className='6ZJn2'
                                                size='md'
                                                onChange={(e) => this.setState({ username: e })} />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm>
                                        <Form.Group>
                                            <Form.ControlLabel><b>Password</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                            <InputGroup inside >
                                                <Input
                                                    className='Bj2BC'
                                                    type={this.state.visiblePassword ? 'text' : 'password'}
                                                    onChange={(e) => this.setState({ password: e })} />
                                                <InputGroup.Button onClick={() => this.visiblePassword({ type: 'password', mode: obj.mode, id: obj.id })}>
                                                    {this.state.visiblePassword ? <i className="bi bi-eye-fill"></i> : <i className="bi bi-eye-slash-fill"></i>}
                                                </InputGroup.Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm>
                                        <Form.Group>
                                            <Form.ControlLabel><b>Confirm Password</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                            <InputGroup inside >
                                                <Input
                                                    className='FgQPv'
                                                    type={this.state.visibleCpassword ? 'text' : 'password'}
                                                    onChange={(e) => this.setState({ cpassword: e })} />
                                                <InputGroup.Button onClick={() => this.visiblePassword({ type: 'cpassword', mode: obj.mode, id: obj.id })}>
                                                    {this.state.visibleCpassword ? <i class="bi bi-eye-fill"></i> : <i class="bi bi-eye-slash-fill"></i>}
                                                </InputGroup.Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm>
                                        <Text color="red">&#8226; รหัสผ่านประกอบด้วยตัวอักษรภาษาอังกฤษ ตัวเลข และสัญลักษณ์เท่านั้น</Text>
                                        <Text color="red">&#8226; รหัสผ่านต้องมีความยาวระหว่าง 6 ถึง 18 ตัวอักษร</Text>
                                        <Text color="red">&#8226; รหัสผ่านต้องมีหมายเลขอย่างน้อยหนึ่งตัว</Text>
                                        <Text color="red">&#8226; รหัสผ่านต้องมีสัญลักษณ์อย่างน้อยหนึ่งตัว</Text>
                                    </Col>
                                </Row>

                            </Form>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer className='me-4 p-2'>
                        <Stack className='d-flex justify-content-end'
                            wrap
                            spacing={12}>
                            <Button
                                color="blue"
                                appearance="primary"
                                startIcon={<i class="bi bi-floppy2-fill"></i>}
                                className='ps-2 pe-2'
                                onClick={() => this.onConfirm({ mode: 'insert' })} >
                                บันทึก
                            </Button>
                            <Button
                                color="red"
                                appearance="subtle"
                                startIcon={<i class="bi bi-x-square"></i>}
                                className='ps-2 pe-2'
                                onClick={() => this.setState({ modal: [] })}>
                                ยกเลิก
                            </Button>
                        </Stack>
                    </Modal.Footer>
                </Modal>
            })
        } else if (obj.mode === 'update' && obj.visible === false) {
            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            const handleAuthFailure = (error) => {
                if (error.response && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: error.message,
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                }
            };

            try {
                axios.get(`${process.env.REACT_APP_API}/users/${obj.id}`, authHeader).then(async response => {

                    await response.data.map(async data => {

                        await this.promisedSetState({
                            name: data.name, tel: data.tel, username: data.username, password: '', cpassword: '',
                            modal: <Modal size='sm' backdrop={'static'} open={true} onClose={() => this.setState({ modal: [] })}>
                                <Modal.Header>
                                    <Modal.Title>สมาชิก</Modal.Title>
                                </Modal.Header>
                                <hr />
                                <Modal.Body>
                                    <Container>
                                        <Form fluid>
                                            <Row>
                                                <Col sm>
                                                    <Form.Group>
                                                        <Form.ControlLabel><b>ชื่อ - นามสกุล</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                                        <Input
                                                            className='0ZWTS'
                                                            defaultValue={data.name}
                                                            size='md'
                                                            onChange={(e) => this.setState({ name: e })} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className='mt-3'>
                                                <Col sm>
                                                    <Form.Group>
                                                        <Form.ControlLabel><b>เบอร์โทรศัพท์</b></Form.ControlLabel>
                                                        <Input
                                                            className='LJpHG'
                                                            defaultValue={data.tel}
                                                            size='md'
                                                            onChange={(e) => this.setState({ tel: e })} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <br />

                                            <Row className='mt-3'>
                                                <Col sm>
                                                    <Form.Group>
                                                        <Form.ControlLabel><b>Username</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                                        <Input
                                                            className='6ZJn2'
                                                            defaultValue={data.username}
                                                            readOnly
                                                            disabled
                                                            size='md'
                                                            onChange={(e) => this.setState({ username: e })} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            {this.state.isPasswordchange === false &&
                                                <Row className='mt-3'>
                                                    <Col sm>
                                                        <Form.Group>
                                                            <Button color="red"
                                                                appearance="primary"
                                                                startIcon={<i class="bi bi-key-fill"></i>}
                                                                onClick={async () => {
                                                                    await this.promisedSetState({ isPasswordchange: true })
                                                                    await this.onModal({ mode: 'update', id: obj.id, visible: false })
                                                                }} >
                                                                เปลี่ยนรหัสผ่าน
                                                            </Button>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>}

                                            {this.state.isPasswordchange === true &&
                                                <>
                                                    <Row className='mt-3'>
                                                        <Col sm>
                                                            <Form.Group>
                                                                <Form.ControlLabel><b>Password</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                                                <InputGroup inside >
                                                                    <Input
                                                                        className='Bj2BC'
                                                                        type={this.state.visiblePassword ? 'text' : 'password'}
                                                                        onChange={(e) => this.setState({ password: e })} />
                                                                    <InputGroup.Button onClick={() => this.visiblePassword({ type: 'password', mode: obj.mode, id: obj.id })}>
                                                                        {this.state.visiblePassword ? <i class="bi bi-eye-fill"></i> : <i class="bi bi-eye-slash-fill"></i>}
                                                                    </InputGroup.Button>
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row className='mt-3'>
                                                        <Col sm>
                                                            <Form.Group>
                                                                <Form.ControlLabel><b>Confirm Password</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                                                <InputGroup inside >
                                                                    <Input
                                                                        className='FgQPv'
                                                                        type={this.state.visibleCpassword ? 'text' : 'password'}
                                                                        onChange={(e) => this.setState({ cpassword: e })} />
                                                                    <InputGroup.Button onClick={() => this.visiblePassword({ type: 'cpassword', mode: obj.mode, id: obj.id })}>
                                                                        {this.state.visibleCpassword ? <i class="bi bi-eye-fill"></i> : <i class="bi bi-eye-slash-fill"></i>}
                                                                    </InputGroup.Button>
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row className='mt-3'>
                                                        <Col sm>
                                                            <Text color="red">&#8226; รหัสผ่านประกอบด้วยตัวอักษรภาษาอังกฤษ ตัวเลข และสัญลักษณ์เท่านั้น</Text>
                                                            <Text color="red">&#8226; รหัสผ่านต้องมีความยาวระหว่าง 6 ถึง 18 ตัวอักษร</Text>
                                                            <Text color="red">&#8226; รหัสผ่านต้องมีหมายเลขอย่างน้อยหนึ่งตัว</Text>
                                                            <Text color="red">&#8226; รหัสผ่านต้องมีสัญลักษณ์อย่างน้อยหนึ่งตัว</Text>
                                                        </Col>
                                                    </Row>
                                                </>}

                                        </Form>
                                    </Container>
                                </Modal.Body>
                                <Modal.Footer className='me-4 p-2'>
                                    <Stack className='d-flex justify-content-end'
                                        wrap
                                        spacing={12}>
                                        <Button
                                            color="blue"
                                            appearance="primary"
                                            startIcon={<i class="bi bi-floppy2-fill"></i>}
                                            className='ps-2 pe-2'
                                            onClick={() => this.onConfirm({ mode: 'update', id: obj.id })} >
                                            บันทึก
                                        </Button>
                                        <Button
                                            color="red"
                                            appearance="subtle"
                                            startIcon={<i class="bi bi-x-square"></i>}
                                            className='ps-2 pe-2'
                                            onClick={() => this.setState({ modal: [] })}>
                                            ยกเลิก
                                        </Button>
                                    </Stack>
                                </Modal.Footer>
                            </Modal>
                        })
                    })
                })
            } catch (error) {
                handleAuthFailure(error);
            }
        } else if (obj.mode && obj.visible === true) {
            await this.promisedSetState({
                name: this.state.name, tel: this.state.tel, username: this.state.username, password: this.state.password, cpassword: this.state.cpassword,
                modal: <Modal size='sm' backdrop={'static'} open={true} onClose={() => this.setState({ modal: [] })}>
                    <Modal.Header>
                        <Modal.Title>สมาชิก</Modal.Title>
                    </Modal.Header>
                    <hr />
                    <Modal.Body>
                        <Container>
                            <Form fluid>
                                <Row>
                                    <Col sm>
                                        <Form.Group>
                                            <Form.ControlLabel><b>ชื่อ - นามสกุล</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                            <Input
                                                className='0ZWTS'
                                                defaultValue={this.state.name}
                                                size='md'
                                                onChange={(e) => this.setState({ name: e })} />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm>
                                        <Form.Group>
                                            <Form.ControlLabel><b>เบอร์โทรศัพท์</b></Form.ControlLabel>
                                            <Input
                                                className='LJpHG'
                                                defaultValue={this.state.tel}
                                                size='md'
                                                onChange={(e) => this.setState({ tel: e })} />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <br />

                                <Row className='mt-3'>
                                    <Col sm>
                                        <Form.Group>
                                            <Form.ControlLabel><b>Username</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                            <Input
                                                disabled={obj.mode === 'update'}
                                                className='6ZJn2'
                                                defaultValue={this.state.username}
                                                size='md'
                                                onChange={(e) => this.setState({ username: e })} />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm>
                                        <Form.Group>
                                            <Form.ControlLabel><b>Password</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                            <InputGroup inside >
                                                <Input
                                                    className='Bj2BC'
                                                    defaultValue={this.state.password}
                                                    type={this.state.visiblePassword ? 'text' : 'password'}
                                                    onChange={(e) => this.setState({ password: e })} />
                                                <InputGroup.Button onClick={() => this.visiblePassword({ type: 'password', mode: obj.mode, id: obj.id })}>
                                                    {this.state.visiblePassword ? <i className="bi bi-eye-fill"></i> : <i className="bi bi-eye-slash-fill"></i>}
                                                </InputGroup.Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm>
                                        <Form.Group>
                                            <Form.ControlLabel><b>Confirm Password</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                            <InputGroup inside >
                                                <Input
                                                    className='FgQPv'
                                                    defaultValue={this.state.cpassword}
                                                    type={this.state.visibleCpassword ? 'text' : 'password'}
                                                    onChange={(e) => this.setState({ cpassword: e })} />
                                                <InputGroup.Button onClick={() => this.visiblePassword({ type: 'cpassword', mode: obj.mode, id: obj.id })}>
                                                    {this.state.visibleCpassword ? <i class="bi bi-eye-fill"></i> : <i class="bi bi-eye-slash-fill"></i>}
                                                </InputGroup.Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm>
                                        <Text color="red">&#8226; รหัสผ่านประกอบด้วยตัวอักษรภาษาอังกฤษ ตัวเลข และสัญลักษณ์เท่านั้น</Text>
                                        <Text color="red">&#8226; รหัสผ่านต้องมีความยาวระหว่าง 6 ถึง 18 ตัวอักษร</Text>
                                        <Text color="red">&#8226; รหัสผ่านต้องมีหมายเลขอย่างน้อยหนึ่งตัว</Text>
                                        <Text color="red">&#8226; รหัสผ่านต้องมีสัญลักษณ์อย่างน้อยหนึ่งตัว</Text>
                                    </Col>
                                </Row>

                            </Form>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer className='me-4 p-2'>
                        <Stack className='d-flex justify-content-end'
                            wrap
                            spacing={12}>
                            <Button
                                color="blue"
                                appearance="primary"
                                startIcon={<i class="bi bi-floppy2-fill"></i>}
                                className='ps-2 pe-2'
                                onClick={() => this.onConfirm({ mode: obj.mode, id: obj.id })} >
                                บันทึก
                            </Button>
                            <Button
                                color="red"
                                appearance="subtle"
                                startIcon={<i class="bi bi-x-square"></i>}
                                className='ps-2 pe-2'
                                onClick={() => this.setState({ modal: [] })}>
                                ยกเลิก
                            </Button>
                        </Stack>
                    </Modal.Footer>
                </Modal>
            })
        }
    }

    visiblePassword = async (obj) => {
        try {
            if (obj.type === 'password') {
                console.log('Toggling password visibility');
                await this.promisedSetState({ visiblePassword: !this.state.visiblePassword });
                this.onModal({ mode: obj.mode, id: obj.id, visible: true })
            } else if (obj.type === 'cpassword') {
                console.log('Toggling confirm password visibility');
                await this.promisedSetState({ visibleCpassword: !this.state.visibleCpassword });
                this.onModal({ mode: obj.mode, id: obj.id, visible: true })
            }
            console.log('Updated state:', this.state);
        } catch (error) {
            console.error('Error in visiblePassword:', error);
        }
    }

    onKeyDownHandler = async e => {
        if (e.keyCode === 13) {
            await this.onRenderContent({ read: true })
        }
    };

    getData = async () => {
        if (this.state.sortColumn && this.state.sortType) {
            return this.state.data.sort((a, b) => {
                let x = a[this.state.sortColumn];
                let y = b[this.state.sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (this.state.sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }

        return this.state.data.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });
    };

    handleSortColumn = async (sortColumn, sortType) => {
        await this.promisedSetState({ userLoading: true });

        await new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });

        await this.promisedSetState({ userLoading: false });
        await this.promisedSetState({ sortColumn, sortType });

        // Call your onRenderContent function if needed
        await this.onRenderContent({ read: true });
    };

    getLogsData = async () => {
        if (this.state.logsSortColumn && this.state.logsSortType) {
            return this.state.logsData.sort((a, b) => {
                let x = a[this.state.logsSortColumn];
                let y = b[this.state.logsSortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (this.state.logsSortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }

        return this.state.logsData.filter((v, i) => {
            const start = this.state.logsLimit * (this.state.logsPage - 1);
            const end = start + this.state.logsLimit;
            return i >= start && i < end;
        });
    };

    logsHandleSortColumn = async (logsSortColumn, logsSortType) => {
        await this.promisedSetState({ logsLoading: true });

        await new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });

        await this.promisedSetState({ logsLoading: false });
        await this.promisedSetState({ logsSortColumn, logsSortType });

        // Call your onRenderContent function if needed
        await this.onLogs({ read: true });
    };

    onMonth = (month) => {
        let data;
        switch (month) {
            case 1: data = 'มกราคม'; break;
            case 2: data = 'กุมภาพันธ์'; break;
            case 3: data = 'มีนาคม'; break;
            case 4: data = 'เมษายน'; break;
            case 5: data = 'พฤษภาคม'; break;
            case 6: data = 'มิถุนายน'; break;
            case 7: data = 'กรกฎาคม'; break;
            case 8: data = 'สิงหาคม'; break;
            case 9: data = 'กันยายน'; break;
            case 10: data = 'ตุลาคม'; break;
            case 11: data = 'พฤศจิกายน'; break;
            case 12: data = 'ธันวาคม'; break;
            default: data = 'Invalid month'; // Handle invalid month input
        }

        return data;
    }

    onDate = (obj) => {
        let dateStart = new Date(`${obj.start.split(' ')[1]} ${obj.start.split(' ')[2]}, ${obj.start.split(' ')[3]} ${obj.start.split(' ')[4]}`)
        let dateEnd = new Date(`${obj.end.split(' ')[1]} ${obj.end.split(' ')[2]}, ${obj.end.split(' ')[3]} ${obj.end.split(' ')[4]}`)

        if (dateStart.getFullYear() === dateEnd.getFullYear()) {

            if (dateStart.getMonth() === dateEnd.getMonth()) {

                return `${dateStart.getDate()} - ${dateEnd.getDate()} ${this.onMonth(dateStart.getMonth() + 1)} ${dateStart.getFullYear() + 543}`

            } else if (dateStart.getMonth() !== dateEnd.getMonth()) {

                return `${dateStart.getDate()}  ${this.onMonth(dateStart.getMonth() + 1)} - ${dateEnd.getDate()} ${this.onMonth(dateEnd.getMonth() + 1)} ${dateStart.getFullYear() + 543}`

            }
        } else if (dateStart.getFullYear() !== dateEnd.getFullYear()) {

            if (dateStart.getMonth() === dateEnd.getMonth()) {

                return `${dateStart.getDate()} - ${dateEnd.getDate()} ${this.onMonth(dateStart.getMonth() + 1)} ${dateStart.getFullYear() + 543}`

            } else if (dateStart.getMonth() !== dateEnd.getMonth()) {

                return `${dateStart.getDate()}  ${this.onMonth(dateStart.getMonth() + 1)} ${dateStart.getFullYear() + 543} - ${dateEnd.getDate()} ${this.onMonth(dateEnd.getMonth() + 1)} ${dateEnd.getFullYear() + 543}`

            }
        }
    }

    // Function to validate a password
    validatePassword = (password) => {
        const minLength = 6;
        const maxLength = 18;
        const hasNumber = /\d/;
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/;
        const isEnglishOnly = /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>]+$/;

        if (password.length < minLength || password.length > maxLength) {
            return { valid: false, message: "รหัสผ่านต้องมีความยาวระหว่าง 6 ถึง 18 ตัวอักษร" };
        }
        if (!hasNumber.test(password)) {
            return { valid: false, message: "รหัสผ่านต้องมีหมายเลข\nอย่างน้อยหนึ่งตัว" };
        }
        if (!hasSymbol.test(password)) {
            return { valid: false, message: "รหัสผ่านต้องมีสัญลักษณ์\nอย่างน้อยหนึ่งตัว" };
        }
        if (!isEnglishOnly.test(password)) {
            return { valid: false, message: "รหัสผ่านสามารถประกอบด้วย\nตัวอักษรภาษาอังกฤษ ตัวเลข และสัญลักษณ์เท่านั้น" };
        }
        return { valid: true };
    };

    // Updated Validate function
    Validate = (params, clss, cond) => {
        if (params === cond) {
            $('.' + clss).addClass("required");
            Swal.fire({
                title: "กรุณากรอกข้อมูลให้ครบถ้วน",
                icon: "warning",
                timer: 2000,
                timerProgressBar: true,
                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            });
            return false;
        } else if (params !== cond) {
            $('.' + clss).removeClass("required");
            return true;
        }
    };

    onConfirm = async (obj) => {
        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        const handleAuthFailure = (error) => {
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        };

        if (obj.mode === 'insert') {
            this.data = true;
            this.data = this.data && this.Validate(this.state.name, '0ZWTS', '');
            this.data = this.data && this.Validate(this.state.username, '6ZJn2', '');

            if (this.data === true) {
                this.checkpass = true;

                try {
                    const usernameResponse = await axios.get(`${process.env.REACT_APP_API}/users/username/${this.state.username}`, authHeader);
                    if (usernameResponse.data.count > 0) {
                        $('.6ZJn2').addClass("required");
                        Swal.fire({
                            title: "ชื่อผู้ใช้นี้มีอยู่แล้ว",
                            icon: "warning",
                            timer: 2000,
                            timerProgressBar: true,
                            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                        });
                        this.checkpass = this.checkpass && false;
                    } else if (usernameResponse.data.count === 0) {
                        $('.6ZJn2').removeClass("required");
                        this.checkpass = this.checkpass && true;
                    }

                    // Validate password with the new function
                    const passwordValidation = this.validatePassword(this.state.password);
                    if (!passwordValidation.valid) {
                        $('.Bj2BC').addClass("required");
                        Swal.fire({
                            title: passwordValidation.message,
                            icon: "warning",
                            timer: 2000,
                            timerProgressBar: true,
                            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                        });
                        this.checkpass = this.checkpass && false;
                    } else {
                        $('.Bj2BC').removeClass("required");
                    }

                    // Validate confirm password
                    if (this.state.password !== this.state.cpassword) {
                        $('.FgQPv').addClass("required");
                        Swal.fire({
                            title: "รหัสผ่านไม่ตรงกัน",
                            icon: "warning",
                            timer: 2000,
                            timerProgressBar: true,
                            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                        });
                        this.checkpass = this.checkpass && false;
                    } else {
                        $('.FgQPv').removeClass("required");
                    }

                    if (this.checkpass === true) {
                        try {

                            await this.promisedSetState({ loading: true }); // Start loading

                            const response = await axios.post(`${process.env.REACT_APP_API}/users`, {
                                name: this.state.name,
                                tel: this.state.tel,
                                username: this.state.username,
                                password: this.state.password
                            }, authHeader);

                            if (response.data.message === 'successfully') {

                                await this.promisedSetState({ loading: false }); // Stop loading

                                Swal.fire({
                                    title: "คุณเพิ่มข้อมูลเรียบร้อยแล้ว",
                                    icon: "success",
                                    timer: 2000,
                                    timerProgressBar: true,
                                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                                }).then(async () => {

                                    await this.promisedSetState({ modal: [] });

                                    await this.promisedSetState({
                                        name: '',
                                        tel: '',
                                        username: '',
                                        password: '',
                                        cpassword: '',
                                        modal: []
                                    });

                                    await this.onRenderContent({ read: true });
                                });
                            }
                        } catch (error) {
                            handleAuthFailure(error);
                        }
                    }
                } catch (error) {
                    handleAuthFailure(error);
                }
            }
        } else if (obj.mode === 'update') {
            if (this.state.isPasswordchange === true) {
                this.data = true;
                this.data = this.data && this.Validate(this.state.name, '0ZWTS', '');
                this.data = this.data && this.Validate(this.state.username, '6ZJn2', '');

                if (this.data === true) {
                    this.checkpass = true;

                    // Validate password with the new function
                    const passwordValidation = this.validatePassword(this.state.password);
                    if (!passwordValidation.valid) {
                        $('.Bj2BC').addClass("required");
                        Swal.fire({
                            title: passwordValidation.message,
                            icon: "warning",
                            timer: 2000,
                            timerProgressBar: true,
                            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                        });
                        this.checkpass = this.checkpass && false;
                    } else {
                        $('.Bj2BC').removeClass("required");
                    }

                    // Validate confirm password
                    if (this.state.password !== this.state.cpassword) {
                        $('.FgQPv').addClass("required");
                        Swal.fire({
                            title: "รหัสผ่านไม่ตรงกัน",
                            icon: "warning",
                            timer: 2000,
                            timerProgressBar: true,
                            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                        });
                        this.checkpass = this.checkpass && false;
                    } else {
                        $('.FgQPv').removeClass("required");
                    }

                    if (this.checkpass === true) {
                        try {

                            await this.promisedSetState({ modal: [] });

                            await this.promisedSetState({ loading: true }); // Start loading

                            const response = await axios.put(`${process.env.REACT_APP_API}/password`, {
                                id: obj.id,
                                name: this.state.name,
                                tel: this.state.tel,
                                password: this.state.password
                            }, authHeader);

                            if (response.data.message === 'successfully') {

                                await this.promisedSetState({ loading: false }); // Stop loading

                                Swal.fire({
                                    title: "คุณแก้ไขข้อมูลเรียบร้อยแล้ว",
                                    icon: "success",
                                    timer: 2000,
                                    timerProgressBar: true,
                                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                                }).then(async () => {

                                    await this.promisedSetState({
                                        name: '',
                                        tel: '',
                                        username: '',
                                        password: '',
                                        cpassword: '',
                                        modal: []
                                    });

                                    await this.onRenderContent({ read: true });
                                });
                            }
                        } catch (error) {
                            handleAuthFailure(error);
                        }
                    }
                }
            } else if (this.state.isPasswordchange === false) {
                this.data = true;
                this.data = this.data && this.Validate(this.state.name, '0ZWTS', '');
                this.data = this.data && this.Validate(this.state.username, '6ZJn2', '');

                if (this.data === true) {
                    try {

                        await this.promisedSetState({ modal: [] });

                        await this.promisedSetState({ loading: true }); // Start loading

                        const response = await axios.put(`${process.env.REACT_APP_API}/users`, {
                            id: obj.id,
                            name: this.state.name,
                            tel: this.state.tel,
                        }, authHeader);

                        if (response.data.message === 'successfully') {


                            await this.promisedSetState({ loading: false }); // Stop loading

                            Swal.fire({
                                title: "คุณแก้ไขข้อมูลเรียบร้อยแล้ว",
                                icon: "success",
                                timer: 2000,
                                timerProgressBar: true,
                                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                            }).then(async () => {

                                await this.promisedSetState({
                                    name: '',
                                    tel: '',
                                    username: '',
                                    password: '',
                                    cpassword: '',
                                    modal: []
                                });

                                await this.onRenderContent({ read: true });
                            });
                        }
                    } catch (error) {
                        handleAuthFailure(error);
                    }
                }
            }
        }
    }

    onRemove = async (obj) => {
        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        const handleAuthFailure = (error) => {
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        };

        if (obj.mode === 'delete') {
            Swal.fire({
                title: "คุณต้องการลบสมาชิกใช่หรือไม่?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `<div style="font-size: 1.2rem">ยืนยัน</div>`,
                cancelButtonText: `<div style="font-size: 1.2rem">ยกเลิก</div>`
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const response = await axios.delete(`${process.env.REACT_APP_API}/users/${obj.id}`, authHeader);
                        if (response.data.message === 'successfully') {
                            Swal.fire({
                                title: "ลบข้อมูลเรียบร้อยแล้ว!",
                                timer: 2000,
                                timerProgressBar: true,
                                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                            }).then(() => {
                                this.onRenderContent({ read: 'read' });
                            });
                        }
                    } catch (error) {
                        handleAuthFailure(error);
                    }
                }
            });
        }
    }

    render() {
        return (
            <>
                {this.state.Breadcrumb}
                {this.state.searchContent}
                {this.state.tableContent}
                {this.state.modal}
                {this.state.logs}
    
                {/* Full-Page Loader */}
                {this.state.loading && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999 // Ensure it’s on top of other content
                    }}>
                        <Loader content="Loading..." vertical />
                    </div>
                )}
            </>
        );
    }    
}