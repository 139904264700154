import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withRouter from './withRouter';

// import axios
import axios from 'axios';

// import $
import $ from 'jquery';

// import Sweetalert2
import Swal from 'sweetalert2'

// ReactSortable
import { ReactSortable } from "react-sortablejs";

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Rsuite
import {
    Form
    , Table
    , IconButton
    , Input
    , Button
    , Divider
    , Stack
    , Accordion
    , Modal
    , List
    , Toggle
    , Checkbox
    , Tag
    , SelectPicker
    , InlineEdit
    , Loader
} from 'rsuite';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

class Manage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem('token')).token,
            username: JSON.parse(localStorage.getItem('token')).username,
            modal: [],
            content: [],
            categoryModal: [],
            categoryOptions: [],
            categoryName: '',
            typeOptions: [],
            forms: [],
            temp: [],
            sortColumn: '',
            sortType: '',
            tableLoading: false,
            limit: 10,
            page: 1,
            formsName: '',
            formsStatus: true,
            question: '',
            type: null,
            options: [],
            required: true,
            status: true,
        };
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {
        this.onForms({ method: 'read', id: this.props.params.id })
    }

    onForms = async (obj) => {

        let method = this.props.params.id === 'create' ? 'insert' : 'update'

        if (obj.method === 'read' && obj.id === 'create') {

            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            const handleAuthFailure = (error) => {
                if (error.response && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: error.message,
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                }
            };

            try {

                await this.promisedSetState({ loading: true }); // Start loading

                axios.get(`${process.env.REACT_APP_API}/elements`, authHeader)
                    .then(response => this.promisedSetState({
                        typeOptions: response.data.map((item) => ({
                            label: <div><i className={item.symbol}></i>{' '}<span>{item.name}</span></div>,
                            value: item.key
                        }))
                    }))

                await axios.get(`${process.env.REACT_APP_API}/defaults/1`, authHeader).then(async (response) => {

                    await response.data.map(async defaults => {

                        await this.promisedSetState({ forms: JSON.parse(defaults.data) })

                        this.setState({
                            content: <><Row className='mt-4'>
                                <Breadcrumb size='lg' separator={<AngleRightIcon />} >
                                    <Breadcrumb.Item><Link to="/"><i class="bi bi-house-fill"></i></Link></Breadcrumb.Item>
                                    <Breadcrumb.Item><Link to="/application">แบบฟอร์มใบสมัคร</Link></Breadcrumb.Item>
                                    <Breadcrumb.Item active>สร้างใบสมัคร</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>

                                <Row className='mt-3'>
                                    <Col sm>
                                        <Form fluid>
                                            <Row>
                                                <Col sm>
                                                    <Form.Group >
                                                        <Form.ControlLabel>
                                                            <b>ชื่อแบบฟอร์มใบสมัคร</b>{' '}
                                                            <b className='text-danger'>*</b>
                                                        </Form.ControlLabel>
                                                        <Form.Control
                                                            className='4Vtq6'
                                                            size='md'
                                                            onChange={e => this.setState({ formsName: e })} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className='mt-4'>
                                                <Col sm>
                                                    <Form.Group >
                                                        <Stack spacing={12} direction={'row'} >
                                                            <Form.ControlLabel><b>สถานะ</b>{' '}</Form.ControlLabel>
                                                            <Toggle className='zaPHI'
                                                                checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน"
                                                                defaultChecked
                                                                onChange={e => this.setState({ formsStatus: e })} />
                                                        </Stack>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>

                                <Row className='mt-4'>
                                    <Col sm>
                                        <Button
                                            className='border shadow-sm bg-white'
                                            appearance="subtle"
                                            startIcon={<i class="bi bi-plus-lg"></i>}
                                            onClick={() => this.onCategory({ method: 'insert' })} >
                                            เพิ่มหมวด
                                        </Button>
                                    </Col>
                                </Row>

                                <ReactSortable
                                    animation={200}
                                    list={this.state.forms.map((item) => item)}
                                    setList={async (newState) => {
                                        await this.promisedSetState({ forms: newState })

                                        // reload Content
                                        await this.onForms({ method: 'update', id: null })
                                    }}
                                >
                                    {this.state.forms.map((item, cateIndex) => (
                                        <div key={cateIndex} className='mt-3'>
                                            <Stack divider={<Divider vertical />} className='d-flex justify-content-end'>
                                                {item.setting && (
                                                    <>
                                                        <Tag>ตั้งค่า :</Tag>
                                                        {item.status ? (
                                                            <Toggle
                                                                defaultChecked
                                                                checkedChildren="ใช้งาน"
                                                                unCheckedChildren="ไม่ใช้งาน"
                                                                onChange={(e) => item.status = e} />) : (<Toggle
                                                                    checkedChildren="ใช้งาน"
                                                                    unCheckedChildren="ไม่ใช้งาน"
                                                                    onChange={(e) => item.status = e} />
                                                        )}
                                                        {item.remove && (
                                                            <IconButton
                                                                onClick={async () => {
                                                                    this.state.forms.splice(cateIndex, 1)
                                                                    await this.promisedSetState({ forms: this.state.forms });
                                                                    await this.onForms({ method: 'update', id: null })
                                                                }}
                                                                size='sm'
                                                                circle
                                                                icon={<i class="bi bi-trash3-fill"></i>} appearance="default" />
                                                        )}
                                                    </>
                                                )}
                                            </Stack>
                                            <Accordion className='shadow-sm'>
                                                <Accordion.Panel className='border'
                                                    style={{ backgroundColor: '#FFF' }}
                                                    header={`หมวด : ${item.category}`}
                                                    defaultExpanded>
                                                    <List bordered sortable onSort={(e) => this.handleSortEnd({ categoryIndex: cateIndex, oldIndex: e.oldIndex, newIndex: e.newIndex })}>
                                                        {item.questions.map((items, quesIndex) => {
                                                            return item.type === "notes" ? (
                                                                <Row>
                                                                    <Col>
                                                                        <Input as="textarea" rows={6}
                                                                            placeholder="กรุณากรอกข้อความ"
                                                                            onChange={(e) => item.questions = [e]} />
                                                                    </Col>
                                                                </Row>) : (
                                                                <List.Item
                                                                    size='sm'
                                                                    style={{ backgroundColor: '#FAFAFA' }}
                                                                    key={quesIndex} index={quesIndex}>
                                                                    <Row>
                                                                        <Col md={10}>
                                                                            <Stack wrap spacing={6}>
                                                                                <i class="bi bi-grip-vertical"></i>
                                                                                <div>คำถาม :</div>
                                                                                <div>{items.label}</div>
                                                                            </Stack>
                                                                        </Col>
                                                                        <Col md={2} className='d-flex justify-content-end'>
                                                                            <Stack wrap spacing={6}>
                                                                                <IconButton
                                                                                    size='sm'
                                                                                    circle
                                                                                    icon={<i class="bi bi-gear-fill"></i>}
                                                                                    appearance="default"
                                                                                    onClick={() => {
                                                                                        this.modalQuestion({ method: 'update', options: this.state.forms.map(res => res.questions.filter(item => item.id === items.id).map(data => data.options))[cateIndex], id: items.id })
                                                                                    }} />
                                                                                {items.remove === 1 && <IconButton
                                                                                    size='sm'
                                                                                    circle
                                                                                    icon={<i class="bi bi-trash3"></i>}
                                                                                    appearance="default"
                                                                                    onClick={async () => {
                                                                                        // Find the ID of the question
                                                                                        const QuestionID = this.state.forms[cateIndex].questions.find(question => question.id === items.id)?.id;

                                                                                        if (QuestionID !== undefined) {
                                                                                            // Find the index of the question to be removed
                                                                                            const index = this.state.forms[cateIndex].questions.findIndex(({ id }) => id === QuestionID);

                                                                                            if (index !== -1) {
                                                                                                // Create a new array of forms
                                                                                                const updatedForms = this.state.forms.map((form, formIndex) => {
                                                                                                    if (formIndex === cateIndex) {
                                                                                                        // Create a new array of questions without the question to be removed
                                                                                                        const updatedQuestions = form.questions.filter((_, qIndex) => qIndex !== index);
                                                                                                        return { ...form, questions: updatedQuestions };
                                                                                                    }
                                                                                                    return form;
                                                                                                });

                                                                                                // Update state using setState with the new array
                                                                                                await this.promisedSetState({ forms: updatedForms });

                                                                                                // Call onForms with updated method
                                                                                                await this.onForms({ method: 'update', id: null });
                                                                                            }
                                                                                        }
                                                                                    }} />}
                                                                            </Stack>
                                                                        </Col>

                                                                    </Row>
                                                                </List.Item>)
                                                        })}
                                                    </List>
                                                    {item.add &&
                                                        <Row className='mt-2'>
                                                            <Col>
                                                                <Button
                                                                    ppearance="subtle"
                                                                    startIcon={<i class="bi bi-check2-circle"></i>}
                                                                    onClick={() => this.onModal({ category: cateIndex, modal: true })}>
                                                                    เลือกคำถาม...
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </Accordion.Panel>
                                            </Accordion>
                                        </div>
                                    ))}
                                </ReactSortable>

                                <Stack className='d-flex justify-content-center mt-4 mb-4'
                                    wrap
                                    spacing={20}>
                                    <Button
                                        color="blue"
                                        appearance="primary"
                                        startIcon={<i class="bi bi-floppy2-fill"></i>}
                                        className='ps-3 pe-3'
                                        onClick={() => this.onConfirm({ method: 'insert' })} >
                                        บันทึก
                                    </Button>
                                    <Button
                                        color="red"
                                        appearance="subtle"
                                        startIcon={<i class="bi bi-trash3-fill"></i>}
                                        className='ps-3 pe-3'
                                        onClick={() => {
                                            Swal.fire({
                                                title: "คุณต้องการยกเลิกใช่หรือไม่?",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                                                cancelButtonText: `<div style="font-size: 1.2rem">ยกเลิก</div>`
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    window.location.replace('/application');
                                                }
                                            });
                                        }} >
                                        ยกเลิก
                                    </Button>
                                </Stack></>
                        })
                    })
                })

                await this.promisedSetState({ loading: false }); // Stop loading

            } catch (error) {
                handleAuthFailure(error);
            }
        } else if (obj.method === 'read' && obj.id !== 'create') {

            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            const handleAuthFailure = (error) => {
                if (error.response && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: error.message,
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                }
            };

            try {

                await this.promisedSetState({ loading: true }); // Start loading

                axios.get(`${process.env.REACT_APP_API}/elements`, authHeader)
                    .then(response => this.promisedSetState({
                        typeOptions: response.data.map((item) => ({
                            label: <div><i className={item.symbol}></i>{' '}<span>{item.name}</span></div>,
                            value: item.key
                        }))
                    }))

                await axios.get(`${process.env.REACT_APP_API}/forms/${this.props.params.id}`, authHeader).then(async response =>

                    await response.data.map(async (data) => {

                        await this.promisedSetState({ formsName: data.name, formsStatus: data.status === 1 ? true : false, forms: JSON.parse(data.forms_json) })

                        this.setState({
                            content: <><Row className='mt-4'>
                                <Breadcrumb size='lg' separator={<AngleRightIcon />} >
                                    <Breadcrumb.Item><Link to="/"><i class="bi bi-house-fill"></i></Link></Breadcrumb.Item>
                                    <Breadcrumb.Item><Link to="/application">แบบฟอร์มใบสมัคร</Link></Breadcrumb.Item>
                                    <Breadcrumb.Item active>{data.name}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>

                                <Row className='mt-3'>
                                    <Col sm>
                                        <Form fluid>
                                            <Row>
                                                <Col sm>
                                                    <Form.Group >
                                                        <Form.ControlLabel>
                                                            <b>ชื่อแบบฟอร์มใบสมัคร</b>{' '}
                                                            <b className='text-danger'>*</b>
                                                        </Form.ControlLabel>
                                                        <Form.Control
                                                            className='4Vtq6'
                                                            size='md'
                                                            defaultValue={data.name}
                                                            onChange={e => this.setState({ formsName: e })} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className='mt-4'>
                                                <Col sm>
                                                    <Form.Group >
                                                        <Stack spacing={12} direction={'row'} >
                                                            <Form.ControlLabel><b>สถานะ</b>{' '}</Form.ControlLabel>
                                                            {data.status === 1 ? (<Toggle className='zaPHI'
                                                                checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน"
                                                                defaultChecked
                                                                onChange={e => this.setState({ formsStatus: e })} />) : (<Toggle className='zaPHI'
                                                                    checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน"
                                                                    onChange={e => this.setState({ formsStatus: e })} />)}
                                                        </Stack>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>

                                <Row className='mt-4'>
                                    <Col sm>
                                        <Button
                                            className='border shadow-sm bg-white'
                                            appearance="subtle"
                                            startIcon={<i class="bi bi-plus-lg"></i>}
                                            onClick={() => this.onCategory({ method: 'insert' })} >
                                            เพิ่มหมวด
                                        </Button>
                                    </Col>
                                </Row>

                                <ReactSortable
                                    animation={200}
                                    list={this.state.forms.map((item) => item)}
                                    setList={async (newState) => {
                                        await this.promisedSetState({ forms: newState })

                                        // reload Content
                                        await this.onForms({ method: 'update', id: null })
                                    }}
                                >
                                    {this.state.forms.map((item, cateIndex) => (
                                        <div key={cateIndex} className='mt-3'>
                                            <Stack divider={<Divider vertical />} className='d-flex justify-content-end'>
                                                {item.setting && (
                                                    <>
                                                        <Tag>ตั้งค่า :</Tag>
                                                        {item.status ? (
                                                            <Toggle
                                                                defaultChecked
                                                                checkedChildren="ใช้งาน"
                                                                unCheckedChildren="ไม่ใช้งาน"
                                                                onChange={(e) => item.status = e} />) : (<Toggle
                                                                    checkedChildren="ใช้งาน"
                                                                    unCheckedChildren="ไม่ใช้งาน"
                                                                    onChange={(e) => item.status = e} />
                                                        )}
                                                        {item.remove && (
                                                            <IconButton
                                                                onClick={async () => {
                                                                    this.state.forms.splice(cateIndex, 1)
                                                                    await this.promisedSetState({ forms: this.state.forms });
                                                                    await this.onForms({ method: 'update', id: null })
                                                                }}
                                                                size='sm'
                                                                circle
                                                                icon={<i class="bi bi-trash3-fill"></i>} appearance="default" />
                                                        )}
                                                    </>
                                                )}
                                            </Stack>
                                            <Accordion className='shadow-sm'>
                                                <Accordion.Panel className='border'
                                                    style={{ backgroundColor: '#FFF' }}
                                                    header={`หมวด : ${item.category}`}
                                                    defaultExpanded>
                                                    <List bordered sortable onSort={(e) => this.handleSortEnd({ categoryIndex: cateIndex, oldIndex: e.oldIndex, newIndex: e.newIndex })}>
                                                        {item.questions.map((items, quesIndex) => {
                                                            return item.type === "notes" ? (
                                                                <Row>
                                                                    <Col>
                                                                        <Input as="textarea" rows={6}
                                                                            placeholder="กรุณากรอกข้อความ"
                                                                            onChange={(e) => item.questions = [e]} />
                                                                    </Col>
                                                                </Row>) : (
                                                                <List.Item
                                                                    size='sm'
                                                                    style={{ backgroundColor: '#FAFAFA' }}
                                                                    key={quesIndex} index={quesIndex}>
                                                                    <Row>
                                                                        <Col md={10}>
                                                                            <Stack wrap spacing={6}>
                                                                                <i class="bi bi-grip-vertical"></i>
                                                                                <div>คำถาม :</div>
                                                                                <div>{items.label}</div>
                                                                            </Stack>
                                                                        </Col>
                                                                        <Col md={2} className='d-flex justify-content-end'>
                                                                            <Stack wrap spacing={6}>
                                                                                <IconButton
                                                                                    size='sm'
                                                                                    circle
                                                                                    icon={<i class="bi bi-gear-fill"></i>}
                                                                                    appearance="default"
                                                                                    onClick={() => {
                                                                                        this.modalQuestion({ method: 'update', options: this.state.forms.map(res => res.questions.filter(item => item.id === items.id).map(data => data.options))[cateIndex], id: items.id })
                                                                                    }} />
                                                                                {items.remove === 1 && <IconButton
                                                                                    size='sm'
                                                                                    circle
                                                                                    icon={<i class="bi bi-trash3"></i>}
                                                                                    appearance="default"
                                                                                    onClick={async () => {
                                                                                        // Find the ID of the question
                                                                                        const QuestionID = this.state.forms[cateIndex].questions.find(question => question.id === items.id)?.id;

                                                                                        if (QuestionID !== undefined) {
                                                                                            // Find the index of the question to be removed
                                                                                            const index = this.state.forms[cateIndex].questions.findIndex(({ id }) => id === QuestionID);

                                                                                            if (index !== -1) {
                                                                                                // Create a new array of forms
                                                                                                const updatedForms = this.state.forms.map((form, formIndex) => {
                                                                                                    if (formIndex === cateIndex) {
                                                                                                        // Create a new array of questions without the question to be removed
                                                                                                        const updatedQuestions = form.questions.filter((_, qIndex) => qIndex !== index);
                                                                                                        return { ...form, questions: updatedQuestions };
                                                                                                    }
                                                                                                    return form;
                                                                                                });

                                                                                                // Update state using setState with the new array
                                                                                                await this.promisedSetState({ forms: updatedForms });

                                                                                                // Call onForms with updated method
                                                                                                await this.onForms({ method: 'update', id: null });
                                                                                            }
                                                                                        }
                                                                                    }} />}
                                                                            </Stack>
                                                                        </Col>

                                                                    </Row>
                                                                </List.Item>)
                                                        })}
                                                    </List>
                                                    {item.add &&
                                                        <Row className='mt-2'>
                                                            <Col>
                                                                <Button
                                                                    ppearance="subtle"
                                                                    startIcon={<i class="bi bi-check2-circle"></i>}
                                                                    onClick={() => this.onModal({ category: cateIndex, modal: true })}>
                                                                    เลือกคำถาม...
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </Accordion.Panel>
                                            </Accordion>
                                        </div>
                                    ))}
                                </ReactSortable>

                                <Stack className='d-flex justify-content-center mt-4 mb-4'
                                    wrap
                                    spacing={20}>
                                    <Button
                                        color="blue"
                                        appearance="primary"
                                        startIcon={<i class="bi bi-floppy2-fill"></i>}
                                        className='ps-3 pe-3'
                                        onClick={() => this.onConfirm({ method: 'update', id: data.id })} >
                                        บันทึก
                                    </Button>
                                    <Button
                                        color="red"
                                        appearance="subtle"
                                        startIcon={<i class="bi bi-trash3-fill"></i>}
                                        className='ps-3 pe-3'
                                        onClick={() => {
                                            Swal.fire({
                                                title: "คุณต้องการยกเลิกใช่หรือไม่?",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                                                cancelButtonText: `<div style="font-size: 1.2rem">ยกเลิก</div>`
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    window.location.replace('/application');
                                                }
                                            });
                                        }} >
                                        ยกเลิก
                                    </Button>
                                </Stack></>
                        })
                    }))

                await this.promisedSetState({ loading: false }); // Stop loading

            } catch (error) {
                handleAuthFailure(error);
            }
        } else if (obj.method === 'update') {

            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            const handleAuthFailure = (error) => {
                if (error.response && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: error.message,
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                }
            };

            try {

                await this.promisedSetState({ loading: true }); // Start loading

                axios.get(`${process.env.REACT_APP_API}/elements`, authHeader)
                    .then(response => this.promisedSetState({
                        typeOptions: response.data.map((item) => ({
                            label: <div><i className={item.symbol}></i>{' '}<span>{item.name}</span></div>,
                            value: item.key
                        }))
                    }))

                this.promisedSetState({
                    content: <><Row className='mt-4'>
                        <Breadcrumb size='lg' separator={<AngleRightIcon />} >
                            <Breadcrumb.Item><Link to="/"><i class="bi bi-house-fill"></i></Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to="/application">แบบฟอร์มใบสมัคร</Link></Breadcrumb.Item>
                            <Breadcrumb.Item active>{this.state.formsName || 'สร้างใบสมัคร'}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>

                        <Row className='mt-3'>
                            <Col sm>
                                <Form fluid>
                                    <Row>
                                        <Col sm>
                                            <Form.Group >
                                                <Form.ControlLabel>
                                                    <b>ชื่อแบบฟอร์มใบสมัคร</b>{' '}
                                                    <b className='text-danger'>*</b>
                                                </Form.ControlLabel>
                                                <Form.Control
                                                    className='4Vtq6'
                                                    size='md'
                                                    defaultValue={this.state.formsName}
                                                    onChange={e => this.setState({ formsName: e })} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-4'>
                                        <Col sm>
                                            <Form.Group >
                                                <Stack spacing={12} direction={'row'} >
                                                    <Form.ControlLabel><b>สถานะ</b>{' '}</Form.ControlLabel>
                                                    {this.state.formsStatus === 1 ? (<Toggle className='zaPHI'
                                                        checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน"
                                                        defaultChecked
                                                        onChange={e => this.setState({ formsStatus: e })} />) : (<Toggle className='zaPHI'
                                                            checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน"
                                                            onChange={e => this.setState({ formsStatus: e })} />)}
                                                </Stack>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>

                        <Row className='mt-4'>
                            <Col sm>
                                <Button
                                    className='border shadow-sm bg-white'
                                    appearance="subtle"
                                    startIcon={<i class="bi bi-plus-lg"></i>}
                                    onClick={() => this.onCategory({ method: 'insert' })} >
                                    เพิ่มหมวด
                                </Button>
                            </Col>
                        </Row>

                        <ReactSortable
                            animation={200}
                            list={this.state.forms.map((item) => item)}
                            setList={async (newState) => {
                                await this.promisedSetState({ forms: newState })

                                // reload Content
                                await this.onForms({ method: 'update', id: null })
                            }}
                        >
                            {this.state.forms.map((item, cateIndex) => (
                                <div key={cateIndex} className='mt-3'>
                                    <Stack divider={<Divider vertical />} className='d-flex justify-content-end'>
                                        {item.setting && (
                                            <>
                                                <Tag>ตั้งค่า :</Tag>
                                                {item.status ? (
                                                    <Toggle
                                                        defaultChecked
                                                        checkedChildren="ใช้งาน"
                                                        unCheckedChildren="ไม่ใช้งาน"
                                                        onChange={(e) => item.status = e} />) : (<Toggle
                                                            checkedChildren="ใช้งาน"
                                                            unCheckedChildren="ไม่ใช้งาน"
                                                            onChange={(e) => item.status = e} />
                                                )}
                                                {item.remove && (
                                                    <IconButton
                                                        onClick={async () => {
                                                            this.state.forms.splice(cateIndex, 1)
                                                            await this.promisedSetState({ forms: this.state.forms });
                                                            await this.onForms({ method: 'update', id: null })
                                                        }}
                                                        size='sm'
                                                        circle
                                                        icon={<i class="bi bi-trash3-fill"></i>} appearance="default" />
                                                )}
                                            </>
                                        )}
                                    </Stack>
                                    <Accordion className='shadow-sm'>
                                        <Accordion.Panel className='border'
                                            style={{ backgroundColor: '#FFF' }}
                                            header={`หมวด : ${item.category}`}
                                            defaultExpanded>
                                            <List bordered sortable onSort={(e) => this.handleSortEnd({ categoryIndex: cateIndex, oldIndex: e.oldIndex, newIndex: e.newIndex })}>
                                                {item.questions.map((items, quesIndex) => {
                                                    return item.type === "notes" ? (
                                                        <Row>
                                                            <Col>
                                                                <Input as="textarea" rows={6}
                                                                    placeholder="กรุณากรอกข้อความ"
                                                                    onChange={(e) => item.questions = [e]} />
                                                            </Col>
                                                        </Row>) : (
                                                        <List.Item
                                                            size='sm'
                                                            style={{ backgroundColor: '#FAFAFA' }}
                                                            key={quesIndex} index={quesIndex}>
                                                            <Row>
                                                                <Col md={10}>
                                                                    <Stack wrap spacing={6}>
                                                                        <i class="bi bi-grip-vertical"></i>
                                                                        <div>คำถาม :</div>
                                                                        <div>{items.label}</div>
                                                                    </Stack>
                                                                </Col>
                                                                <Col md={2} className='d-flex justify-content-end'>
                                                                    <Stack wrap spacing={6}>
                                                                        <IconButton
                                                                            size='sm'
                                                                            circle
                                                                            icon={<i class="bi bi-gear-fill"></i>}
                                                                            appearance="default"
                                                                            onClick={() => {
                                                                                this.modalQuestion({ method: 'update', options: JSON.parse(this.state.forms.map(res => res.questions.filter(item => item.id === items.id).map(data => data.options))[cateIndex]), id: items.id })
                                                                            }} />
                                                                        {items.remove === 1 && <IconButton
                                                                            size='sm'
                                                                            circle
                                                                            icon={<i class="bi bi-trash3"></i>}
                                                                            appearance="default"
                                                                            onClick={async () => {
                                                                                // Find the ID of the question
                                                                                const QuestionID = this.state.forms[cateIndex].questions.find(question => question.id === items.id)?.id;

                                                                                if (QuestionID !== undefined) {
                                                                                    // Find the index of the question to be removed
                                                                                    const index = this.state.forms[cateIndex].questions.findIndex(({ id }) => id === QuestionID);

                                                                                    if (index !== -1) {
                                                                                        // Create a new array of forms
                                                                                        const updatedForms = this.state.forms.map((form, formIndex) => {
                                                                                            if (formIndex === cateIndex) {
                                                                                                // Create a new array of questions without the question to be removed
                                                                                                const updatedQuestions = form.questions.filter((_, qIndex) => qIndex !== index);
                                                                                                return { ...form, questions: updatedQuestions };
                                                                                            }
                                                                                            return form;
                                                                                        });

                                                                                        // Update state using setState with the new array
                                                                                        await this.promisedSetState({ forms: updatedForms });

                                                                                        // Call onForms with updated method
                                                                                        await this.onForms({ method: 'update', id: null });
                                                                                    }
                                                                                }
                                                                            }} />}
                                                                    </Stack>
                                                                </Col>

                                                            </Row>
                                                        </List.Item>)
                                                })}
                                            </List>
                                            {item.add &&
                                                <Row className='mt-2'>
                                                    <Col>
                                                        <Button
                                                            ppearance="subtle"
                                                            startIcon={<i class="bi bi-check2-circle"></i>}
                                                            onClick={() => this.onModal({ category: cateIndex, modal: true })}>
                                                            เลือกคำถาม...
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            }
                                        </Accordion.Panel>
                                    </Accordion>
                                </div>
                            ))}
                        </ReactSortable>

                        <Stack className='d-flex justify-content-center mt-4 mb-4'
                            wrap
                            spacing={20}>
                            <Button
                                color="blue"
                                appearance="primary"
                                startIcon={<i class="bi bi-floppy2-fill"></i>}
                                className='ps-3 pe-3'
                                onClick={() => this.onConfirm({ method: method, id: this.props.params.id })} >
                                บันทึก
                            </Button>
                            <Button
                                color="red"
                                appearance="subtle"
                                startIcon={<i class="bi bi-trash3-fill"></i>}
                                className='ps-3 pe-3'
                                onClick={() => {
                                    Swal.fire({
                                        title: "คุณต้องการยกเลิกใช่หรือไม่?",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                                        cancelButtonText: `<div style="font-size: 1.2rem">ยกเลิก</div>`
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            window.location.replace('/application');
                                        }
                                    });
                                }} >
                                ยกเลิก
                            </Button>
                        </Stack></>
                })

                await this.promisedSetState({ loading: false }); // Stop loading

            } catch (error) {
                handleAuthFailure(error);
            }
        }
    }

    onQuestionsInForm = async (id) => {

        await this.promisedSetState({ loading: true }); // Start loading

        this.state.forms.map(async (item, index) => {
            if (index === id) {
                item.questions = this.state.temp

                // reload Content
                await this.onForms({ method: 'update', id: null })
                await this.promisedSetState({ loading: false }); // Stop loading

                this.setState({ modal: false, temp: [] })
            }
        })
    }

    onSelected = async (obj) => {
        this.state.forms.forEach((item, index) => {
            if (index === obj.category) {
                const tempIndex = this.state.temp.findIndex(({ id }) => id === obj.id);
                if (obj.event === true && tempIndex === -1) {
                    this.state.temp.push({
                        id: obj.id,
                        label: obj.label,
                        default: obj.default === undefined ? { status: false, key: false } : obj.default,
                        type: obj.type,
                        options: obj.options,
                        status: obj.status,
                        required: obj.required,
                        remove: obj.remove
                    });
                } else if (obj.event === false && tempIndex > -1) {
                    this.state.temp.splice(tempIndex, 1);
                }
            }
        });
    }

    onModal = (obj) => {
        // Temporary settings
        this.state.forms.forEach((item, index) => {
            if (index === obj.category) {
                item.questions.forEach((items) => {
                    this.state.temp.push({
                        id: items.id,
                        label: items.label,
                        default: items.default,
                        type: items.type,
                        options: items.options,
                        status: items.status,
                        required: items.required,
                        remove: items.remove
                    });
                });
            }
        });

        // selected Questions
        const { Column, HeaderCell, Cell } = Table;

        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        axios.get(`${process.env.REACT_APP_API}/questions/selected`, authHeader)
            .then(response => {
                this.setState({
                    modal: (
                        <Modal backdrop={'static'}
                            height={'100%'}
                            open={obj.modal}
                            onClose={() => this.setState({ modal: false, temp: [] })} size='lg'>
                            <Modal.Header>
                                <Modal.Title>เลือกคำถาม</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Container>
                                    <Row>
                                        <Col>
                                            <Table
                                                height={420}
                                                data={response.data.map(item => {
                                                    // Get the question from state
                                                    const question = this.state.forms[obj.category].questions.find(({ id }) => id === item.id);

                                                    // Check if the question exists and get its status
                                                    const status = question ? question.default['status'] : false;

                                                    // Determine if the item is selected
                                                    const isSelected = this.state.forms[obj.category].questions.findIndex(({ id }) => id === item.id) !== -1;

                                                    return {
                                                        selected: (
                                                            <Checkbox
                                                                defaultChecked={isSelected}
                                                                disabled={status}
                                                                onChange={(e, isChecked) =>
                                                                    this.onSelected({
                                                                        id: item.id,
                                                                        label: item.questions,
                                                                        default: item.default,
                                                                        type: item.type,
                                                                        options: item.options,
                                                                        status: item.status,
                                                                        required: item.required,
                                                                        remove: 1,
                                                                        event: isChecked,
                                                                        category: obj.category
                                                                    })}
                                                            />
                                                        ),
                                                        questions: item.questions,
                                                        type: (
                                                            <div>
                                                                <i className={item.symbol}></i> <span>{item.elements}</span>
                                                            </div>
                                                        )
                                                    };
                                                })}
                                                bordered
                                                cellBordered
                                                sortColumn={this.state.sortColumn}
                                                sortType={this.state.sortType}
                                                onSortColumn={this.handleSortColumn}
                                                loading={this.state.tableLoading}
                                                className='rounded'
                                            >
                                                <Column width={80} align="center" fixed sortable>
                                                    <HeaderCell>เลือก</HeaderCell>
                                                    <Cell dataKey="selected" />
                                                </Column>

                                                <Column width={600} sortable>
                                                    <HeaderCell>คำถาม</HeaderCell>
                                                    <Cell dataKey="questions" />
                                                </Column>

                                                <Column width={255} sortable>
                                                    <HeaderCell>ประเภท</HeaderCell>
                                                    <Cell dataKey="type" />
                                                </Column>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer className='me-4 p-2'>
                                <Stack className='d-flex justify-content-end'
                                    wrap
                                    spacing={12}>
                                    <Button
                                        color="blue"
                                        appearance="primary"
                                        startIcon={<i class="bi bi-floppy2-fill"></i>}
                                        className='ps-2 pe-2'
                                        onClick={() => this.onQuestionsInForm(obj.category)}>
                                        บันทึก
                                    </Button>
                                    <Button
                                        color="red"
                                        appearance="subtle"
                                        startIcon={<i class="bi bi-x-square"></i>}
                                        className='ps-2 pe-2'
                                        onClick={() => this.setState({ modal: [], temp: [] })}>
                                        ยกเลิก
                                    </Button>
                                </Stack>
                            </Modal.Footer>
                        </Modal>
                    )
                });
            })
            .catch(error => {
                if (error.response && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: error.message,
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                }
            });
    }

    handleSortColumn = (sortColumn, sortType) => {
        this.setState({ tableLoading: true })
        setTimeout(() => {
            this.setState({ tableLoading: false })
            this.setState({ sortColumn: sortColumn })
            this.setState({ sortType: sortType })
        }, 500);
    };

    handleSortEnd = async ({ categoryIndex, oldIndex, newIndex }) => {
        await this.state.forms.map(async (item, index) => {
            if (categoryIndex === index) {
                const moveData = item.questions.splice(oldIndex, 1);

                const newData = [...item.questions];
                newData.splice(newIndex, 0, moveData[0]);
                item.questions = newData

                // reload Content
                await this.onForms({ method: 'update', id: null })
            }
        })
    }

    Validate = (params, clss, cond) => {
        if (params === cond) {
            $('.' + clss).addClass("required")
            Swal.fire({
                title: "กรุณากรอกข้อมูลให้ครบถ้วน",
                icon: "warning",
                timer: 2000,
                timerProgressBar: true,
                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            });
            return false
        } else if (params !== cond) {
            $('.' + clss).removeClass("required")
            return true
        }
    }

    onCategory = (obj) => {
        if (obj.method === 'insert') {
            this.setState({
                categoryModal: <Modal backdrop={'static'}
                    open={true}
                    onClose={() => this.setState({ categoryModal: [] })}>
                    <Modal.Header>
                        <Modal.Title>เพิ่มหมวด</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form fluid>
                            <Form.Group>
                                <Form.ControlLabel>ชื่อหมวด</Form.ControlLabel>
                                <Form.Control
                                    className='Mpe0F'
                                    name="category"
                                    onChange={(e) => this.setState({ categoryName: e })} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            color="blue"
                            appearance="primary"
                            startIcon={<i class="bi bi-floppy2-fill"></i>}
                            onClick={() => this.addCategory({ method: 'insert' })} >
                            บันทึก
                        </Button>
                        <Button
                            appearance="subtle"
                            startIcon={<i class="bi bi-x-square"></i>}
                            onClick={() => this.setState({ categoryModal: [] })}>
                            ยกเลิก
                        </Button>
                    </Modal.Footer>
                </Modal>
            })
        }
    }

    addCategory = async (obj) => {
        if (obj.method === 'insert') {
            this.val = true;
            this.val = true && this.Validate(this.state.categoryName, 'Mpe0F', '')

            if (this.val === true) {

                await this.promisedSetState({ loading: true }); // Start loading

                await this.state.forms.push({
                    "category": this.state.categoryName,
                    "type": "conditions",
                    "status": true,
                    "add": true,
                    "remove": true,
                    "setting": true,
                    "questions": []
                })

                // reload Content
                await this.onForms({ method: 'update', id: null })

                this.setState({ categoryModal: [] })

                await this.promisedSetState({ loading: false }); // Stop loading

                Swal.fire({
                    title: "คุณเพิ่มหมวดเรียบร้อยแล้ว",
                    icon: "success",
                    timer: 2000,
                    timerProgressBar: true,
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                })
            }
        }
    }

    onChoice = (obj) => {
        this.data = []
        if (obj.choice === true) {
            this.data = <Button
                className='mt-1'
                appearance="subtle"
                startIcon={<i class="bi bi-plus-lg"></i>}
                onClick={() => this.modalQuestion({ method: obj.method, options: obj.options, newOption: true, choice: true, id: obj.id })}>
                เพิ่มตัวเลือก
            </Button>
        }
        return this.data
    }

    modalQuestion = async (obj) => {
        if (obj.method === 'update') {
            await obj.newOption === true && (obj.options.push({ name: "กรุณาระบุตัวเลือก", more: 0, remove: 1 }))
            await obj.removeOption === true && (obj.options.splice(obj.indexOption, 1))
            this.state.forms.forEach(res => {
                res.questions.filter(item => item.id === obj.id)
                    .forEach(questions => {
                        this.promisedSetState({
                            question: questions.label,
                            type: questions.type,
                            required: questions.required,
                            status: questions.status,
                            options: obj.options,
                            modal: (
                                <Modal size='md' backdrop={'static'} open={true} onClose={() => this.setState({ modal: [] })}>
                                    <Modal.Header>
                                        <Modal.Title>คำถาม - ตอบ</Modal.Title>
                                    </Modal.Header>
                                    <hr />
                                    <Modal.Body>
                                        <Container>
                                            <Form fluid>
                                                <Row className='mt-2'>
                                                    <Col sm>
                                                        <Form.Group>
                                                            <Form.ControlLabel><i className="bi bi-question-circle-fill"></i>{' '}<b>คำถาม</b></Form.ControlLabel>
                                                            <Form.Control
                                                                className='4H2Bd'
                                                                readOnly
                                                                size='sm'
                                                                value={questions.label}
                                                                onChange={(e) => this.setState({ question: e })} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row className='mt-2'>
                                                    <Col sm>
                                                        <Form.Group>
                                                            <Form.ControlLabel><i class="bi bi-chat-quote-fill"></i>{' '}<b>คำตอบ</b></Form.ControlLabel>
                                                            <SelectPicker
                                                                className='61YSy'
                                                                size='lg'
                                                                placeholder='กรุณาเลือกประเภทคำตอบ'
                                                                data={this.state.typeOptions}
                                                                defaultValue={questions.type}
                                                                style={{ width: 300 }}
                                                                onChange={async (e) => {
                                                                    await e === 'multiple_choice' || e === 'checkboxes' || e === 'dropdown' ? (this.modalQuestion({ method: 'insert', options: this.state.options, choice: true, id: obj.id })) : (this.modalQuestion({ method: 'insert', options: [], choice: false, id: obj.id }))
                                                                    this.setState({ type: e })
                                                                }}
                                                                onClean={() => this.modalQuestion({ method: obj.method, options: [], choice: false, id: obj.id })} />
                                                            <ReactSortable
                                                                swap
                                                                list={obj.options}
                                                                setList={(newState) => this.modalQuestion({ method: obj.method, options: newState, id: obj.id })}
                                                            >
                                                                {obj.options.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <div key={item.name}>
                                                                                <Stack className='mt-2 mb-2' spacing={6}>
                                                                                    <i class="bi bi-grip-vertical"></i>
                                                                                    <InlineEdit
                                                                                        defaultValue={item.name}
                                                                                        onChange={(e) => item.name = e} />
                                                                                    {item.more === 1 ? (<Checkbox
                                                                                        checked
                                                                                        onChange={(str, e) => item.more = e ? 1 : 0}>
                                                                                        ระบุเพิ่มเติม
                                                                                    </Checkbox>) : (<Checkbox
                                                                                        onChange={(str, e) => item.more = e ? 1 : 0}>
                                                                                        ระบุเพิ่มเติม
                                                                                    </Checkbox>)}
                                                                                    {item.remove === 1 && (<IconButton
                                                                                        size='sm'
                                                                                        circle
                                                                                        icon={<i class="bi bi-trash3"></i>}
                                                                                        onClick={() => {
                                                                                            this.onModal({ method: obj.method, options: obj.options, removeOption: true, indexOption: index, id: obj.id })
                                                                                        }
                                                                                        } />)}
                                                                                </Stack>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </ReactSortable>
                                                            {questions.type === 'multiple_choice' || questions.type === 'checkboxes' || questions.type === 'dropdown' ? (this.onChoice({ method: obj.method, options: obj.options, choice: true, id: obj.id })) : (this.onChoice({ method: obj.method, options: obj.options, choice: obj.choice, id: obj.id }))}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row className='mt-2'>
                                                    <Col sm>
                                                        <Form.Group>
                                                            <Checkbox
                                                                checked={questions.required === 1}
                                                                onChange={(str, e) => {
                                                                    this.state.forms.map(res => res.questions.filter(item => item.id === obj.id).map(data => data.required = e ? 1 : 0))
                                                                    this.modalQuestion({ method: obj.method, options: obj.options, id: obj.id })
                                                                }}>
                                                                <i>จำเป็นต้องกรอก (required)</i>
                                                            </Checkbox>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row className='mt-2'>
                                                    <Col sm>
                                                        <Form.Group>
                                                            <Form.ControlLabel><i class="bi bi-toggles2"></i>{' '}<b>สถานะ</b></Form.ControlLabel>
                                                            <Toggle
                                                                className='ms-2'
                                                                checkedChildren="ใช้งาน"
                                                                unCheckedChildren="ไม่ใช้งาน"
                                                                checked={questions.status === 1}
                                                                onChange={(e) => {
                                                                    this.state.forms.map(res => res.questions.filter(item => item.id === obj.id).map(data => data.status = e ? 1 : 0))
                                                                    this.modalQuestion({ method: obj.method, options: obj.options, id: obj.id })
                                                                }} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Container>
                                    </Modal.Body>
                                    <Modal.Footer className='me-4 p-2'>
                                        <Stack className='d-flex justify-content-end'
                                            wrap
                                            spacing={12}>
                                            <Button
                                                color="blue"
                                                appearance="primary"
                                                startIcon={<i class="bi bi-floppy2-fill"></i>}
                                                className='ps-2 pe-2'
                                                onClick={() => this.editQuestion({ method: 'update', id: questions.id })} >
                                                บันทึก
                                            </Button>
                                            <Button
                                                color="red"
                                                appearance="subtle"
                                                startIcon={<i class="bi bi-x-square"></i>}
                                                className='ps-2 pe-2'
                                                onClick={() => this.setState({ modal: [] })}>
                                                ยกเลิก
                                            </Button>
                                        </Stack>
                                    </Modal.Footer>
                                </Modal>
                            )
                        });
                    });
            });
        }
    }

    editQuestion = async (obj) => {
        if (obj.method === 'update') {
            this.val = true;
            this.val = true && this.Validate(this.state.question, '4H2Bd', '')
            this.val = true && this.Validate(this.state.type, '61YSy', null)

            if (this.val === true) {

                await this.promisedSetState({ loading: true }); // Start loading

                this.state.forms.forEach(res => {
                    res.questions.filter(item => item.id === obj.id)
                        .forEach(data => {
                            data.options = JSON.stringify(this.state.options.map(item => ({ id: item.id, name: item.name, more: item.more })));
                            data.required = this.state.required;
                            data.status = this.state.status;
                        });
                });

                Swal.fire({
                    title: "คุณแก้ไขคำถามเรียบร้อยแล้ว",
                    icon: "success",
                    timer: 2000,
                    timerProgressBar: true,
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                }).then(async (result) => {
                    await this.onForms({ method: 'update', id: null })
                    await this.promisedSetState({ modal: [] })
                    await this.promisedSetState({ loading: false }); // Stop loading
                });
            }
        }
    }

    onConfirm = (obj) => {
        // Validation
        this.forms = this.Validate(this.state.formsName, '4Vtq6', '');

        if (!this.forms) {
            // Handle invalid form case
            Swal.fire({
                title: "กรุณากรอกข้อมูลให้ครบถ้วน",
                icon: "warning",
                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            });
            return;
        }

        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        // Common Success Response Function
        const handleSuccess = (message) => {
            Swal.fire({
                title: message,
                icon: "success",
                timer: 2000,
                timerProgressBar: true,
                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            }).then(() => {
                window.location.replace('/application');
            });
        };

        // API Request Based on Method
        if (obj.method === 'insert') {

            this.promisedSetState({ loading: true }); // Start loading

            axios.post(`${process.env.REACT_APP_API}/forms`, {
                name: this.state.formsName,
                status: this.state.formsStatus,
                forms_json: this.state.forms,
                username: this.state.username
            }, authHeader)
                .then((response) => {
                    if (response.data.message === 'successfully') {
                        handleSuccess("คุณสร้างใบสมัครเรียบร้อยแล้ว");
                        this.promisedSetState({ loading: false }); // Stop loading
                    }
                })
                .catch(error => this.handleError(error));

        } else if (obj.method === 'update') {

            this.promisedSetState({ loading: true }); // Start loading

            axios.put(`${process.env.REACT_APP_API}/forms`, {
                id: obj.id,
                name: this.state.formsName,
                status: this.state.formsStatus,
                forms_json: this.state.forms,
                username: this.state.username
            }, authHeader)
                .then((response) => {
                    if (response.data.message === 'successfully') {
                        handleSuccess("คุณแก้ไขใบสมัครเรียบร้อยแล้ว");
                        this.promisedSetState({ loading: false }); // Stop loading
                    }
                })
                .catch(error => this.handleError(error));
        }
    };

    // Common Error Handling Function
    handleError = (error) => {
        if (error.response && error.response.data.message === 'AuthenticationFailed') {
            window.location.replace('/login');
        } else {
            Swal.fire({
                title: "เกิดข้อผิดพลาด",
                text: error.message,
                icon: "error",
                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            });
        }
    };

    render() {

        return (
            <>
                {this.state.content}
                {this.state.modal}
                {this.state.categoryModal}
                {this.state.loading && <Loader backdrop content="Loading..." vertical />}
            </>
        );
    }
}

export default withRouter(Manage);