import React, { Component } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
    Container,
    Content,
    Form,
    Stack,
    Grid,
    Row,
    Col,
    Input,
    InputGroup,
    Button,
    Loader
} from 'rsuite';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Logo from '../images/kids.png';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            visible: false,
            loading: false
        };
    }

    visiblePassword = () => {
        this.setState({ visible: !this.state.visible });
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.onLogin({ username: this.state.username, password: this.state.password });
        }
    };

    onLogin = ({ username, password }) => {
        if (username === '' || password === '') {
            Swal.fire({
                title: "Username หรือ Password\nไม่ถูกต้อง!",
                icon: "warning",
                timer: 2000,
                timerProgressBar: true,
                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            });
        } else {
            this.setState({ loading: true }); // Start loading

            axios.post(`${process.env.REACT_APP_API}/login`, { username, password })
                .then((response) => {
                    if (response.data.message === 'InvalidPassword') {
                        this.setState({ loading: false }); // Stop loading
                        Swal.fire({
                            title: "Username หรือ Password\nไม่ถูกต้อง!",
                            icon: "warning",
                            timer: 2000,
                            timerProgressBar: true,
                            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                        });
                    } else if (response.data.message === 'LoginSuccessfully') {
                        const tokenData = {
                            status: 'successfully',
                            username: response.data.user.username,
                            latest_access: response.data.user.latest_access,
                            token: response.data.token
                        };
                        localStorage.setItem('token', JSON.stringify(tokenData));

                        // Handle successful login
                        axios.post(`${process.env.REACT_APP_API}/logs`, { username })
                            .then(() => {
                                window.location.replace('/');
                                this.setState({ loading: false }); // Stop loading
                            });
                    }
                })
                .catch((error) => {
                    this.setState({ loading: false }); // Stop loading on error
                    console.error('Login error:', error);
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด!",
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                });
        }
    }

    render() {
        return (
            <div className="login-page d-flex align-items-center justify-content-center vh-100">
                <Container>
                    <Content>
                        <Stack spacing={6} alignItems="center" justifyContent="center" style={{ height: '100%' }}>
                            <Grid fluid>
                                <Row className="show-grid">
                                    <Col>
                                        <Card bordered className='shadow-sm' style={{ backgroundColor: '#FFF' }}>
                                            <Card.Header className='d-flex justify-content-center'>
                                                <Stack direction={'column'}>
                                                    <Image src={Logo} width={300} height={300} rounded />
                                                    <Card.Title>
                                                        <h3><b>ระบบ Doi Tung Experience</b></h3>
                                                    </Card.Title>
                                                </Stack>
                                            </Card.Header>
                                            <Card.Body>
                                                <Card.Text>
                                                    {this.state.loading && <Loader backdrop content="Loading..." vertical />}
                                                    <Form fluid>
                                                        <Form.Group>
                                                            <Form.ControlLabel><b>Username</b></Form.ControlLabel>
                                                            <Form.Control
                                                                onChange={e => this.setState({ username: e })}
                                                                onKeyPress={this.handleKeyPress}
                                                                disabled={this.state.loading}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.ControlLabel><b>Password</b></Form.ControlLabel>
                                                            <InputGroup inside>
                                                                <Input
                                                                    type={this.state.visible ? 'text' : 'password'}
                                                                    onChange={e => this.setState({ password: e })}
                                                                    onKeyPress={this.handleKeyPress}
                                                                    disabled={this.state.loading}
                                                                />
                                                                <InputGroup.Button onClick={this.visiblePassword} disabled={this.state.loading}>
                                                                    {this.state.visible ? <i className="bi bi-eye-fill"></i> : <i className="bi bi-eye-slash-fill"></i>}
                                                                </InputGroup.Button>
                                                            </InputGroup>
                                                        </Form.Group>
                                                        <Button
                                                            appearance="primary"
                                                            block
                                                            onClick={() => this.onLogin({ username: this.state.username, password: this.state.password })}
                                                            disabled={this.state.loading}
                                                        >
                                                            เข้าสู่ระบบ
                                                        </Button>
                                                    </Form>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Grid>
                        </Stack>
                    </Content>
                </Container>
            </div>
        );
    }
}

export default Login;
