import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import axios
import axios from 'axios';

// import Rsuite
import {
    Form
    , SelectPicker
    , DateRangePicker
    , Button
    , Table
    , Pagination
    , AutoComplete
    , InputGroup
    , ButtonToolbar
    , IconButton
    , Text
    , Tooltip
    , Whisper
} from 'rsuite';

// import Bootstrap
import Row from 'react-bootstrap/Row';

// import Sweetalert2
import Swal from 'sweetalert2'

export default class History extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem('token')).token,
            tableContent: [],
            searchContent: [],
            searchDataset: [],
            typeQuery: 0,
            applicationQuery: 0,
            searchQuery: '',
            dateStartQuery: '',
            dateEndQuery: '',
            sortColumn: '',
            sortType: '',
            loading: false,
            limit: 15,
            page: 1,
            data: [],
            message: ''
        };

        this.onRenderContent({ read: true })
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    onRenderContent = async (obj) => {

        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        const handleAuthFailure = (error) => {
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        };

        if (obj.read) {
            const { Column, HeaderCell, Cell } = Table;

            try {
                // Fetch history data
                const historyResponse = await axios.get(`${process.env.REACT_APP_API}/activities/history`, authHeader);

                // Clear previous searchDataset
                await this.promisedSetState({ searchDataset: [] });

                // Populate searchDataset and filterContent
                for (const item of historyResponse.data.rows) {
                    const applicationFilterResponse = await axios.get(`${process.env.REACT_APP_API}/forms`, authHeader);
                    const applicationFilter = applicationFilterResponse.data.map(form => ({ label: form.name, value: form.id }));

                    await this.promisedSetState(prevState => ({
                        searchDataset: [...prevState.searchDataset, { label: item.name, value: item.name.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;") }],
                        filterContent: (
                            <Row className='mt-3'>
                                <Form layout="inline">
                                    <Form.Group style={{ marginBottom: '0.65em' }}>
                                        <Form.ControlLabel>ประเภท</Form.ControlLabel>
                                        <SelectPicker
                                            size='md'
                                            placeholder="กรุณาเลือกรายการ"
                                            data={[
                                                { label: 'ค่าย', value: 'camp' },
                                                { label: 'Family trip/Private trip', value: 'trip' },
                                                { label: 'Workshop/กิจกรรมระยะสั้น', value: 'workshop' }
                                            ]}
                                            onSelect={async e => {
                                                await this.promisedSetState({ typeQuery: e });
                                                await this.onRenderContent({ read: true });
                                            }}
                                            onClean={async () => {
                                                await this.promisedSetState({ typeQuery: 0 });
                                                await this.onRenderContent({ read: true });
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '0.65em' }}>
                                        <Form.ControlLabel>วันที่</Form.ControlLabel>
                                        <DateRangePicker
                                            style={{ width: 260 }}
                                            size='md'
                                            format="dd/MM/yyyy"
                                            character=" – "
                                            placeholder="วัน/เดือน/ปี - วัน/เดือน/ปี"
                                            onOk={async e => {
                                                await this.promisedSetState({ dateStartQuery: e[0], dateEndQuery: e[1] });
                                                await this.onRenderContent({ read: true });
                                            }}
                                            onClean={async () => {
                                                await this.promisedSetState({ dateStartQuery: '', dateEndQuery: '' });
                                                await this.onRenderContent({ read: true });
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '0.65em' }}>
                                        <Form.ControlLabel>ใบสมัคร</Form.ControlLabel>
                                        <SelectPicker
                                            size='md'
                                            placeholder="กรุณาเลือกรายการ"
                                            data={applicationFilter}
                                            onSelect={async e => {
                                                await this.promisedSetState({ applicationQuery: e });
                                                await this.onRenderContent({ read: true });
                                            }}
                                            onClean={async () => {
                                                await this.promisedSetState({ applicationQuery: 0 });
                                                await this.onRenderContent({ read: true });
                                            }}
                                        />
                                    </Form.Group>
                                </Form>
                            </Row>
                        ),
                        searchContent: (
                            <Row>
                                <Form onKeyDown={this.onKeyDownHandler} layout="inline">
                                    <Form.Group>
                                        <InputGroup style={{ width: 300, marginBottom: 10 }} tabIndex={-1}>
                                            <InputGroup.Addon>
                                                <i className="bi bi-search"></i>
                                            </InputGroup.Addon>
                                            <AutoComplete
                                                onChange={async e => {
                                                    await this.promisedSetState({ searchQuery: e.replace(/&amp;/g, "&").replace(/&gt;/g, ">").replace(/&lt;/g, "<").replace(/&quot;/g, '"') });
                                                }}
                                                style={{ width: 300 }}
                                                data={this.state.searchDataset.map(item => ({ label: item.label, value: item.value }))}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Button
                                        color="blue"
                                        appearance="primary"
                                        onClick={async () => await this.onRenderContent({ read: true })}>
                                        ค้นหา
                                    </Button>
                                </Form>
                            </Row>
                        )
                    }));
                }

                // Fetch filtered activities
                const filterResponse = await axios.post(`${process.env.REACT_APP_API}/activities/filter`, {
                    page: 'other',
                    typeQuery: this.state.typeQuery,
                    applicationQuery: this.state.applicationQuery,
                    searchQuery: this.state.searchQuery,
                    dateStartQuery: this.state.dateStartQuery,
                    dateEndQuery: this.state.dateEndQuery
                }, authHeader);

                // Clear current state
                await this.promisedSetState({ tableContent: [], data: [] });

                // Process filtered data
                const processedData = await Promise.all(filterResponse.data.map(async (item, index) => {
                    let type;
                    switch (item.type) {
                        case 'camp':
                            type = 'ค่าย';
                            break;
                        case 'trip':
                            type = 'Family trip/Private trip';
                            break;
                        case 'workshop':
                            type = 'Workshop/กิจกรรมระยะสั้น';
                            break;
                        default:
                            type = 'error'; // Default value for unknown types
                            break;
                    }
                    

                    const datePeriod = await this.onDate({ start: item.date_period.split(',')[0], end: item.date_period.split(',')[1] });
                    const applicationResponse = await axios.get(`${process.env.REACT_APP_API}/forms/${item.forms_id}`, authHeader);
                    const application = applicationResponse.data.map(form => form.name);
                    const participantsResponse = await axios.post(`${process.env.REACT_APP_API}/participants/activities`, { id: item.id, searchQuery: '' }, authHeader);
                    const participants = participantsResponse.data.length;

                    return {
                        index: index + 1,
                        id: item.id,
                        type: type,
                        activity: item.name,
                        participants: participants,
                        application: application,
                        date: datePeriod,
                        status: item.status === 'completed' ? (<Text color="green">{item.status}</Text>) : (<Text color="red">{item.status}</Text>)
                    };
                }));

                // Set state with processed data
                await this.promisedSetState({ data: processedData });

                // Set up table content
                this.setState({
                    tableContent: (
                        <div>
                            <Table
                                autoHeight
                                data={this.state.data}
                                bordered
                                cellBordered
                                sortColumn={this.state.sortColumn}
                                sortType={this.state.sortType}
                                onSortColumn={this.handleSortColumn}
                                loading={this.state.loading}
                                className='rounded shadow-sm'
                            >
                                <Column width={60} align="center" sortable>
                                    <HeaderCell>ลำดับ</HeaderCell>
                                    <Cell dataKey="index" />
                                </Column>

                                <Column width={125} sortable>
                                    <HeaderCell>ประเภท</HeaderCell>
                                    <Cell dataKey="type" />
                                </Column>

                                <Column width={400} sortable>
                                    <HeaderCell>ชื่อกิจกรรม</HeaderCell>
                                    <Cell dataKey="activity" />
                                </Column>

                                <Column width={210} sortable>
                                    <HeaderCell>วันที่จัดกิจกรรม</HeaderCell>
                                    <Cell dataKey="date" />
                                </Column>

                                <Column align='center' width={100} sortable>
                                    <HeaderCell>ผู้สมัคร</HeaderCell>
                                    <Cell dataKey="participants" />
                                </Column>

                                <Column width={160} sortable>
                                    <HeaderCell>ใบสมัคร</HeaderCell>
                                    <Cell dataKey="application" />
                                </Column>

                                <Column align='center' width={110} sortable>
                                    <HeaderCell>สถานะ</HeaderCell>
                                    <Cell dataKey="status" />
                                </Column>

                                <Column align='center' width={130} fixed="right">
                                    <HeaderCell>...</HeaderCell>
                                    <Cell align='center' style={{ padding: '6px' }}>
                                        {rowData => (
                                            <ButtonToolbar>
                                                <Link to={`/activities/details/${rowData.id}`}>
                                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>รายละเอียด</Tooltip>}>
                                                        <IconButton
                                                            size='sm'
                                                            color="blue"
                                                            appearance="primary"
                                                            icon={<i className="bi bi-file-earmark-text-fill"></i>} />
                                                    </Whisper>
                                                </Link>
                                                <Link to={`/activities/participants/${rowData.id}`}>
                                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>ผู้สมัคร</Tooltip>}>
                                                        <IconButton
                                                            size='sm'
                                                            color="violet"
                                                            appearance="primary"
                                                            icon={<i className="bi bi-person-check"></i>} />
                                                    </Whisper>
                                                </Link>
                                                <Link to={`/activities/assessment/${rowData.id}`}>
                                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>ผลประเมิน</Tooltip>}>
                                                        <IconButton
                                                            size='sm'
                                                            color="yellow"
                                                            appearance="primary"
                                                            icon={<i className="bi bi-clipboard-check-fill"></i>} />
                                                    </Whisper>
                                                </Link>
                                            </ButtonToolbar>
                                        )}
                                    </Cell>
                                </Column>
                            </Table>
                            <div style={{ padding: 20 }}>
                                <Pagination
                                    prev
                                    next
                                    first
                                    last
                                    ellipsis
                                    boundaryLinks
                                    maxButtons={5}
                                    size="xs"
                                    layout={['total', '|', 'pager']}
                                    total={this.state.data.length}
                                    limit={this.state.limit}
                                    activePage={this.state.page}
                                    onChangePage={async (e) => { 
                                        await this.promisedSetState({ page: e }) 
                                        await this.onRenderContent({ read: true })
                                }}
                                />
                            </div>
                        </div>
                    )
                });
            } catch (error) {
                handleAuthFailure(error);
            }
        }
    }

    onKeyDownHandler = async e => {
        if (e.keyCode === 13) {
            await this.onRenderContent({ read: true })
        }
    };

    handleSortColumn = async (sortColumn, sortType) => {
        await this.promisedSetState({ loading: true });

        await new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });

        await this.promisedSetState({ loading: false });
        await this.promisedSetState({ sortColumn, sortType });

        // Call your onRenderContent function if needed
        await this.onRenderContent({ read: true });
    };

    getData = async () => {
        if (this.state.sortColumn && this.state.sortType) {
            return this.state.data.sort((a, b) => {
                let x = a[this.state.sortColumn];
                let y = b[this.state.sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (this.state.sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }

        return this.state.data.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });
    };

    onMonth = (month) => {
        let data;
        switch (month) {
            case 1: data = 'มกราคม'; break;
            case 2: data = 'กุมภาพันธ์'; break;
            case 3: data = 'มีนาคม'; break;
            case 4: data = 'เมษายน'; break;
            case 5: data = 'พฤษภาคม'; break;
            case 6: data = 'มิถุนายน'; break;
            case 7: data = 'กรกฎาคม'; break;
            case 8: data = 'สิงหาคม'; break;
            case 9: data = 'กันยายน'; break;
            case 10: data = 'ตุลาคม'; break;
            case 11: data = 'พฤศจิกายน'; break;
            case 12: data = 'ธันวาคม'; break;
            default: data = 'Invalid month'; // Handle invalid month input
        }
    
        return data;
    }

    onDate = (obj) => {
        let dateStart = new Date(`${obj.start.split(' ')[1]} ${obj.start.split(' ')[2]}, ${obj.start.split(' ')[3]} ${obj.start.split(' ')[4]}`)
        let dateEnd = new Date(`${obj.end.split(' ')[1]} ${obj.end.split(' ')[2]}, ${obj.end.split(' ')[3]} ${obj.end.split(' ')[4]}`)

        if (dateStart.getFullYear() === dateEnd.getFullYear()) {

            if (dateStart.getMonth() === dateEnd.getMonth()) {

                return `${dateStart.getDate()} - ${dateEnd.getDate()} ${this.onMonth(dateStart.getMonth() + 1)} ${dateStart.getFullYear() + 543}`

            } else if (dateStart.getMonth() !== dateEnd.getMonth()) {

                return `${dateStart.getDate()}  ${this.onMonth(dateStart.getMonth() + 1)} - ${dateEnd.getDate()} ${this.onMonth(dateEnd.getMonth() + 1)} ${dateStart.getFullYear() + 543}`

            }
        } else if (dateStart.getFullYear() !== dateEnd.getFullYear()) {

            if (dateStart.getMonth() === dateEnd.getMonth()) {

                return `${dateStart.getDate()} - ${dateEnd.getDate()} ${this.onMonth(dateStart.getMonth() + 1)} ${dateStart.getFullYear() + 543}`

            } else if (dateStart.getMonth() !== dateEnd.getMonth()) {

                return `${dateStart.getDate()}  ${this.onMonth(dateStart.getMonth() + 1)} ${dateStart.getFullYear() + 543} - ${dateEnd.getDate()} ${this.onMonth(dateEnd.getMonth() + 1)} ${dateEnd.getFullYear() + 543}`

            }
        }
    }

    render() {

        return (
            <>
                {this.state.filterContent}
                {this.state.searchContent}
                {this.state.tableContent}
            </>
        );
    }
}