import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withRouter from './withRouter';

// import axios
import axios from 'axios';

// import Cropper
import Cropper from 'react-easy-crop'

// import Sweetalert2
import Swal from 'sweetalert2'

// import Rsuite
import {
    Modal
    , Form
    , DateRangePicker
    , InputNumber
    , Input
    , Button
    , SelectPicker
    , Stack
    , MaskedInput
    , Loader
} from 'rsuite';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';

// import Bootstrap
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Images from 'react-bootstrap/Image';

// import $
import $ from 'jquery';

// images
import ImageSize from '../images/imagesize.jpg'

class Edit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem('token')).token,
            username: JSON.parse(localStorage.getItem('token')).username,
            content: [],
            modal: false,
            image: null,
            croppedImage: ImageSize,
            croppedArea: null,
            crop: { x: 0, y: 0 },
            zoom: 1,
            rotation: 0,
            formList: [],
            name: '',
            type: null,
            date_period: null,
            participants: 0,
            details: null,
            code: null,
            income: 0,
            forms: null,
            ImageData: null,
            loading: false
        }
    }

    async componentDidMount() {
        const input = document.getElementById('InputImageUpload');
        if (input) {
            input.addEventListener('change', this.onSelectFile);
        }

        await this.onForms({ read: true, id: this.props.params.id })
    }

    async componentWillUnmount() {
        const input = document.getElementById('InputImageUpload');
        if (input) {
            input.removeEventListener('change', this.onSelectFile);
        }

        await this.onForms({ read: true, id: this.props.params.id })
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    onForms = async (obj) => {
        if (obj.id === 'create') {
            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            const handleAuthFailure = (error) => {
                if (error.response && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: error.message,
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                }
            };

            try {
                await axios.get(`${process.env.REACT_APP_API}/forms/active`, authHeader)
                    .then(response => this.setState({ formList: response.data.map((item) => ({ label: item.name, value: item.id })) }))

                await this.promisedSetState({
                    content: <div><Row className='mt-4'>
                        <Breadcrumb size='lg' separator={<AngleRightIcon />} >
                            <Breadcrumb.Item><Link to="/"><i class="bi bi-house-fill"></i></Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to="/activities">ข้อมูลกิจกรรม</Link></Breadcrumb.Item>
                            <Breadcrumb.Item active>สร้างกิจกรรม</Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>

                        <Form fluid>
                            <Row>
                                <Col sm>
                                    <Form.Group controlId="name">
                                        <Form.ControlLabel><b>รูปภาพ</b></Form.ControlLabel>
                                        <Stack
                                            className='cNlLu'
                                            spacing={6}
                                            direction={'column'}
                                            alignItems={'flex-start'}
                                        >
                                            <Images
                                                className="border"
                                                src={this.state.croppedImage}
                                                rounded
                                                fluid
                                                width={1600}
                                                height={400} />
                                            <Button
                                                color="green"
                                                appearance="primary"
                                                startIcon={<i class="bi bi-card-image"></i>}
                                                onClick={() => this.onSelectedImage()}>กรุณาเลือกรูปภาพ</Button>
                                            <input
                                                id='InputImageUpload'
                                                type='file'
                                                accept='image/*'
                                                onChange={(e) => this.onSelectFile(e)}
                                                hidden />
                                        </Stack>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm><Form.Group>
                                    <Form.ControlLabel><b>ประเภท</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                    <SelectPicker
                                        className='ZJlkT'
                                        placeholder="กรุณาเลือกรายการ"
                                        data={[{ label: 'ค่าย', value: 'camp' }, { label: 'Family trip/Private trip', value: 'trip' }, { label: 'Workshop/กิจกรรมระยะสั้น', value: 'workshop' }]
                                            .map(item => ({ label: item.label, value: item.value })
                                            )}
                                        style={{ width: '100%' }}
                                        size='lg'
                                        onSelect={e => this.setState({ type: e })} />
                                </Form.Group></Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm><Form.Group>
                                    <Form.ControlLabel><b>ชื่อกิจกรรม</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                    <Form.Control
                                        className='QzdrE'
                                        name="activityName"
                                        onChange={e => this.setState({ name: e })} />
                                </Form.Group></Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm><Form.Group>
                                    <Form.ControlLabel><b>วันที่จัดกิจกรรม</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                    <DateRangePicker
                                        className='roTA0'
                                        format="dd/MM/yyyy"
                                        character=" – "
                                        placeholder="วัน/เดือน/ปี - วัน/เดือน/ปี"
                                        style={{ width: '100%' }}
                                        onChange={(e) => this.setState({ date_period: e })} />
                                </Form.Group></Col>
                                <Col sm><Form.Group>
                                    <Form.ControlLabel><b>จำนวนผู้เข้าร่วมกิจกรรม</b></Form.ControlLabel>
                                    <InputNumber
                                        className='H22QH'
                                        defaultValue={0}
                                        min={0}
                                        formatter={this.toInterger}
                                        style={{ width: '100%' }}
                                        onChange={e => this.setState({ participants: e })} />
                                </Form.Group></Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm><Form.Group>
                                    <Form.ControlLabel><b>รายละเอียด</b></Form.ControlLabel>
                                    <Input
                                        className='JsRcV'
                                        as="textarea"
                                        rows={8}
                                        onChange={e => this.setState({ details: e })} />
                                </Form.Group></Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm><Form.Group>
                                    <Form.ControlLabel><b>รหัสกิจกรรม</b></Form.ControlLabel>
                                    <MaskedInput
                                        mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                        guide={true}
                                        showMask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                        keepCharPositions={true}
                                        onChange={(e) => this.setState({ code: e })} />
                                </Form.Group></Col>
                                <Col sm></Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm><Form.Group>
                                    <Form.ControlLabel><b>รายได้</b></Form.ControlLabel>
                                    <InputNumber
                                        className='H22QH'
                                        defaultValue={0}
                                        min={0}
                                        formatter={this.to2decimal}
                                        style={{ width: '100%' }}
                                        onChange={e => this.setState({ income: e })} />
                                </Form.Group></Col>
                                <Col sm></Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm><Form.Group>
                                    <Form.ControlLabel><b>แบบฟอร์มใบสมัคร</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                    <SelectPicker
                                        className='AEXR5'
                                        placeholder="กรุณาเลือกรายการ"
                                        data={this.state.formList}
                                        style={{ width: '100%' }}
                                        size='lg'
                                        onSelect={e => this.setState({ forms: e })} />
                                </Form.Group></Col>
                            </Row>

                            <Stack
                                className='d-flex justify-content-center mt-5 mb-5'
                                wrap
                                spacing={20}>
                                <Button
                                    color="blue"
                                    appearance="primary"
                                    startIcon={<i class="bi bi-floppy2-fill"></i>}
                                    className='ps-3 pe-3'
                                    onClick={async () => {
                                        await this.promisedSetState({ loading: true })
                                        await this.onConfirm({ method: 'insert' })
                                    }} >
                                    บันทึก
                                </Button>
                                <Button
                                    color="red"
                                    appearance="subtle"
                                    startIcon={<i class="bi bi-trash3-fill"></i>}
                                    className='ps-3 pe-3'
                                    onClick={() => {
                                        Swal.fire({
                                            title: "คุณต้องการยกเลิกใช่หรือไม่?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                                            cancelButtonText: `<div style="font-size: 1.2rem">ยกเลิก</div>`
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                window.location.replace('/activities');
                                            }
                                        });
                                    }}>
                                    ยกเลิก
                                </Button>
                            </Stack>
                        </Form></div>
                })
            } catch (error) {
                handleAuthFailure(error);
            }
        } else if (obj.id !== 'create') {

            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            const handleAuthFailure = (error) => {
                if (error.response && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: error.message,
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                }
            };

            try {
                await axios.get(`${process.env.REACT_APP_API}/forms`, authHeader)
                    .then(response => this.setState({ formList: response.data.map((item) => ({ label: item.name, value: item.id })) }))

                await axios.get(`${process.env.REACT_APP_API}/activities/${obj.id}`, authHeader).then((response) =>
                    response.data.map(async (data) => {

                        obj.read && await this.promisedSetState({
                            croppedImage: `${process.env.REACT_APP_UPLOAD}/${data.image_path}`,
                            type: data.type,
                            name: data.name,
                            date_period: data.date_period,
                            participants: data.participants,
                            details: data.details,
                            code: data.code,
                            income: data.income,
                            forms: data.forms_id
                        })


                        await this.promisedSetState({
                            content: <div><Row className='mt-4'>
                                <Breadcrumb size='lg' separator={<AngleRightIcon />} >
                                    <Breadcrumb.Item><Link to="/"><i class="bi bi-house-fill"></i></Link></Breadcrumb.Item>
                                    <Breadcrumb.Item><Link to="/activities">ข้อมูลกิจกรรม</Link></Breadcrumb.Item>
                                    <Breadcrumb.Item active>{this.state.name}</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>

                                <Form fluid>
                                    <Row>
                                        <Col sm>
                                            <Form.Group controlId="name">
                                                <Form.ControlLabel><b>รูปภาพ</b></Form.ControlLabel>
                                                <Stack
                                                    className='cNlLu'
                                                    spacing={6}
                                                    direction={'column'}
                                                    alignItems={'flex-start'}
                                                >
                                                    <Images
                                                        className="border"
                                                        src={this.state.croppedImage}
                                                        rounded
                                                        fluid
                                                        width={1600}
                                                        height={400} />
                                                    <Button
                                                        color="green"
                                                        appearance="primary"
                                                        startIcon={<i class="bi bi-card-image"></i>}
                                                        onClick={() => this.onSelectedImage()}>กรุณาเลือกรูปภาพ</Button>
                                                    <input
                                                        id='InputImageUpload'
                                                        type='file'
                                                        accept='image/png, image/jpeg'
                                                        onChange={(e) => this.onSelectFile(e)}
                                                        hidden />
                                                </Stack>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-4'>
                                        <Col sm><Form.Group>
                                            <Form.ControlLabel><b>ประเภท</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                            <SelectPicker
                                                className='ZJlkT'
                                                placeholder="กรุณาเลือกรายการ"
                                                data={[{ label: 'ค่าย', value: 'camp' }, { label: 'Family trip/Private trip', value: 'trip' }, { label: 'Workshop/กิจกรรมระยะสั้น', value: 'workshop' }]
                                                    .map(item => ({ label: item.label, value: item.value })
                                                    )}
                                                style={{ width: '100%' }}
                                                size='lg'
                                                defaultValue={this.state.type}
                                                onSelect={e => this.setState({ type: e })} />
                                        </Form.Group></Col>
                                    </Row>

                                    <Row className='mt-4'>
                                        <Col sm><Form.Group>
                                            <Form.ControlLabel><b>ชื่อกิจกรรม</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                            <Form.Control
                                                className='QzdrE'
                                                name="activityName"
                                                defaultValue={this.state.name}
                                                onChange={e => this.setState({ name: e })} />
                                        </Form.Group></Col>
                                    </Row>

                                    <Row className='mt-4'>
                                        <Col sm><Form.Group>
                                            <Form.ControlLabel><b>วันที่จัดกิจกรรม</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                            <DateRangePicker
                                                className='roTA0'
                                                format="dd/MM/yyyy"
                                                character=" – "
                                                placeholder="วัน/เดือน/ปี - วัน/เดือน/ปี"
                                                style={{ width: '100%' }}
                                                defaultValue={[new Date(this.state.date_period.split(/,/)[0]), new Date(this.state.date_period.split(/,/)[1])]}
                                                onChange={(e) => this.setState({ date_period: e })} />
                                        </Form.Group></Col>
                                        <Col sm><Form.Group>
                                            <Form.ControlLabel><b>จำนวนผู้เข้าร่วมกิจกรรม</b></Form.ControlLabel>
                                            <InputNumber
                                                className='H22QH'
                                                defaultValue={this.state.participants}
                                                min={0}
                                                formatter={this.toInterger}
                                                style={{ width: '100%' }}
                                                onChange={e => this.setState({ participants: e })} />
                                        </Form.Group></Col>
                                    </Row>

                                    <Row className='mt-4'>
                                        <Col sm><Form.Group>
                                            <Form.ControlLabel><b>รายละเอียด</b></Form.ControlLabel>
                                            <Input
                                                className='JsRcV'
                                                as="textarea"
                                                rows={8}
                                                defaultValue={this.state.details}
                                                onChange={e => this.setState({ details: e })} />
                                        </Form.Group></Col>
                                    </Row>

                                    <Row className='mt-4'>
                                        <Col sm><Form.Group>
                                            <Form.ControlLabel><b>รหัสกิจกรรม</b></Form.ControlLabel>
                                            <MaskedInput
                                                mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                                guide={true}
                                                showMask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                defaultValue={this.state.code}
                                                onChange={(e) => this.setState({ code: e })} />
                                        </Form.Group></Col>
                                        <Col sm></Col>
                                    </Row>

                                    <Row className='mt-4'>
                                        <Col sm><Form.Group>
                                            <Form.ControlLabel><b>รายได้</b></Form.ControlLabel>
                                            <InputNumber
                                                className='H22QH'
                                                defaultValue={this.state.income}
                                                min={0}
                                                formatter={this.to2decimal}
                                                style={{ width: '100%' }}
                                                onChange={e => this.setState({ income: e })} />
                                        </Form.Group></Col>
                                        <Col sm></Col>
                                    </Row>

                                    <Row className='mt-4'>
                                        <Col sm><Form.Group>
                                            <Form.ControlLabel><b>แบบฟอร์มใบสมัคร</b>{' '}<b className='text-danger'>*</b></Form.ControlLabel>
                                            <SelectPicker
                                                className='AEXR5'
                                                readOnly
                                                placeholder="กรุณาเลือกรายการ"
                                                data={this.state.formList}
                                                style={{ width: '100%' }}
                                                size='lg'
                                                defaultValue={this.state.forms}
                                                onSelect={e => this.setState({ forms: e })} />
                                        </Form.Group></Col>
                                    </Row>

                                    <Stack
                                        className='d-flex justify-content-center mt-5 mb-5'
                                        wrap
                                        spacing={20}>
                                        <Button
                                            color="blue"
                                            appearance="primary"
                                            startIcon={<i class="bi bi-floppy2-fill"></i>}
                                            className='ps-3 pe-3'
                                            onClick={async () => {
                                                await this.promisedSetState({ loading: true })
                                                await this.onConfirm({ method: 'update', id: data.id })
                                            }} >
                                            บันทึก
                                        </Button>
                                        <Button
                                            color="red"
                                            appearance="subtle"
                                            startIcon={<i class="bi bi-trash3-fill"></i>}
                                            className='ps-3 pe-3'
                                            onClick={() => {
                                                Swal.fire({
                                                    title: "คุณต้องการยกเลิกใช่หรือไม่?",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#3085d6",
                                                    cancelButtonColor: "#d33",
                                                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                                                    cancelButtonText: `<div style="font-size: 1.2rem">ยกเลิก</div>`
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        window.location.replace('/activities');
                                                    }
                                                });
                                            }}>
                                            ยกเลิก
                                        </Button>
                                    </Stack>
                                </Form></div>
                        })
                    }))
            } catch (error) {
                handleAuthFailure(error);
            }
        }
    }

    to2decimal = (value) => {
        return `${(value * 1).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')}`
    }

    toInterger = (value) => {
        return `${(value * 1).toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')}`
    }

    onSelectedImage = () => {
        const input = document.getElementById('InputImageUpload');
        if (input) {
            input.click(); // Trigger the file input click
        }
    }

    // Crop Image
    onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        this.setState({ croppedArea: croppedAreaPixels });
    };

    onSelectFile = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = async () => {
                await this.setState({ image: reader.result, modal: true });
            };
        }
    };

    createImage = (url) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(image);
            image.onerror = (error) => reject(error);
            image.crossOrigin = "anonymous"; // Handle cross-origin issues
            image.src = url;
        });
    };

    getRadianAngle(degreeValue) {
        return (degreeValue * Math.PI) / 180;
    }

    getBase64 = async (imageSrc, pixelCrop, rotation) => {
        const image = await this.createImage(imageSrc);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const maxSize = Math.max(image.width, image.height);
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

        canvas.width = safeArea;
        canvas.height = safeArea;

        ctx.translate(safeArea / 2, safeArea / 2);
        ctx.rotate(this.getRadianAngle(rotation));
        ctx.translate(-safeArea / 2, -safeArea / 2);

        ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);

        const data = ctx.getImageData(0, 0, safeArea, safeArea);

        // Adjust canvas to cropped area size
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        ctx.putImageData(
            data,
            0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
            0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
        );

        return canvas.toDataURL("image/jpeg");
    };

    Validate = (params, clss, cond) => {
        if (params === cond) {
            $('.' + clss).addClass("required")
            Swal.fire({
                title: "กรุณากรอกข้อมูลให้ครบถ้วน",
                icon: "warning",
                timer: 2000,
                timerProgressBar: true,
                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            });
            return false
        } else if (params !== cond) {
            $('.' + clss).removeClass("required")
            return true
        }
    }

    RandomKey(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    base64ToBlob(base64String, contentType = '') {
        const byteCharacters = atob(base64String);
        const byteArrays = [];

        for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i));
        }

        const byteArray = new Uint8Array(byteArrays);
        return new Blob([byteArray], { type: contentType });
    }

    onConfirm = async (obj) => {
        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        const handleAuthFailure = (error) => {
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: (
                        <div style={{ fontSize: '1.2rem' }}>ตกลง</div>
                    ),
                });
            }
        };

        const validateFields = () => {
            const validations = [
                this.Validate(this.state.croppedImage, 'cNlLu', obj.method === 'insert' ? ImageSize : ''),
                this.Validate(this.state.type, 'ZJlkT', null),
                this.Validate(this.state.name, 'QzdrE', ''),
                this.Validate(this.state.date_period, 'roTA0', null),
                this.Validate(this.state.forms, 'AEXR5', null),
            ];
            return validations.every(result => result === true);
        };

        const getTypeBackground = () => {
            const typeBackgroundMap = {
                'camp': 'bg-blue',
                'trip': 'bg-pink',
                'workshop': 'bg-orange'
            };
            return typeBackgroundMap[this.state.type] || '';
        };

        const prepareFormData = (filename, keyRandom) => {
            const formData = new FormData();
            formData.append('type', this.state.type);
            formData.append('type_bg', getTypeBackground());
            formData.append('name', this.state.name);
            formData.append('date_period', this.state.date_period);
            formData.append('participants', this.state.participants);
            formData.append('details', this.state.details);
            formData.append('code', this.state.code);
            formData.append('income', this.state.income);
            formData.append('forms_id', this.state.forms);
            formData.append('key', keyRandom);
            formData.append('username', this.state.username);
            formData.append('image_data', this.base64ToBlob(
                this.state.croppedImage.split(',')[1],
                this.state.croppedImage.split(';')[0].split(':')[1]
            ), `img_${filename}.${this.state.croppedImage.split(';')[0].split(':')[1].split('/')[1]}`);
            return formData;
        };

        if (obj.method === 'insert') {

            await this.promisedSetState({ loading: true })

            if (validateFields()) {

                const filename = this.RandomKey(10);
                const keyRandom = this.RandomKey(12);
                const formData = prepareFormData(filename, keyRandom);

                try {
                    const response = await axios.post(`${process.env.REACT_APP_API}/activities`, formData, authHeader);
                    if (response.data.message === 'successfully') {

                        await this.promisedSetState({ loading: false })

                        Swal.fire({
                            title: "คุณสร้างกิจกรรมเรียบร้อยแล้ว",
                            icon: "success",
                            timer: 2000,
                            timerProgressBar: true,
                            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                        }).then(async () => {
                            window.location.replace('/activities');
                        });
                    }
                } catch (error) {
                    handleAuthFailure(error);
                }
            }
        } else if (obj.method === 'update') {

            await this.promisedSetState({ loading: true })

            if (validateFields()) {
                try {

                    const response = await fetch(this.state.croppedImage);
                    const blob = await response.blob();

                    const formData = new FormData();
                    formData.append('id', obj.id);
                    formData.append('type', this.state.type);
                    formData.append('type_bg', getTypeBackground());
                    formData.append('name', this.state.name);
                    formData.append('date_period', this.state.date_period);
                    formData.append('participants', this.state.participants);
                    formData.append('details', this.state.details);
                    formData.append('code', this.state.code);
                    formData.append('income', this.state.income);
                    formData.append('forms_id', this.state.forms);
                    formData.append('username', this.state.username);
                    formData.append('image_data', blob);

                    const putResponse = await axios.put(`${process.env.REACT_APP_API}/activities`, formData, authHeader);
                    if (putResponse.data.message === 'successfully') {

                        await this.promisedSetState({ loading: false })

                        Swal.fire({
                            title: "คุณแก้ไขกิจกรรมเรียบร้อยแล้ว",
                            icon: "success",
                            timer: 2000,
                            timerProgressBar: true,
                            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                        }).then(async () => {
                            window.location.replace('/activities');
                        });
                    }
                } catch (error) {
                    handleAuthFailure(error);
                }
            }
        }
    }

    render() {

        const FullPageLoader = () => (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 9999 // Ensure it’s on top of other content
            }}>
                <Loader content="Loading..." /> {/* Adjust as needed */}
            </div>
        );

        return (
            <>
                {this.state.content}
    
                {/* Open Modal */}
                <Modal size='md' open={this.state.modal} onClose={() => this.setState({ modal: false })}>
                    <Modal.Body>
                        <div style={{ width: '100%', height: '600px' }}>
                            <Cropper
                                image={this.state.image}
                                crop={this.state.crop}
                                zoom={this.state.zoom}
                                aspect={4 / 1}
                                onCropChange={(e) => this.setState({ crop: e })}
                                onZoomChange={(e) => this.setState({ zoom: e })}
                                onCropComplete={this.onCropComplete}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='p-2'>
                        <Button
                            onClick={async () => {
                                await this.promisedSetState({ loading: true }); // Set loading state to true
                                const croppedImage = await this.getBase64(this.state.image, this.state.croppedArea, 0);
                                await this.promisedSetState({ croppedImage, modal: false });
                                await this.onForms({ read: false, id: this.props.params.id });
                                await this.promisedSetState({ loading: false }); // Reset loading state
                            }}
                            appearance="primary"
                            startIcon={<i className="bi bi-check2-square"></i>}>
                            ยืนยัน
                        </Button>
                        <Button
                            onClick={() => this.setState({ modal: false })}
                            appearance="subtle">
                            ยกเลิก
                        </Button>
                    </Modal.Footer>
                </Modal>
    
                {/* Full-Page Loader */}
                {this.state.loading && <FullPageLoader />}
            </>
        );
    }
    
}

export default withRouter(Edit);