import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withRouter from './withRouter';

// import axios
import axios from 'axios';

// import $
import $ from 'jquery';

// Rsuite
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import {
    Input
    , InputNumber
    , IconButton
    , ButtonToolbar
    , Form
    , Stack
    , Table
    , Button
    , Modal
    , Text
    , Uploader
    , SelectPicker
    , Loader
} from 'rsuite';

// Bootstrap
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import Sweetalert2
import Swal from 'sweetalert2'

class Assessment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem('token')).token,
            username: JSON.parse(localStorage.getItem('token')).username,
            content: [],
            path: '',
            evaluator: 0,
            title: '',
            score: 0,
            suggestions: '',
            files: [],
            loading: false
        };
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {
        await this.onRenderContent({ read: true })
    }

    onRenderContent = async (obj) => {
        if (obj.read) {
            const { Column, HeaderCell, Cell } = Table;
            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            const handleAuthFailure = (error) => {
                if (error.response && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: error.message,
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                }
            };

            try {
                const activityResponse = await axios.get(`${process.env.REACT_APP_API}/activities/${this.props.params.id}`, authHeader);
                await Promise.all(activityResponse.data.map(async item => await this.promisedSetState({ path: item.name })));

                const assessmentResponse = await axios.get(`${process.env.REACT_APP_API}/assessment/activity/${this.props.params.id}`, authHeader);

                this.dataArr = assessmentResponse.data.map((data, index) => ({
                    id: data.id,
                    index: index + 1,
                    evaluator: data.evaluator === 'participants' ? 'ผู้เข้าร่วมกิจกรรม' : 'ผู้ปกครอง',
                    title: data.title,
                    score: data.score
                }));

                await this.promisedSetState({
                    content: (
                        <div>
                            <Row className='mt-4'>
                                <Breadcrumb size='lg' separator={<AngleRightIcon />} >
                                    <Breadcrumb.Item><Link to="/"><i className="bi bi-house-fill"></i></Link></Breadcrumb.Item>
                                    <Breadcrumb.Item><Link to="/activities">ข้อมูลกิจกรรม</Link></Breadcrumb.Item>
                                    <Breadcrumb.Item>{this.state.path}</Breadcrumb.Item>
                                    <Breadcrumb.Item active>ผลการประเมิน</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>

                            <Row className='mt-3'>
                                <Col>
                                    <Button size='sm'
                                        color="green"
                                        appearance="primary"
                                        startIcon={<i className="bi bi-plus-lg"></i>}
                                        onClick={() => this.onModal({ method: 'insert', activity_id: this.props.params.id })}>
                                        เพิ่มผลการประเมิน
                                    </Button>
                                </Col>
                            </Row>

                            <Table
                                autoHeight
                                data={this.dataArr}
                                bordered
                                cellBordered
                                sortColumn={this.state.sortColumn}
                                sortType={this.state.sortType}
                                onSortColumn={this.handleSortColumn}
                                className='rounded shadow-sm mt-3'
                                onRowClick={rowData => {
                                    console.log(rowData);
                                }}
                            >
                                <Column width={60} align="center" sortable>
                                    <HeaderCell>ลำดับ</HeaderCell>
                                    <Cell dataKey="index" />
                                </Column>

                                <Column width={230} sortable>
                                    <HeaderCell>ผู้ประเมิน</HeaderCell>
                                    <Cell dataKey="evaluator" />
                                </Column>

                                <Column width={735} sortable>
                                    <HeaderCell>เรื่อง</HeaderCell>
                                    <Cell dataKey="title" />
                                </Column>

                                <Column width={180} sortable>
                                    <HeaderCell>คะแนน</HeaderCell>
                                    <Cell dataKey="score" />
                                </Column>

                                <Column align='center' width={90} fixed="right">
                                    <HeaderCell>...</HeaderCell>

                                    <Cell align='center' style={{ padding: '6px' }}>
                                        {rowData => (
                                            <ButtonToolbar>
                                                <IconButton
                                                    size='sm'
                                                    color="yellow"
                                                    appearance="primary"
                                                    icon={<i className="bi bi-pencil-square"></i>}
                                                    onClick={() => this.onModal({ method: 'update', activity_id: this.props.params.id, id: rowData.id })} />
                                                <IconButton
                                                    size='sm'
                                                    color="red"
                                                    appearance="primary"
                                                    icon={<i className="bi bi-trash3-fill"></i>}
                                                    onClick={() => this.onRemove({ id: rowData.id, activity_id: this.props.params.id })} />
                                            </ButtonToolbar>
                                        )}
                                    </Cell>
                                </Column>
                            </Table>
                        </div>
                    )
                });
            } catch (error) {
                handleAuthFailure(error);
            }
        }
    }

    Validate = (params, clss, cond) => {
        if (params === cond) {
            $('.' + clss).addClass("required")
            Swal.fire({
                title: "กรุณากรอกข้อมูลให้ครบถ้วน",
                icon: "warning",
                timer: 2000,
                timerProgressBar: true,
                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            });
            return false
        } else if (params !== cond) {
            $('.' + clss).removeClass("required")
            return true
        }
    }

    onUpload = (obj) => {
        if (obj.method === 'insert') {
            this.setState(prevState => ({
                files: [...prevState.files, {
                    files: {
                        mode: 'inserting',
                        file_id: null,
                        key: obj.key,
                        name: obj.event.name,
                        mimetype: obj.response.mimetype,
                        file_data: obj.response.buffer
                    }
                }]
            }))
        } else if (obj.method === 'delete') {
            this.state.files
                .filter(res => res.files.key === obj.key)
                .map(item => item.files.mode = 'deleting')
        }
    }

    bufferToBlob = (obj) => {
        const byteArray = new Uint8Array(obj.data)
        return new Blob([byteArray], { type: obj.mimetype })
    }

    to2decimal = (value) => {
        return `${(value * 1).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')}`
    }

    onModal = async (obj) => {
        if (obj.method === 'insert') {
            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            const handleAuthFailure = (error) => {
                if (error.response && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: error.message,
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                }
            };

            try {
                await this.promisedSetState({
                    files: [],
                    modal: <Modal size='md' open={true} onClose={() => this.setState({ modal: [] })}>
                        <Modal.Header>
                            <Modal.Title>ผลการประเมิน</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form fluid>
                                <Form.Group>
                                    <Form.ControlLabel>ผู้ประเมิน</Form.ControlLabel>
                                    <SelectPicker
                                        className='Av21v'
                                        size='md'
                                        placeholder='กรุณาเลือกรายการ'
                                        searchable={false}
                                        style={{ width: 300 }}
                                        data={[{ label: 'ผู้เข้าร่วมกิจกรรม', value: 'participants' }, { label: 'ผู้ปกครอง', value: 'parent' }].map(
                                            item => ({ label: item.label, value: item.value })
                                        )}
                                        onSelect={e => this.setState({ evaluator: e })} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.ControlLabel>เรื่อง</Form.ControlLabel>
                                    <Input
                                        className='LxGiN'
                                        name='title'
                                        onChange={e => this.setState({ title: e })} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.ControlLabel>คะแนน</Form.ControlLabel>
                                    <Stack wrap spacing={2}>
                                        <InputNumber
                                            className='55res'
                                            name="score"
                                            defaultValue={0}
                                            max={5}
                                            min={0}
                                            formatter={this.to2decimal}
                                            style={{ width: '90%' }}
                                            onChange={e => this.setState({ score: e })} />
                                        <i className='text-start'>1 - 5 คะแนน</i>
                                    </Stack>
                                </Form.Group>
                                <Form.Group>
                                    <Form.ControlLabel>ข้อเสนอแนะ</Form.ControlLabel>
                                    <Input
                                        as="textarea"
                                        rows={5}
                                        onChange={e => this.setState({ suggestions: e })} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.ControlLabel>เอกสารแนบ</Form.ControlLabel>
                                    <Uploader
                                        action={`${process.env.REACT_APP_API}/assessment/files`}
                                        headers={authHeader}
                                        draggable
                                        onSuccess={(o, e) => this.onUpload({
                                            method: 'insert',
                                            key: e.fileKey,
                                            response: o,
                                            event: e
                                        })}
                                        onRemove={(e) => this.onUpload({
                                            method: 'delete',
                                            key: e.fileKey
                                        })}>
                                        <div style={{ height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span>คลิ๊กหรือลากไฟล์เพื่ออัปโหลด</span>
                                        </div>
                                    </Uploader>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                color="blue"
                                appearance="primary"
                                startIcon={<i class="bi bi-floppy2-fill"></i>}
                                className='ps-2 pe-2'
                                onClick={() => this.onConfirm({ method: 'insert', activity_id: obj.activity_id })} >
                                บันทึก
                            </Button>
                            <Button
                                color="red"
                                appearance="subtle"
                                startIcon={<i class="bi bi-trash3-fill"></i>}
                                className='ps-2 pe-2'
                                onClick={() => this.setState({ modal: [] })} >
                                ยกเลิก
                            </Button>
                        </Modal.Footer>
                    </Modal>
                })
            } catch (error) {
                handleAuthFailure(error);
            }
        } else if (obj.method === 'update') {
            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            const handleAuthFailure = (error) => {
                if (error.response && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: error.message,
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                }
            };

            try {
                await axios.get(`${process.env.REACT_APP_API}/assessment/${obj.id}`, authHeader).then(async response => {
                    await response.data.map(async item => {

                        await axios.get(`${process.env.REACT_APP_API}/assessment/uploads/${item.id}`, authHeader).then(async response => {

                            // set files empty
                            this.setState({ files: [] })

                            // list default files
                            this.defaultFileList = []
                            response.data.forEach(item => {
                                this.defaultFileList.push({
                                    name: item.originalname,
                                    fileKey: item.id,
                                    url: `${process.env.REACT_APP_UPLOAD}/${item.path}`
                                });

                                this.setState(prevState => ({
                                    files: [...prevState.files, {
                                        files: {
                                            mode: 'reading',
                                            file_id: item.id,
                                            key: item.id,
                                            name: item.filename,
                                            mimetype: item.mimetype,
                                            file_data: null
                                        }
                                    }]
                                }));
                            });
                        }).then(async () => await this.promisedSetState({
                            evaluator: item.evaluator,
                            title: item.title,
                            score: item.score,
                            suggestions: item.suggestions,
                            modal: <Modal size='md' open={true} onClose={() => this.setState({ modal: [] })}>
                                <Modal.Header>
                                    <Modal.Title>ผลการประเมิน</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form fluid>
                                        <Form.Group>
                                            <Form.ControlLabel>ผู้ประเมิน</Form.ControlLabel>
                                            <SelectPicker
                                                className='Av21v'
                                                size='md'
                                                placeholder='กรุณาเลือกรายการ'
                                                searchable={false}
                                                style={{ width: 300 }}
                                                defaultValue={item.evaluator}
                                                data={[{ label: 'ผู้เข้าร่วมกิจกรรม', value: 'participants' }, { label: 'ผู้ปกครอง', value: 'parent' }].map(
                                                    item => ({ label: item.label, value: item.value })
                                                )}
                                                onSelect={e => this.setState({ evaluator: e })} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.ControlLabel>เรื่อง</Form.ControlLabel>
                                            <Input
                                                defaultValue={item.title}
                                                className='LxGiN'
                                                name='title'
                                                onChange={e => this.setState({ title: e })} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.ControlLabel>คะแนน</Form.ControlLabel>
                                            <Stack wrap spacing={2}>
                                                <InputNumber
                                                    className='55res'
                                                    name="score"
                                                    max={5}
                                                    min={0}
                                                    formatter={this.to2decimal}
                                                    style={{ width: '90%' }}
                                                    defaultValue={item.score}
                                                    onChange={e => this.setState({ score: e })} />
                                                <i className='text-start'>1 - 5 คะแนน</i>
                                            </Stack>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.ControlLabel>ข้อเสนอแนะ</Form.ControlLabel>
                                            <Input
                                                as="textarea"
                                                rows={5}
                                                defaultValue={item.suggestions}
                                                onChange={e => this.setState({ suggestions: e })} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.ControlLabel>เอกสารแนบ</Form.ControlLabel>
                                            <Uploader
                                                action={`${process.env.REACT_APP_API}/assessment/files`}
                                                headers={authHeader}
                                                fileList={this.defaultFileList}
                                                draggable
                                                renderFileInfo={(file, fileElement) => {
                                                    return (
                                                        <>
                                                            {file.url ?
                                                                (<Stack
                                                                    wrap
                                                                    spacing={6} >
                                                                    <Text onClick={() => this.onPreview({ url: file.url, type: file.url.split('.').pop() })} color="blue">{file.name}</Text>
                                                                    <IconButton
                                                                        size='sm'
                                                                        circle
                                                                        icon={<i class="bi bi-download"></i>}
                                                                        appearance="default"
                                                                        onClick={() => this.onDownload({ url: file.url, fileName: file.name })} />
                                                                </Stack>) : (<Stack
                                                                    wrap
                                                                    spacing={6} >
                                                                    {file.name}
                                                                </Stack>)}
                                                        </>
                                                    );
                                                }}
                                                onSuccess={(o, e) => this.onUpload({
                                                    method: 'insert',
                                                    key: e.fileKey,
                                                    response: o,
                                                    event: e
                                                })}
                                                onRemove={(e) => this.onUpload({
                                                    method: 'delete',
                                                    key: e.fileKey
                                                })}>
                                                <div style={{ height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <span>คลิ๊กหรือลากไฟล์เพื่ออัปโหลด</span>
                                                </div>
                                            </Uploader>
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        color="blue"
                                        appearance="primary"
                                        startIcon={<i class="bi bi-floppy2-fill"></i>}
                                        className='ps-2 pe-2'
                                        onClick={() => this.onConfirm({ method: 'update', activity_id: obj.activity_id, id: item.id })} >
                                        บันทึก
                                    </Button>
                                    <Button
                                        color="red"
                                        appearance="subtle"
                                        startIcon={<i class="bi bi-trash3-fill"></i>}
                                        className='ps-2 pe-2'
                                        onClick={() => this.setState({ modal: [] })} >
                                        ยกเลิก
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        })
                        )
                    })
                })
            } catch (error) {
                handleAuthFailure(error);
            }
        }
    }

    onPreview = async (obj) => {
        if (obj.type === 'jpg' || obj.type === 'jpeg' || obj.type === 'png' || obj.type === 'gif') {
            await this.promisedSetState({
                preview: <Modal size='md' open={true} onClose={() => this.setState({ preview: [] })}>
                    <Modal.Header>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Image width='100%' height='100%' src={obj.url} rounded />
                    </Modal.Body>
                </Modal>
            })
        } else if (obj.type === 'pdf') {
            await this.promisedSetState({
                preview: <Modal size='md' open={true} onClose={() => this.setState({ preview: [] })}>
                    <Modal.Header>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <iframe
                            src={obj.url}
                            width="100%"
                            height="500"
                            title={`${obj.name}`} // or another descriptive title
                        ></iframe>
                    </Modal.Body>
                </Modal>
            })
        } else {
            Swal.fire({
                icon: "warning",
                title: "ไม่สามารถ Preview",
                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                timer: 1500
            })
        }
    }

    onDownload = (obj) => {
        fetch(obj.url)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = obj.fileName || "downloaded-file";
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    }

    onConfirm = async (obj) => {
        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        const handleAuthFailure = (error) => {
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        };

        try {
            this.data = true;
            this.data = this.data && this.Validate(this.state.evaluator, 'Av21v', 0);
            this.data = this.data && this.Validate(this.state.title, 'LxGiN', '');
            this.data = this.data && this.Validate(this.state.score, '55res', 0);

            if (this.data) {

                await this.promisedSetState({ loading: true}); // Start loading

                await this.promisedSetState({ modal: [] });

                let response;
                if (obj.method === 'insert') {
                    response = await axios.post(`${process.env.REACT_APP_API}/assessment`, {
                        activity_id: obj.activity_id,
                        evaluator: this.state.evaluator,
                        title: this.state.title,
                        score: this.state.score,
                        suggestions: this.state.suggestions,
                        username: this.state.username
                    }, authHeader);
                } else if (obj.method === 'update') {
                    response = await axios.put(`${process.env.REACT_APP_API}/assessment`, {
                        id: obj.id,
                        activity_id: obj.activity_id,
                        evaluator: this.state.evaluator,
                        title: this.state.title,
                        score: this.state.score,
                        suggestions: this.state.suggestions,
                        username: this.state.username
                    }, authHeader);
                }

                if (response.data.message === 'successfully') {
                    let message = 0;

                    for (let i = 0; i < this.state.files.length; i++) {
                        const file = this.state.files[i].files;
                        if (file.mode === 'inserting') {
                            const formData = new FormData();
                            formData.append('activity_id', obj.activity_id);
                            formData.append('assessment_id', obj.method === 'insert' ? response.data.id : obj.id);
                            formData.append('file_name', file.name);
                            formData.append('file_data', this.bufferToBlob({ mimetype: file.mimetype, data: file.file_data.data }));
                            formData.append('username', this.state.username);

                            try {
                                const uploadResponse = await axios.post(`${process.env.REACT_APP_API}/assessment/uploads`, formData, authHeader);
                                if (uploadResponse.data.message === 'successfully') {
                                    message++;
                                }
                            } catch (error) {
                                console.log(error);
                                handleAuthFailure(error);
                            }
                        } else if (file.mode === 'deleting' && file.file_id !== null) {
                            try {
                                const deleteResponse = await axios.delete(`${process.env.REACT_APP_API}/assessment/${file.file_id}`, authHeader);
                                if (deleteResponse.data.message === 'successfully') {
                                    message++;
                                }
                            } catch (error) {
                                console.log(error);
                                handleAuthFailure(error);
                            }
                        } else {
                            message++;
                        }
                    }

                    if (message === this.state.files.length) {

                        await this.promisedSetState({ loading: false }); // Stop loading

                        await this.promisedSetState({ modal: [] });
                        
                        Swal.fire({
                            title: obj.method === 'insert' ? "คุณเพิ่มข้อมูลเรียบร้อยแล้ว" : "คุณแก้ไขข้อมูลเรียบร้อยแล้ว",
                            icon: "success",
                            timer: 2000,
                            timerProgressBar: true,
                            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                        }).then(async () => {
                            
                            await this.onRenderContent({ read: true })
                        })
                    }
                }
            }
        } catch (error) {
            handleAuthFailure(error);
        }
    }

    onRemove = async ({ id, activity_id }) => {

        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        const handleAuthFailure = (error) => {
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        };

        // Display confirmation dialog
        Swal.fire({
            title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            cancelButtonText: `<div style="font-size: 1.2rem">ยกเลิก</div>`
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // Perform the delete request
                    const response = await axios.delete(`${process.env.REACT_APP_API}/assessment/remove/${id}/${activity_id}`, authHeader);

                    // Check for success response
                    if (response.data.message === 'successfully') {
                        Swal.fire({
                            title: "ลบข้อมูลเรียบร้อยแล้ว!",
                            icon: "success",
                            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                        }).then(async () => {
                            await this.promisedSetState({ modal: [] }); // Update state within the same block
                            await this.onRenderContent({ read: true });
                        });

                        // Optionally, you may want to refresh the data or state here
                        // await this.onRenderContent({ read: true });
                    }
                } catch (error) {
                    // Handle errors, including authentication failures
                    handleAuthFailure(error);
                }
            }
        });
    };

    render() {
        return (
            <>
                {this.state.content}
                {this.state.modal}
                {this.state.preview}
    
                {/* Full-Page Loader */}
                {this.state.loading && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999 // Ensure it’s on top of other content
                    }}>
                        <Loader content="Loading..." vertical />
                    </div>
                )}
            </>
        );
    }    
}

export default withRouter(Assessment);