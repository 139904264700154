import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import withRouter from './withRouter';

import axios from 'axios';

import { 
    Form
    , Stack 
    , Loader
} from 'rsuite';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Images from 'react-bootstrap/Image';

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem('token')).token,
            content: null,  // Initialize with null to handle loading state
            loading: false
        };
    }

    async componentDidMount() {
        await this.onRenderContent({ read: true, id: this.props.params.id });
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    handleAuthFailure = (error) => {
        if (error.response && error.response.data.message === 'AuthenticationFailed') {
            window.location.replace('/login');
        } else {
            // You can replace this with your preferred error handling mechanism
            console.error("Error fetching data:", error.message);
        }
    };

    onRenderContent = async (obj) => {
        if (obj.read) {
            try {

                await this.promisedSetState({ loading: true }); // Start loading

                const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };
                const { id } = this.props.params;

                // Fetch activity details
                const response = await axios.get(`${process.env.REACT_APP_API}/activities/${id}`, authHeader);
                const data = response.data[0]; // Assuming response data is an array and we need the first item

                const formNameResponse = await axios.get(`${process.env.REACT_APP_API}/forms/${data.forms_id}`, authHeader);
                const formNames = formNameResponse.data.map(item => item.name).join(', ');

                const participantsResponse = await axios.post(`${process.env.REACT_APP_API}/participants/activities`, { id: data.id, searchQuery: '' }, authHeader);
                const participants = participantsResponse.data;
                const participantsTotal = participants.length;
                const participantsPayment = participants.filter(res => res.payment === 1).length;

                const content = (
                    <div key={data.id}>
                        <Row className='mt-4'>
                            <Breadcrumb size='lg' separator={<AngleRightIcon />}>
                                <Breadcrumb.Item><Link to="/"><i className="bi bi-house-fill"></i></Link></Breadcrumb.Item>
                                <Breadcrumb.Item><Link to="/activities">ข้อมูลกิจกรรม</Link></Breadcrumb.Item>
                                <Breadcrumb.Item active>{data.name}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>

                        <Form fluid>
                            <Row>
                                <Col sm>
                                    <Form.Group controlId="name">
                                        <Stack className='cNlLu' spacing={6} direction='column' alignItems='flex-start'>
                                            <Images
                                                className="border"
                                                src={`${process.env.REACT_APP_UPLOAD}/${data.image_path}`}
                                                rounded
                                                fluid
                                                width={1600}
                                                height={400}
                                            />
                                        </Stack>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm>
                                    <Form.Group>
                                        <Form.ControlLabel><b>ชื่อกิจกรรม :</b> {data.name}</Form.ControlLabel>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm>
                                    <Form.Group>
                                        <Form.ControlLabel><b>วันที่จัดกิจกรรม :</b> {this.formatDateRange(data.date_period)}</Form.ControlLabel>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm>
                                    <Form.Group>
                                        <Form.ControlLabel><b>จำนวนเปิดรับสมัคร :</b> {data.participants} คน</Form.ControlLabel>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm>
                                    <Form.Group>
                                        <Form.ControlLabel><b>แบบฟอร์มใบสมัคร :</b> {formNames}</Form.ControlLabel>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm>
                                    <Form.Group>
                                        <Form.ControlLabel><b>Link ใบสมัคร :</b> <pre>{`${process.env.REACT_APP_WEB}/forms/${data.key}`}</pre></Form.ControlLabel>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>
                                    <Form.Group>
                                        <Form.ControlLabel><b>รายละเอียด :</b> <pre>{data.details}</pre></Form.ControlLabel>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>
                                    <Form.Group>
                                        <Form.ControlLabel><b>จำนวนผู้สมัคร :</b> {participantsTotal} คน</Form.ControlLabel>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm>
                                    <Form.Group>
                                        <Form.ControlLabel><b>จำนวนผู้ที่ชำระเงินแล้ว :</b> {participantsPayment} คน</Form.ControlLabel>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm>
                                    <Form.Group>
                                        <Form.ControlLabel><b>รหัสกิจกรรม :</b> {data.code}</Form.ControlLabel>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm>
                                    <Form.Group>
                                        <Form.ControlLabel><b>รายได้ :</b> {await this.formatCurrency(data.income)} บาท</Form.ControlLabel>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm>
                                    <Form.Group>
                                        <Form.ControlLabel><b>สถานะ :</b> {await this.statusMessage({ status: data.status, reason: data.status_reason })}</Form.ControlLabel>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                );

                await this.promisedSetState({ content });

                await this.promisedSetState({ loading: false }); // Stop loading

            } catch (error) {
                this.handleAuthFailure(error);
            }
        }
    }

    formatCurrency = async (number) => {
        const numericValue = Number(number); // Convert to number
        if (isNaN(numericValue)) {
            throw new Error('Invalid number');
        }
        return numericValue.toLocaleString('en-US');
    };

    statusMessage = async ({ status, reason }) => {
        switch (status) {
            case 'completed':
                return <span style={{ color: 'green' }}>{status}</span>;
            case 'cancel':
                return <><span style={{ color: 'red' }}>{status}</span> ({reason})</>;
            case 'active':
                return <span style={{ color: 'blue' }}>{status}</span>;
            default:
                return <span>{status}</span>;
        }
    };

    formatDateRange = (datePeriod) => {
        const [start, end] = datePeriod.split(',');
        const startDate = new Date(start);
        const endDate = new Date(end);

        const formatDate = (date) => `${date.getDate()} ${this.getMonthName(date.getMonth() + 1)} ${date.getFullYear() + 543}`;

        if (startDate.getFullYear() === endDate.getFullYear()) {
            if (startDate.getMonth() === endDate.getMonth()) {
                return startDate.getDate() === endDate.getDate()
                    ? `วันที่ ${formatDate(startDate)}`
                    : `วันที่ ${formatDate(startDate)} - ${formatDate(endDate)}`;
            } else {
                return `วันที่ ${formatDate(startDate)} - ${formatDate(endDate)}`;
            }
        } else {
            return `วันที่ ${formatDate(startDate)} - ${formatDate(endDate)}`;
        }
    };

    getMonthName = (month) => {
        const months = [
            'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
            'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
        ];
        return months[month - 1];
    };

    render() {
        return (
            <>
                {this.state.content}
                <br />
    
                {/* Full-Page Loader */}
                {this.state.loading && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999 // Ensure it’s on top of other content
                    }}>
                        <Loader content="Loading..." vertical />
                    </div>
                )}
            </>
        );
    }    
}

export default withRouter(Details);