import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import axios
import axios from 'axios';

// import Rsuite
import {
    Button
    , IconButton
    , Dropdown
    , Tag
    , Modal
    , Pagination
    , Form
    , InputGroup
    , AutoComplete
    , ButtonGroup
    , ButtonToolbar
    , Loader
} from 'rsuite';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';

// import Bootstrap
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

// import Sweetalert2
import Swal from 'sweetalert2'

// import CopyToClipboard
import { CopyToClipboard } from 'react-copy-to-clipboard'

// import QR code
import QRCode from "react-qr-code";

// import html2canvas
import html2canvas from 'html2canvas';

// Pages
import HistoryPage from './history'
import CalendarPage from './calendar'

export default class Activities extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem('token')).token,
            activities: [],
            modal: [],
            copied: false,
            loading: false,
            limit: 5,
            page: 1,
            searchQuery: '',
            searchDataset: [],
            pagination: [],
            activeTab: 1
        }

        this.onRenderContent({ read: true })
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    onRenderContent = async (obj) => {
        if (obj.read) {
            try {
                // Set up the Axios headers with the Authorization token
                const headers = {
                    'Authorization': `Bearer ${this.state.token}`
                };

                // Fetch activities with authentication headers
                const response = await axios.post(`${process.env.REACT_APP_API}/activities/paginate`, {
                    limit: this.state.limit,
                    page: this.state.page,
                    searchQuery: this.state.searchQuery
                }, { headers });

                await this.promisedSetState({ loading: true }); // Start loading

                const activitiesData = response.data.rows;

                // Fetch participants count for each activity with authentication headers
                const activitiesWithParticipants = await Promise.all(activitiesData.map(async (item) => {
                    const participantsResponse = await axios.post(`${process.env.REACT_APP_API}/participants/activities`,
                        { id: item.id, searchQuery: '' },
                        { headers }
                    );
                    return { ...item, participantsCount: participantsResponse.data.length };
                }));

                // Prepare the activities and searchDataset
                const activities = activitiesWithParticipants.map(item => (
                    <Row className='mt-4' key={item.id}>
                        <Col sm>
                            <Card className='shadow-sm'>
                                <Card.Header className={item.type_bg}>
                                    <b className='text-white'>{item.name}</b>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col sm>
                                            <Image className='rounded'
                                                style={{ width: '100%', height: '100%' }}
                                                src={`${process.env.REACT_APP_UPLOAD}/${item.image_path}`}
                                                fluid />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={1} className='mt-2 mb-2'>
                                            <Stack direction="horizontal" gap={2}>
                                                <Tag color="violet" size="lg"><i class="bi bi-person-fill"></i></Tag>
                                                <div>{item.participantsCount}</div>
                                            </Stack>
                                        </Col>
                                        <Col lg={4} className='mt-2 mb-2'>
                                            <Stack direction="horizontal" gap={2}>
                                                <Tag color="blue" size="lg"><i class="bi bi-calendar3"></i></Tag>
                                                <div>{this.onDate({ start: item.date_period.split(',')[0], end: item.date_period.split(',')[1] })}</div>
                                            </Stack>
                                        </Col>
                                        <Col className='mt-2 mb-2'>
                                            <Stack direction="horizontal" gap={1}>
                                                <Tag color="yellow" size="lg"><i class="bi bi-share"></i></Tag>
                                                <div style={{ display: 'block', overflow: 'hidden', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} align="left">{process.env.REACT_APP_WEB}/forms/{item.key}</div>
                                                <CopyToClipboard text={`${process.env.REACT_APP_WEB}/forms/${item.key}`}
                                                    onCopy={async () => Swal.fire({
                                                        icon: "success",
                                                        title: "Copy Link เรียบร้อยแล้ว",
                                                        showConfirmButton: false,
                                                        timer: 1500
                                                    })}>
                                                    <IconButton size='sm' icon={<i class="bi bi-copy"></i>} />
                                                </CopyToClipboard>{' '}
                                                <IconButton
                                                    onClick={() => this.onModal({ url: `${process.env.REACT_APP_WEB}/forms/${item.key}` })}
                                                    size='sm'
                                                    icon={<i class="bi bi-qr-code-scan"></i>} />
                                            </Stack>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className='d-flex justify-content-end'>
                                            <Dropdown
                                                title="ดำเนินการ"
                                                startIcon={<i class="bi bi-gear-fill"></i>}
                                                size="lg"
                                                className='float-end'>
                                                <Link to={`/activities/details/${item.id}`}>
                                                    <Dropdown.Item icon={<i className="bi bi-file-earmark-text"></i>}>ดูรายละเอียด</Dropdown.Item>
                                                </Link>
                                                <Link to={`/activities/${item.id}`}>
                                                    <Dropdown.Item icon={<i className="bi bi-pen"></i>}>แก้ไขกิจกรรม</Dropdown.Item>
                                                </Link>
                                                <Dropdown.Item
                                                    onClick={() => this.onRemove({ id: item.id })}
                                                    icon={<i className="bi bi-trash3"></i>}>ลบกิจกรรม
                                                </Dropdown.Item>
                                                <Link to={`/activities/participants/${item.id}`} >
                                                    <Dropdown.Item icon={<i className="bi bi-person-check"></i>}>ข้อมูลผู้สมัคร</Dropdown.Item>
                                                </Link>
                                                <Link to={`/activities/assessment/${item.id}`} >
                                                    <Dropdown.Item icon={<i className="bi bi-clipboard2-data-fill"></i>}>ผลการประเมิน</Dropdown.Item>
                                                </Link>
                                                <Dropdown.Item
                                                    icon={<i className="bi bi-check2-circle"></i>}
                                                    onClick={() => this.onClose({ id: item.id })} >ปิดกิจกรรม</Dropdown.Item>
                                            </Dropdown>
                                        </div>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                ));

                const searchDataset = activitiesWithParticipants.map(item => ({
                    label: item.name,
                    value: item.name.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;")
                }));

                // Set state in one go
                await this.promisedSetState({
                    header: [],
                    activities,
                    searchDataset,
                    pagination: (
                        <Row className='mt-3'>
                            <Pagination
                                className='d-flex justify-content-center'
                                prev
                                last
                                next
                                first
                                size="lg"
                                maxButtons={5}
                                total={response.data.count}
                                limit={this.state.limit}
                                activePage={this.state.page}
                                onChangePage={async (e) => await this.promisedSetState({ page: e }).then(async () => this.onRenderContent({ read: true }))}
                            />
                        </Row>
                    )
                });

                await this.promisedSetState({ loading: false }); // Stop loading

            } catch (error) {
                // Handle authentication failure
                if (error.response && error.response.data && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    console.error("Error rendering content", error);
                }
            }
        }
    }

    onModal = (obj) => {
        this.setState({
            modal: <div>
                <Modal size='sm' open={true} onClose={() => this.setState({ modal: [] })}>
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                        <div id="QTUEIFw4"
                            style={{ height: "auto", margin: "0 auto", maxWidth: '90%', width: "100%" }}>
                            <QRCode
                                size={512}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={obj.url}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='mt-2 d-flex justify-content-center'>
                        <Button
                            onClick={() => this.onDownload()}>Download</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        })
    }

    onDownload = async () => {
        const name = await this.randString(5)
        html2canvas(document.querySelector("#QTUEIFw4"), { scale: 8 }).then((canvas) => {
            // Convert canvas to blob
            canvas.toBlob(blob => {
                // Create download link
                var pngURL = window.URL.createObjectURL(blob);
                var tempLink = document.createElement('a');
                tempLink.href = pngURL;
                tempLink.setAttribute('download', `${name}.png`); // Set default filename
                document.body.appendChild(tempLink); // Required for Firefox
                tempLink.click();
                document.body.removeChild(tempLink); // Clean up
            }, 'image/png');
        });
    }

    randString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    onMonth = (month) => {
        let data;
        switch (month) {
            case 1: data = 'มกราคม'; break;
            case 2: data = 'กุมภาพันธ์'; break;
            case 3: data = 'มีนาคม'; break;
            case 4: data = 'เมษายน'; break;
            case 5: data = 'พฤษภาคม'; break;
            case 6: data = 'มิถุนายน'; break;
            case 7: data = 'กรกฎาคม'; break;
            case 8: data = 'สิงหาคม'; break;
            case 9: data = 'กันยายน'; break;
            case 10: data = 'ตุลาคม'; break;
            case 11: data = 'พฤศจิกายน'; break;
            case 12: data = 'ธันวาคม'; break;
            default: data = 'Invalid month'; // Handle invalid month input
        }

        return data;
    }

    onDate = (obj) => {
        let dateStart = new Date(`${obj.start.split(' ')[1]} ${obj.start.split(' ')[2]}, ${obj.start.split(' ')[3]} ${obj.start.split(' ')[4]}`)
        let dateEnd = new Date(`${obj.end.split(' ')[1]} ${obj.end.split(' ')[2]}, ${obj.end.split(' ')[3]} ${obj.end.split(' ')[4]}`)

        if (dateStart.getFullYear() === dateEnd.getFullYear()) {

            if (dateStart.getMonth() === dateEnd.getMonth()) {

                if (dateStart.getDate() === dateEnd.getDate()) {

                    return `วันที่ ${dateStart.getDate()} ${this.onMonth(dateStart.getMonth() + 1)} ${dateStart.getFullYear() + 543}`

                } else {

                    return `วันที่ ${dateStart.getDate()} - ${dateEnd.getDate()} ${this.onMonth(dateStart.getMonth() + 1)} ${dateStart.getFullYear() + 543}`

                }

            } else if (dateStart.getMonth() !== dateEnd.getMonth()) {

                return `วันที่ ${dateStart.getDate()}  ${this.onMonth(dateStart.getMonth() + 1)} - ${dateEnd.getDate()} ${this.onMonth(dateEnd.getMonth() + 1)} ${dateStart.getFullYear() + 543}`

            }
        } else if (dateStart.getFullYear() !== dateEnd.getFullYear()) {

            if (dateStart.getMonth() === dateEnd.getMonth()) {

                return `วันที่ ${dateStart.getDate()} - ${dateEnd.getDate()} ${this.onMonth(dateStart.getMonth() + 1)} ${dateStart.getFullYear() + 543}`

            } else if (dateStart.getMonth() !== dateEnd.getMonth()) {

                return `วันที่ ${dateStart.getDate()}  ${this.onMonth(dateStart.getMonth() + 1)} ${dateStart.getFullYear() + 543} - ${dateEnd.getDate()} ${this.onMonth(dateEnd.getMonth() + 1)} ${dateEnd.getFullYear() + 543}`

            }
        }
    }

    onRemove = async (obj) => {
        Swal.fire({
            title: "คุณต้องการลบกิจกรรมใช่หรือไม่?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            cancelButtonText: `<div style="font-size: 1.2rem">ยกเลิก</div>`
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Start loading
                await this.promisedSetState({ loading: true });

                try {
                    // Set up the Axios headers with the Authorization token
                    const headers = {
                        'Authorization': `Bearer ${this.state.token}`
                    };

                    // Perform the delete request with authentication headers
                    const response = await axios.delete(`${process.env.REACT_APP_API}/activities/${obj.id}`, { headers });

                    if (response.data.message === 'successfully') {
                        Swal.fire({
                            title: "ลบกิจกรรมเรียบร้อยแล้ว!",
                            icon: "success",
                            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.replace('/activities');
                            }
                        });
                    }

                } catch (error) {
                    // Handle authentication failure
                    if (error.response && error.response.data && error.response.data.message === 'AuthenticationFailed') {
                        window.location.replace('/login');
                    } else {
                        console.error("Error removing activity", error);
                    }
                } finally {
                    // Stop loading
                    await this.promisedSetState({ loading: false });
                }
            }
        });
    }

    onKeyDownHandler = async e => {
        if (e.keyCode === 13) {
            await this.onRenderContent({ read: true })
        }
    };

    onClose = async (obj) => {
        const inputOptions = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    'completed': 'Completed <div>(จัดกิจกรรมเรียบร้อยแล้ว)</div>',
                    'cancel': 'Cancel <div>(ยกเลิกกิจกรรม)</div>',
                })
            }, 300)
        });

        Swal.fire({
            title: "ปิดกิจกรรม",
            input: 'radio',
            inputOptions: inputOptions,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            cancelButtonText: `<div style="font-size: 1.2rem">ยกเลิก</div>`,
            showLoaderOnConfirm: true,
            preConfirm: async (value) => {
                const headers = {
                    'Authorization': `Bearer ${this.state.token}`
                };

                try {
                    if (value === 'completed') {
                        // Handle 'completed' status
                        const response = await axios.put(`${process.env.REACT_APP_API}/activities/status`, {
                            id: obj.id,
                            status: 'completed',
                        }, { headers });

                        if (response.data.message === 'successfully') {
                            Swal.fire({
                                icon: "success",
                                title: "ปิดกิจกรรมเรียบร้อยแล้ว",
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: 1500
                            }).then(() => {
                                window.location.replace('/activities');
                            });
                        }
                    } else if (value === 'cancel') {
                        // Handle 'cancel' status
                        const reason = await Swal.fire({
                            title: "โปรดระบุสาเหตุการยกเลิก",
                            input: "text",
                            inputAttributes: {
                                autocapitalize: "off"
                            },
                            showCancelButton: false,
                            confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                            showLoaderOnConfirm: true,
                            preConfirm: async (message) => {
                                if (message === '') {
                                    return Swal.showValidationMessage(`กรุณากรอกข้อมูลให้ครบถ้วน !`);
                                } else {
                                    return message;
                                }
                            }
                        });

                        if (reason.value) {
                            const response = await axios.put(`${process.env.REACT_APP_API}/activities/status`, {
                                id: obj.id,
                                status: 'cancel',
                                status_reason: reason.value,
                            }, { headers });

                            if (response.data.message === 'successfully') {
                                Swal.fire({
                                    icon: "success",
                                    title: "ปิดกิจกรรมเรียบร้อยแล้ว",
                                    showConfirmButton: false,
                                    timer: 1500
                                }).then(() => {
                                    window.location.replace('/activities');
                                });
                            }
                        }
                    }
                } catch (error) {
                    // Handle authentication failure
                    if (error.response && error.response.data && error.response.data.message === 'AuthenticationFailed') {
                        window.location.replace('/login');
                    } else {
                        console.error("Error updating activity status", error);
                    }
                }
            }
        });
    }

    render() {

        const FullPageLoader = () => (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 9999 // Ensure it's on top of other content
            }}>
                <Loader content="Loading..." /> {/* Adjust loader component as needed */}
            </div>
        );

        const activeStyle = {
            textDecoration: 'underline',
            fontWeight: 'bold',
        };

        return (
            <>
                <Row className='mt-4'>
                    <Breadcrumb size='lg' separator={<AngleRightIcon />}>
                        <Breadcrumb.Item><Link to="/"><i className="bi bi-house-fill"></i></Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>ข้อมูลกิจกรรม</Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
    
                <ButtonToolbar>
                    <ButtonGroup>
                        <Button
                            onClick={async () => await this.promisedSetState({ activeTab: 1 })}
                            style={this.state.activeTab === 1 ? activeStyle : {}}
                            startIcon={<i className="bi bi-file-earmark-text"></i>}
                        >
                            รายการ
                        </Button>
                        <Button
                            onClick={async () => await this.promisedSetState({ activeTab: 2 })}
                            style={this.state.activeTab === 2 ? activeStyle : {}}
                            startIcon={<i className="bi bi-calendar3"></i>}
                        >
                            ปฏิทิน
                        </Button>
                        <Button
                            onClick={async () => await this.promisedSetState({ activeTab: 3 })}
                            style={this.state.activeTab === 3 ? activeStyle : {}}
                            startIcon={<i className="bi bi-clock-history"></i>}
                        >
                            ประวัติ
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
    
                {this.state.activeTab === 1 && (
                    <div>
                        <Form className='mt-2' onKeyDown={this.onKeyDownHandler} layout="inline">
                            <Form.Group>
                                <InputGroup styles={{ width: 300, marginBottom: 10 }} tabIndex={-1}>
                                    <InputGroup.Addon>
                                        <i className="bi bi-search"></i>
                                    </InputGroup.Addon>
                                    <AutoComplete
                                        style={{ width: 300 }}
                                        data={this.state.searchDataset.map(item => ({ label: item.label, value: item.value }))}
                                        onChange={async e => await this.promisedSetState({ searchQuery: e.replace(/&amp;/g, "&").replace(/&gt;/g, ">").replace(/&lt;/g, "<").replace(/&quot;/g, '"') })} />
                                </InputGroup>
                            </Form.Group>
    
                            <Button
                                color="blue"
                                appearance="primary"
                                onClick={async () => await this.onRenderContent({ read: true })}>
                                ค้นหา
                            </Button>
                        </Form>
    
                        <Row>
                            <Col sm>
                                <Link to="/activities/create">
                                    <Button color="green" appearance="primary" size='sm' startIcon={<i className="bi bi-plus-lg"></i>}>
                                        สร้างกิจกรรม
                                    </Button>
                                </Link>
                            </Col>
                            <Col sm className='text-end'>
                                <Button size='sm' startIcon={<i className="bi bi-circle-fill text-blue"></i>}>ค่าย</Button>
                                <Button size='sm' startIcon={<i className="bi bi-circle-fill text-pink"></i>}>Family trip/Private trip</Button>
                                <Button size='sm' startIcon={<i className="bi bi-circle-fill text-orange"></i>}>Workshop/กิจกรรมระยะสั้น</Button>
                            </Col>
                        </Row>
                        {this.state.activities}
                        {this.state.pagination}
                        {this.state.modal}
                        <br />
                    </div>
                )}
    
                {this.state.activeTab === 2 && <CalendarPage />}
                {this.state.activeTab === 3 && <HistoryPage />}
    
                {/* Full-Page Loader */}
                {this.state.loading && <FullPageLoader />}
            </>
        );
    }
}