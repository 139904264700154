import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import axios
import axios from 'axios';

// import Rsuite
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import {
    Table
    , Pagination
    , IconButton
    , ButtonToolbar
    , Button
    , Stack
    , Toggle
    , Loader
} from 'rsuite';

// import Bootstrap
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import Sweetalert2
import Swal from 'sweetalert2'

export default class Application extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem('token')).token,
            content: [],
            sortColumn: '',
            sortType: '',
            tableLoading: false,
            limit: 10,
            page: 1,
            data: [],
            count: 0,
            loading: false,
        };
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {
        this.onRenderContent({ read: true })
    }

    onRenderContent = async (obj) => {
        if (obj.read === true) {
            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            const handleAuthFailure = (error) => {
                if (error.response && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: error.message,
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                }
            };

            try {

                await this.promisedSetState({ loading: true }); // Start loading

                // Fetch data with authentication header
                const response = await axios.get(`${process.env.REACT_APP_API}/forms`, authHeader);

                // Update state with data
                await this.promisedSetState({
                    data: response.data.map((item, index) => ({
                        index: index + 1,
                        id: item.id,
                        status: <Toggle
                            checked={item.status === 1}
                            checkedChildren="ใช้งาน"
                            unCheckedChildren="ไม่ใช้งาน"
                            onChange={checked => this.onStatus({ id: item.id, status: checked ? 1 : 0 })}
                        />,
                        name: item.name
                    }))
                });

                const { Column, HeaderCell, Cell } = Table;

                // Update state with content including table and buttons
                await this.promisedSetState({
                    content: (
                        <>
                            <Row className='mt-4'>
                                <Breadcrumb size='lg' separator={<AngleRightIcon />}>
                                    <Breadcrumb.Item><Link to="/"><i class="bi bi-house-fill"></i></Link></Breadcrumb.Item>
                                    <Breadcrumb.Item active>แบบฟอร์มใบสมัคร</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>

                            <Row className='mt-2'>
                                <Col sm>
                                    <Stack spacing={6}>
                                        <Link to="/application/manage/create">
                                            <Button size='sm'
                                                color="green"
                                                appearance="primary"
                                                startIcon={<i class="bi bi-plus-lg"></i>}>
                                                สร้างใบสมัคร
                                            </Button>
                                        </Link>
                                        <Link to="/application/questions">
                                            <Button size='sm'
                                                appearance="default"
                                                startIcon={<i class="bi bi-gear-fill"></i>}>
                                                ตั้งค่าคำถาม-ตอบ
                                            </Button>
                                        </Link>
                                    </Stack>
                                </Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm>
                                    <Table
                                        autoHeight
                                        data={this.getData()}
                                        bordered
                                        cellBordered
                                        sortColumn={this.state.sortColumn}
                                        sortType={this.state.sortType}
                                        onSortColumn={this.handleSortColumn}
                                        loading={this.state.tableLoading}
                                        className='rounded shadow-sm'
                                    >
                                        <Column width={60} align="center" sortable>
                                            <HeaderCell>ลำดับ</HeaderCell>
                                            <Cell dataKey="index" />
                                        </Column>

                                        <Column align='center' width={120} sortable>
                                            <HeaderCell>สถานะ</HeaderCell>
                                            <Cell align='center' dataKey="status" />
                                        </Column>

                                        <Column width={1025} sortable>
                                            <HeaderCell>ชื่อแบบฟอร์มใบสมัคร</HeaderCell>
                                            <Cell dataKey="name" />
                                        </Column>

                                        <Column align='center' width={90} fixed="right">
                                            <HeaderCell>...</HeaderCell>

                                            <Cell align='center' style={{ padding: '6px' }}>
                                                {rowData => (
                                                    <ButtonToolbar>
                                                        <Link to={`/application/manage/${rowData.id}`} >
                                                            <IconButton
                                                                size='sm'
                                                                color="yellow"
                                                                appearance="primary"
                                                                icon={<i class="bi bi-pencil-square"></i>} />
                                                        </Link>
                                                        <IconButton
                                                            size='sm'
                                                            color="red"
                                                            appearance="primary"
                                                            icon={<i class="bi bi-trash3-fill"></i>}
                                                            onClick={() => this.onRemove({ method: 'delete', id: rowData.id })} />
                                                    </ButtonToolbar>
                                                )}
                                            </Cell>
                                        </Column>
                                    </Table>
                                    <div style={{ padding: 20 }}>
                                        <Pagination
                                            prev
                                            next
                                            first
                                            last
                                            ellipsis
                                            boundaryLinks
                                            maxButtons={5}
                                            size="xs"
                                            layout={['total', '|', 'pager']}
                                            total={this.state.data.length}
                                            limit={this.state.limit}
                                            activePage={this.state.page}
                                            onChangePage={(e) => this.setState({ page: e })}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )
                });

                await this.promisedSetState({ loading: false }); // Stop loading
                
            } catch (error) {
                handleAuthFailure(error);
            }
        }
    }

    onStatus = async (obj) => {
        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        const handleAuthFailure = (error) => {
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        };

        try {
            // Perform the update with authentication header
            await axios.put(`${process.env.REACT_APP_API}/forms/status`, {
                id: obj.id,
                status: obj.status,
            }, authHeader);

            // Refresh the content after successful update
            await this.onRenderContent({ read: true });
        } catch (error) {
            // Handle any errors that occur during the request
            handleAuthFailure(error);
        }
    }

    onRemove = async (obj) => {
        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        const handleAuthFailure = (error) => {
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        };

        if (obj.method === 'delete') {
            try {
                // Check if the form can be deleted
                const countResponse = await axios.get(`${process.env.REACT_APP_API}/forms/count/${obj.id}`, authHeader);
                if (countResponse.data.count > 0) {
                    Swal.fire({
                        icon: "error",
                        title: "ไม่สามารถลบ! เนื่องจากใบสมัครอยู่ระหว่างการใช้งาน",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                } else if (countResponse.data.count === 0) {
                    // Confirm deletion
                    const result = await Swal.fire({
                        title: "คุณต้องการลบใบสมัครใช่หรือไม่?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: `<div style="font-size: 1.2rem">ยืนยัน</div>`,
                        cancelButtonText: `<div style="font-size: 1.2rem">ยกเลิก</div>`
                    });

                    if (result.isConfirmed) {
                        // Proceed with deletion
                        const deleteResponse = await axios.delete(`${process.env.REACT_APP_API}/forms/${obj.id}`, authHeader);
                        if (deleteResponse.data.message === 'successfully') {
                            Swal.fire({
                                title: "ลบใบสมัครเรียบร้อยแล้ว!",
                                icon: "success",
                                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                            }).then(() => {
                                this.onRenderContent({ read: true });
                            });
                        }
                    }
                }
            } catch (error) {
                handleAuthFailure(error);
            }
        }
    }

    handleSortColumn = async (sortColumn, sortType) => {
        await this.promisedSetState({ tableLoading: true });

        await new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });

        await this.promisedSetState({ tableLoading: false });
        await this.promisedSetState({ sortColumn, sortType });

        // Call your onRenderContent function if needed
        await this.onRenderContent({ read: true });
    };

    getData = () => {
        if (this.state.sortColumn && this.state.sortType) {
            return this.state.data.sort((a, b) => {
                let x = a[this.state.sortColumn];
                let y = b[this.state.sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (this.state.sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }

        return this.state.data.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });
    };

    render() {
        return (
            <>
                {this.state.content}
    
                {/* Full-Page Loader */}
                {this.state.loading && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999 // Ensure it’s on top of other content
                    }}>
                        <Loader content="Loading..." vertical />
                    </div>
                )}
            </>
        );
    }    
}