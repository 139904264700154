import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import axios
import axios from 'axios';

// import $
import $ from 'jquery';

// import Sweetalert2
import Swal from 'sweetalert2'

// import Rsuite
import {
    Table
    , Pagination
    , IconButton
    , ButtonToolbar
    , Button
    , SelectPicker
    , Stack
    , Toggle
    , Modal
    , Form
    , InlineEdit
    , Checkbox
    , Loader
} from 'rsuite';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';

// ReactSortable
import { ReactSortable } from "react-sortablejs";

// import Bootstrap
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class Questions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem('token')).token,
            username: JSON.parse(localStorage.getItem('token')).username,
            modal: [],
            tables: [],
            categoryOptions: [],
            typeOptions: [],
            data: [],
            sortColumn: '',
            sortType: '',
            tableLoading: false,
            limit: 15,
            page: 1,
            question: '',
            type: null,
            options: [],
            required: true,
            status: true,
        };

    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {
        this.onRenderContent({ read: true })
    }

    onRenderContent = async (obj) => {
        if (obj.read === true) {

            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            const handleAuthFailure = (error) => {
                if (error.response && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: error.message,
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                }
            };

            try {

                await this.promisedSetState({ loading: true, modal: [] }); // Start loading

                await axios.get(`${process.env.REACT_APP_API}/elements`, authHeader)
                    .then(response => this.setState({
                        typeOptions: response.data.map((item) => ({
                            label: <div><i className={item.symbol}></i>{' '}<span>{item.name}</span></div>,
                            value: item.key
                        }))
                    }))

                // Fetch questions list
                const questionsResponse = await axios.get(`${process.env.REACT_APP_API}/questions/list`, authHeader);
                const questionsData = questionsResponse.data;

                // Update state with fetched data
                await this.promisedSetState({
                    data: questionsData.map((item, index) => ({
                        index: index + 1,
                        id: item.id,
                        status: (
                            <Toggle
                                checked={item.status === 1}
                                checkedChildren="ใช้งาน"
                                unCheckedChildren="ไม่ใช้งาน"
                                onChange={checked => this.onStatus({ id: item.id, status: checked ? 1 : 0 })}
                            />
                        ),
                        question: item.questions,
                        type: (
                            <div>
                                <i className={item.symbol}></i>{' '}
                                <span>{item.elements}</span>
                            </div>
                        )
                    }))
                });

                // Render table
                const { Column, HeaderCell, Cell } = Table;

                const data = await this.getData()
                await this.promisedSetState({
                    tables: (
                        <Row className='mt-3'>
                            <Col sm>
                                <Table
                                    autoHeight
                                    data={data}
                                    bordered
                                    cellBordered
                                    sortColumn={this.state.sortColumn}
                                    sortType={this.state.sortType}
                                    onSortColumn={this.handleSortColumn}
                                    loading={this.state.tableLoading}
                                    className='rounded shadow-sm'
                                >
                                    <Column width={60} align="center" sortable>
                                        <HeaderCell>ลำดับ</HeaderCell>
                                        <Cell dataKey="index" />
                                    </Column>

                                    <Column width={120} sortable>
                                        <HeaderCell>สถานะ</HeaderCell>
                                        <Cell dataKey="status" />
                                    </Column>

                                    <Column width={685} sortable>
                                        <HeaderCell>คำถาม</HeaderCell>
                                        <Cell dataKey="question" />
                                    </Column>

                                    <Column width={380} sortable>
                                        <HeaderCell>ประเภทคำตอบ</HeaderCell>
                                        <Cell dataKey="type" />
                                    </Column>

                                    <Column align='center' width={50} fixed="right">
                                        <HeaderCell>...</HeaderCell>
                                        <Cell align='center' style={{ padding: '6px' }}>
                                            {rowData => (
                                                <ButtonToolbar>
                                                    <IconButton
                                                        onClick={async () => {
                                                            try {
                                                                const questionDetails = await axios.get(`${process.env.REACT_APP_API}/questions/${rowData.id}`, authHeader);
                                                                const options = questionDetails.data.map(items => JSON.parse(items.options))[0];
                                                                this.onModal({ method: 'update', options, id: rowData.id });
                                                            } catch (error) {
                                                                handleAuthFailure(error);
                                                            }
                                                        }}
                                                        size='sm'
                                                        color="yellow"
                                                        appearance="primary"
                                                        icon={<i class="bi bi-pencil-square"></i>}
                                                    />
                                                </ButtonToolbar>
                                            )}
                                        </Cell>
                                    </Column>
                                </Table>
                                <div style={{ padding: 20 }}>
                                    <Pagination
                                        prev
                                        next
                                        first
                                        last
                                        ellipsis
                                        boundaryLinks
                                        maxButtons={5}
                                        size="xs"
                                        layout={['total', '|', 'pager']}
                                        total={this.state.data.length}
                                        limit={this.state.limit}
                                        activePage={this.state.page}
                                        onChangePage={async (page) => {
                                            try {
                                                await this.promisedSetState({ page });
                                                await this.onRenderContent({ read: true });
                                            } catch (error) {
                                                handleAuthFailure(error);
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )
                });

                await this.promisedSetState({ loading: false }); // Stop loading

            } catch (error) {
                handleAuthFailure(error);
            }
        }
    };


    onStatus = async (obj) => {

        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        const handleAuthFailure = (error) => {
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        };

        try {
            // Update question status
            const response = await axios.put(`${process.env.REACT_APP_API}/questions/status`, {
                id: obj.id,
                status: obj.status,
            }, authHeader);

            if (response.data.message === 'successfully') {
                // Refresh the content if the status update is successful
                await this.onRenderContent({ read: true });
            } else {
                // Handle unexpected responses
                Swal.fire({
                    title: "อัพเดตสถานะล้มเหลว",
                    text: "ไม่สามารถอัพเดตสถานะได้ กรุณาลองอีกครั้ง",
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        } catch (error) {
            // Handle errors during the API request
            handleAuthFailure(error);
        }
    };

    handleSortColumn = async (sortColumn, sortType) => {
        await this.promisedSetState({ tableLoading: true });

        await new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 500);
        });

        await this.promisedSetState({ tableLoading: false });
        await this.promisedSetState({ sortColumn, sortType });

        // Call your onRenderContent function if needed
        await this.onRenderContent({ read: true });
    };

    getData = async () => {
        if (this.state.sortColumn && this.state.sortType) {
            return this.state.data.sort((a, b) => {
                let x = a[this.state.sortColumn];
                let y = b[this.state.sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (this.state.sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }

        return this.state.data.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });
    };

    onChoice = (obj) => {
        this.data = []
        if (obj.choice === true) {
            this.data = <Button
                className='mt-1'
                appearance="subtle"
                startIcon={<i class="bi bi-plus-lg"></i>}
                onClick={() => this.onModal({ method: obj.method, options: obj.options, newOption: true, choice: true, id: obj.id })}>
                เพิ่มตัวเลือก
            </Button>
        }

        return this.data
    }

    onModal = async (obj) => {
        if (obj.method === 'insert') {
            await obj.newOption === true && (obj.options.push({ name: "กรุณาระบุตัวเลือก", more: 0, remove: 1 }))
            await obj.removeOption === true && (obj.options.splice(obj.indexOption, 1))
            await this.promisedSetState({
                options: obj.options,
                modal: <Modal size='md' backdrop={'static'} open={true} onClose={() => this.setState({ modal: [] })}>
                    <Modal.Header>
                        <Modal.Title>คำถาม - ตอบ</Modal.Title>
                    </Modal.Header>
                    <hr />
                    <Modal.Body>
                        <Container>
                            <Form fluid>
                                <Row className='mt-3'>
                                    <Col sm>
                                        <Form.Group>
                                            <Form.ControlLabel><i className="bi bi-question-circle-fill"></i>{' '}<b>คำถาม</b></Form.ControlLabel>
                                            <Form.Control
                                                className='4H2Bd'
                                                size='md'
                                                onChange={(e) => this.setState({ question: e })} />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm>
                                        <Form.Group>
                                            <Form.ControlLabel><i class="bi bi-chat-quote-fill"></i>{' '}<b>คำตอบ</b></Form.ControlLabel>
                                            <SelectPicker
                                                className='61YSy'
                                                size='lg'
                                                placeholder='กรุณาเลือกประเภทคำตอบ'
                                                data={this.state.typeOptions}
                                                style={{ width: 300 }}
                                                onChange={async (e) => {
                                                    await e === 'multiple_choice' || e === 'checkboxes' || e === 'dropdown' ? (this.onModal({ method: 'insert', options: this.state.options, choice: true })) : (this.onModal({ method: 'insert', options: [], choice: false }))
                                                    this.setState({ type: e })
                                                }}
                                                onClean={() => this.onModal({ method: obj.method, options: [], choice: false })} />
                                            <ReactSortable
                                                swap
                                                list={obj.options}
                                                setList={(newState) => this.onModal({ method: obj.method, options: newState, choice: obj.choice })}
                                            >
                                                {obj.options.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div key={item.name}>
                                                                <Stack className='mt-2 mb-2' spacing={6}>
                                                                    <i class="bi bi-grip-vertical"></i>
                                                                    <InlineEdit
                                                                        defaultValue={item.name}
                                                                        onChange={(e) => item.name = e} />
                                                                    <Checkbox
                                                                        onChange={(str, e) => item.more = e ? 1 : 0}>
                                                                        ระบุเพิ่มเติม
                                                                    </Checkbox>
                                                                    {item.remove === 1 && (<IconButton
                                                                        size='sm'
                                                                        circle
                                                                        icon={<i class="bi bi-trash3"></i>}
                                                                        onClick={() => this.onModal({ method: obj.method, options: obj.options, removeOption: true, indexOption: index, choice: obj.choice })} />)}
                                                                </Stack>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </ReactSortable>
                                            {this.onChoice({ method: obj.method, options: obj.options, choice: obj.choice })}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm>
                                        <Form.Group>
                                            <Checkbox
                                                onChange={(str, e) => this.setState({ required: e })}
                                                defaultChecked
                                            ><i>จำเป็นต้องกรอก (required)</i></Checkbox>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col sm>
                                        <Form.Group>
                                            <Form.ControlLabel><i class="bi bi-toggles2"></i>{' '}<b>สถานะ</b></Form.ControlLabel>
                                            <Toggle
                                                className='ms-2'
                                                checkedChildren="ใช้งาน"
                                                unCheckedChildren="ไม่ใช้งาน"
                                                defaultChecked
                                                onChange={(e) => this.setState({ status: e })} />
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Form>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer className='me-4 p-2'>
                        <Stack className='d-flex justify-content-end'
                            wrap
                            spacing={12}>
                            <Button
                                color="blue"
                                appearance="primary"
                                startIcon={<i class="bi bi-floppy2-fill"></i>}
                                className='ps-2 pe-2'
                                onClick={() => this.onConfirm({ method: 'insert' })} >
                                บันทึก
                            </Button>
                            <Button
                                color="red"
                                appearance="subtle"
                                startIcon={<i class="bi bi-x-square"></i>}
                                className='ps-2 pe-2'
                                onClick={() => this.setState({ modal: [] })}>
                                ยกเลิก
                            </Button>
                        </Stack>
                    </Modal.Footer>
                </Modal>
            })

        } else if (obj.method === 'update') {
            await obj.newOption === true && (obj.options.push({ name: "กรุณาระบุตัวเลือก", more: 0, remove: 1 }))
            await obj.removeOption === true && (obj.options.splice(obj.indexOption, 1))

            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            const handleAuthFailure = (error) => {
                if (error.response && error.response.data.message === 'AuthenticationFailed') {
                    window.location.replace('/login');
                } else {
                    Swal.fire({
                        title: "เกิดข้อผิดพลาด",
                        text: error.message,
                        icon: "error",
                        confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                    });
                }
            };

            try {

                await axios.get(`${process.env.REACT_APP_API}/questions/${obj.id}`, authHeader)
                    .then(response => {
                        response.data.forEach(questions => {
                            this.promisedSetState({
                                question: questions.questions,
                                type: questions.type,
                                required: questions.required,
                                status: questions.status,
                                options: obj.options,
                                modal:
                                    <Modal size='md' backdrop={'static'} open={true} onClose={() => this.setState({ modal: [] })}>
                                        <Modal.Header>
                                            <Modal.Title>คำถาม - ตอบ</Modal.Title>
                                        </Modal.Header>
                                        <hr />
                                        <Modal.Body>
                                            <Container>
                                                <Form fluid>
                                                    <Row className='mt-3'>
                                                        <Col sm>
                                                            <Form.Group>
                                                                <Form.ControlLabel>
                                                                    <i className="bi bi-question-circle-fill"></i>{' '}
                                                                    <b>คำถาม</b>
                                                                </Form.ControlLabel>
                                                                <Form.Control
                                                                    className='4H2Bd'
                                                                    readOnly
                                                                    size='md'
                                                                    value={questions.questions}
                                                                    onChange={(e) => this.setState({ question: e })}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row className='mt-3'>
                                                        <Col sm>
                                                            <Form.Group>
                                                                <Form.ControlLabel>
                                                                    <i className="bi bi-chat-quote-fill"></i>{' '}
                                                                    <b>คำตอบ</b>
                                                                </Form.ControlLabel>
                                                                <SelectPicker
                                                                    className='61YSy'
                                                                    size='lg'
                                                                    placeholder='กรุณาเลือกประเภทคำตอบ'
                                                                    data={this.state.typeOptions}
                                                                    defaultValue={questions.type}
                                                                    style={{ width: 300 }}
                                                                    onChange={async (e) => {
                                                                        await (e === 'multiple_choice' || e === 'checkboxes' || e === 'dropdown')
                                                                            ? this.onModal({ method: 'insert', options: this.state.options, choice: true, id: obj.id })
                                                                            : this.onModal({ method: 'insert', options: [], choice: false, id: obj.id });
                                                                        this.setState({ type: e });
                                                                    }}
                                                                    onClean={() => this.onModal({ method: obj.method, options: [], choice: false, id: obj.id })}
                                                                />
                                                                <ReactSortable
                                                                    swap
                                                                    list={obj.options}
                                                                    setList={(newState) => this.onModal({ method: obj.method, options: newState, id: obj.id })}>
                                                                    {obj.options.map((item, index) => (
                                                                        <div key={item.name}>
                                                                            <Stack className='mt-2 mb-2' spacing={6}>
                                                                                <i className="bi bi-grip-vertical"></i>
                                                                                <InlineEdit
                                                                                    defaultValue={item.name}
                                                                                    onChange={(e) => item.name = e} />
                                                                                <Checkbox
                                                                                    checked={item.more === 1}
                                                                                    onChange={(str, e) => item.more = e ? 1 : 0}>
                                                                                    ระบุเพิ่มเติม
                                                                                </Checkbox>
                                                                                {item.remove === 1 && (
                                                                                    <IconButton
                                                                                        size='sm'
                                                                                        circle
                                                                                        icon={<i className="bi bi-trash3"></i>}
                                                                                        onClick={() => this.onModal({ method: obj.method, options: obj.options, removeOption: true, indexOption: index, id: obj.id })} />
                                                                                )}
                                                                            </Stack>
                                                                        </div>
                                                                    ))}
                                                                </ReactSortable>
                                                                {['multiple_choice', 'checkboxes', 'dropdown'].includes(questions.type)
                                                                    ? this.onChoice({ method: obj.method, options: obj.options, choice: true, id: obj.id })
                                                                    : this.onChoice({ method: obj.method, options: obj.options, choice: obj.choice, id: obj.id })}
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row className='mt-3'>
                                                        <Col sm>
                                                            <Form.Group>
                                                                <Checkbox
                                                                    defaultChecked={questions.required === 1}
                                                                    onChange={(str, e) => this.setState({ required: e })}>
                                                                    <i>จำเป็นต้องกรอก (required)</i>
                                                                </Checkbox>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row className='mt-3'>
                                                        <Col sm>
                                                            <Form.Group>
                                                                <Form.ControlLabel>
                                                                    <i className="bi bi-toggles2"></i>{' '}
                                                                    <b>สถานะ</b>
                                                                </Form.ControlLabel>
                                                                <Toggle
                                                                    className='ms-2'
                                                                    checkedChildren="ใช้งาน"
                                                                    unCheckedChildren="ไม่ใช้งาน"
                                                                    defaultChecked={questions.status === 1}
                                                                    onChange={(e) => this.promisedSetState({ status: e })} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Container>
                                        </Modal.Body>
                                        <Modal.Footer className='me-4 p-2'>
                                            <Stack className='d-flex justify-content-end' wrap spacing={12}>
                                                <Button
                                                    color="blue"
                                                    appearance="primary"
                                                    startIcon={<i className="bi bi-floppy2-fill"></i>}
                                                    className='ps-2 pe-2'
                                                    onClick={() => this.onConfirm({ method: 'update', id: questions.id })}>
                                                    บันทึก
                                                </Button>
                                                <Button
                                                    color="red"
                                                    appearance="subtle"
                                                    startIcon={<i className="bi bi-x-square"></i>}
                                                    className='ps-2 pe-2'
                                                    onClick={() => this.setState({ modal: [] })}>
                                                    ยกเลิก
                                                </Button>
                                            </Stack>
                                        </Modal.Footer>
                                    </Modal>
                            });
                        });
                    });

            } catch (error) {
                handleAuthFailure(error);
            }
        }
    }

    Validate = (params, clss, cond) => {
        if (params === cond) {
            $('.' + clss).addClass("required")
            Swal.fire({
                title: "กรุณากรอกข้อมูลให้ครบถ้วน",
                icon: "warning",
                timer: 2000,
                timerProgressBar: true,
                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            });
            return false
        } else if (params !== cond) {
            $('.' + clss).removeClass("required")
            return true
        }
    }

    onConfirm = async (obj) => {

        const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

        const handleAuthFailure = (error) => {
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        };

        try {
            // Validation
            const isValid = this.Validate(this.state.question, '4H2Bd', '') &&
                this.Validate(this.state.type, '61YSy', null);

            if (!isValid) {
                return; // Exit if validation fails
            }

            // Prepare request data
            const requestData = {
                questions: this.state.question,
                type: this.state.type,
                options: this.state.options.map(item => ({
                    id: item.id,
                    name: item.name,
                    more: item.more
                })),
                required: this.state.required,
                status: this.state.status,
                record_status: 1,
                username: this.state.username
            };

            // Determine API endpoint and method
            const apiEndpoint = obj.method === 'insert'
                ? `${process.env.REACT_APP_API}/questions`
                : `${process.env.REACT_APP_API}/questions`;
            const apiMethod = obj.method === 'insert' ? 'post' : 'put';

            if (obj.method === 'update') {
                requestData.id = obj.id; // Add ID for update
            }

            // Make API request
            const response = await axios[apiMethod](apiEndpoint, requestData, authHeader);

            if (response.data.message === 'successfully') {
                await this.onRenderContent({ read: true });

                Swal.fire({
                    title: obj.method === 'insert'
                        ? "คุณสร้างคำถามเรียบร้อยแล้ว"
                        : "คุณแก้ไขคำถามเรียบร้อยแล้ว",
                    icon: "success",
                    timer: 2000,
                    timerProgressBar: true,
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                }).then(async () => {
                    await this.promisedSetState({ modal: [] });
                });
            } else {
                throw new Error('Operation failed'); // Handle unexpected responses
            }
        } catch (error) {
            console.error(error);
            handleAuthFailure(error); // Handle authentication failure or other errors
        }
    };

    render() {
        return (
            <>
                <Row className='mt-4'>
                    <Breadcrumb size='lg' separator={<AngleRightIcon />} >
                        <Breadcrumb.Item>
                            <Link to="/"><i className="bi bi-house-fill"></i></Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/application">แบบฟอร์มใบสมัคร</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>ตั้งค่าคำถาม - ตอบ</Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
    
                <Row className='mt-3'>
                    <Col sm>
                        <Button size='sm'
                            color="green"
                            appearance="primary"
                            startIcon={<i className="bi bi-plus-lg"></i>}
                            onClick={() => this.onModal({ method: 'insert', options: [] })}>
                            สร้างคำถาม
                        </Button>
                    </Col>
                </Row>
    
                {this.state.tables}
                {this.state.modal}
    
                {/* Full-Page Loader */}
                {this.state.loading && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999 // Ensure it’s on top of other content
                    }}>
                        <Loader content="Loading..." vertical />
                    </div>
                )}
            </>
        );
    }    
}