import React, { Component } from 'react';
import withRouter from './withRouter';
import axios from 'axios';
import $ from 'jquery';
import md5 from 'md5'
import Swal from 'sweetalert2'

// import Bootstrap
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

// import Rsuite
import {
    Form
    , Stack
    , Radio
    , RadioGroup
    , Input
    , InputNumber
    , Checkbox
    , CheckboxGroup
    , SelectPicker
    , DatePicker
    , MaskedInput
    , Text
    , IconButton
    , Button
    , Loader
} from 'rsuite';

// import Map
import Thailand from '../json/thailand.json'

class Forms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Forms: [],
            Result: [],
            step: 0,
            amphur: [],
            tambon: [],
            multichoiceOptions: [],
            checkboxesOptions: []
        }
    }

    async componentDidMount() {
        try {
            await this.onRenderContent({ mode: 'insert' });
        } catch (error) {
            console.error('Error in componentDidMount:', error);
            // Handle or report the error as needed
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        // Example comparison - adjust based on actual state logic
        if (prevState.Forms === this.state.Forms) {
            try {
                await this.onRenderContent({ mode: 'insert' });
            } catch (error) {
                console.error('Error in componentDidUpdate:', error);
                // Handle or report the error as needed
            }
        }
    }

    // Promised setState method for handling asynchronous state updates
    promisedSetState = (newState) => new Promise((resolve, reject) => {
        this.setState(newState, (error) => {
            if (error) {
                reject(error);
            } else {
                resolve();
            }
        });
    });

    onRenderContent = async (obj) => {

        if (obj.mode === 'insert') {

            await axios.get(`${process.env.REACT_APP_API}/activities/path/${this.props.params.id}`).then(async (response) =>
                await response.data.map(async (activity) =>
                    await axios.get(`${process.env.REACT_APP_API}/forms/${activity.forms_id}`).then(response => response.data.map(async (data) => {

                        const datePeriod = await this.onDate({ start: activity.date_period.split(',')[0], end: activity.date_period.split(',')[1] })

                        if (activity.status === 'active') {
                            await this.promisedSetState({
                                Forms: <Container className='p-3' style={{ position: 'relative' }}>
                                    <Row className='mt-3' >
                                        <Col sm>
                                            <Image
                                                className='rounded'
                                                style={{ width: '100%', height: '100%' }}
                                                src={`${process.env.REACT_APP_UPLOAD}/${activity.image_path}`}
                                                fluid />
                                        </Col>
                                    </Row>
                                    {JSON.parse(data.forms_json)
                                        .filter(res => res.status === true)
                                        .map((form, categoryIndex) => {
                                            if (this.state.step === categoryIndex) {
                                                return (
                                                    <>
                                                        {categoryIndex === 0 && (
                                                            <Row className='mt-3'>
                                                                <Col sm>
                                                                    <div className='d-flex justify-content-center mt-3 mb-3'>
                                                                        <span className='header-forms'><b>{activity.name}</b></span>
                                                                    </div>
                                                                    <div>
                                                                        <label>
                                                                            <IconButton circle icon={<i className="bi bi-chat-quote"></i>} />{' '}<b style={{ color: '#606060' }}>รายละเอียด</b>
                                                                        </label>
                                                                    </div>
                                                                    <pre>{activity.details}</pre>
                                                                    <div>
                                                                        <IconButton circle icon={<i className="bi bi-calendar3"></i>} />{' '}<b style={{ color: '#606060' }}>{datePeriod}</b>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                        <Form fluid>
                                                            <Row className='mt-3'>
                                                                <Col sm>
                                                                    <div className='header-category' style={{ width: '100%' }}>
                                                                        <b>{form.category}</b>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            {form.questions.map((question, questionIndex) => (
                                                                <Row className='mt-3' key={question.id}> {/* Add a key for each question */}
                                                                    <Col sm>
                                                                        {this.onQuestions({
                                                                            category: categoryIndex,
                                                                            index: questionIndex,
                                                                            class: md5(`${categoryIndex}MERGE${questionIndex}`),
                                                                            label: question.label,
                                                                            id: question.id,
                                                                            default: question.default,
                                                                            type: question.type,
                                                                            required: question.required,
                                                                            status: question.status,
                                                                            opt: JSON.parse(question.options),
                                                                            value: obj.value ? obj.value : null,
                                                                        })}
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                        </Form>

                                                        <Stack className='d-flex justify-content-center mt-5 mb-5' wrap spacing={60}>
                                                            <Button
                                                                appearance="subtle"
                                                                className='ps-3 pe-3'
                                                                startIcon={<i className="bi bi-chevron-left"></i>}
                                                                onClick={() => this.onPrevious()}
                                                                disabled={this.state.step === 0}
                                                            >
                                                                ย้อนกลับ
                                                            </Button>
                                                            {this.state.step === (parseInt(JSON.parse(data.forms_json).filter(res => res.status === true).length) - 1) ? (
                                                                <Button
                                                                    color="green"
                                                                    appearance="primary"
                                                                    className='ps-4 pe-4'
                                                                    endIcon={<i className="bi bi-send-fill"></i>}
                                                                    onClick={() => this.onConfirm({ method: 'insert', category: categoryIndex, activity_id: activity.id, forms_id: activity.forms_id })}>
                                                                    ส่งข้อมูล
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    color="blue"
                                                                    appearance="primary"
                                                                    className='ps-4 pe-4'
                                                                    endIcon={<i className="bi bi-chevron-right"></i>}
                                                                    onClick={() => this.onNext({ category: categoryIndex, activity_id: activity.id, forms_id: activity.forms_id })}>
                                                                    ถัดไป
                                                                </Button>
                                                            )}
                                                        </Stack>
                                                    </>
                                                );
                                            }
                                            return null; // Return null if the condition is not met
                                        })}

                                </Container>
                            })

                        } else {

                            await this.promisedSetState({
                                Forms: <Container className='p-3' style={{ position: 'relative' }}>
                                    <Row className='mt-3' >
                                        <Col sm>
                                            <Image
                                                className='rounded'
                                                style={{ width: '100%', height: '100%' }}
                                                src={`${process.env.REACT_APP_UPLOAD}/${activity.image_path}`}
                                                fluid />
                                        </Col>
                                    </Row>
                                    <Row className='mt-3' >
                                        <Col sm>
                                            <div className='d-flex justify-content-center mt-3 mb-3'>
                                                <Stack
                                                    spacing={6}
                                                    direction='column'
                                                >
                                                    <div><pre><Text color="blue" size="xl" weight="bold">"ขอบคุณที่สนใจสมัครกิจกรรมของ Doi Tung Experience"</Text></pre></div>
                                                    <div><pre>กรุณาติดต่อเจ้าหน้าที่ของโครงการ โทร.081-817-9944: เพชร</pre></div>
                                                </Stack>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            })

                        }
                    }))
                ))
        } else if (obj.mode === 'successfully') {

            await axios.get(`${process.env.REACT_APP_API}/activities/path/${this.props.params.id}`).then(async (response) =>
                await response.data.map(async (activity) => {
                    await this.promisedSetState({
                        Forms: <Container className='p-3' style={{ position: 'relative' }}>
                            <Row className='mt-3' >
                                <Col sm>
                                    <Image
                                        className='rounded'
                                        style={{ width: '100%', height: '100%' }}
                                        src={`${process.env.REACT_APP_UPLOAD}/${activity.image_path}`}
                                        fluid />
                                </Col>
                            </Row>
                            <Row className='mt-3' >
                                <Col sm>
                                    <div className='d-flex justify-content-center mt-3 mb-3'>
                                        <Stack
                                            spacing={6}
                                            direction='column'
                                        >
                                            <div><pre><Text color="blue" size="xl" weight="bold">"ขอบคุณที่สนใจสมัครกิจกรรมของ Doi Tung Experience"</Text></pre></div>
                                            <div><pre>จะมีอีเมลแจ้งรายละเอียดการจ่ายเงินส่งให้ตามอีเมลที่ท่านกรอกรายละเอียดไว้ภายในไม่เกิน 7 วัน หากท่านไม่ได้รับอีเมลจากทางเรา</pre></div>
                                            <div><pre>สามารถติดต่อเจ้าหน้าที่ของโครงการได้ที่ โทร.081-817-9944 : เพชร</pre></div>
                                        </Stack>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    })
                }))

        }
    }

    toInterger = (value) => {
        return `${(value * 1).toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')}`
    }

    onDate = (obj) => {
        let dateStart = new Date(`${obj.start.split(' ')[1]} ${obj.start.split(' ')[2]}, ${obj.start.split(' ')[3]} ${obj.start.split(' ')[4]}`)
        let dateEnd = new Date(`${obj.end.split(' ')[1]} ${obj.end.split(' ')[2]}, ${obj.end.split(' ')[3]} ${obj.end.split(' ')[4]}`)

        if (dateStart.getFullYear() === dateEnd.getFullYear()) {

            if (dateStart.getMonth() === dateEnd.getMonth()) {

                if (dateStart.getDate() === dateEnd.getDate()) {

                    return `วันที่ ${dateStart.getDate()} ${this.onMonth(dateStart.getMonth() + 1)} ${dateStart.getFullYear() + 543}`

                } else {

                    return `วันที่ ${dateStart.getDate()} - ${dateEnd.getDate()} ${this.onMonth(dateStart.getMonth() + 1)} ${dateStart.getFullYear() + 543}`

                }

            } else if (dateStart.getMonth() !== dateEnd.getMonth()) {

                return `วันที่ ${dateStart.getDate()}  ${this.onMonth(dateStart.getMonth() + 1)} - ${dateEnd.getDate()} ${this.onMonth(dateEnd.getMonth() + 1)} ${dateStart.getFullYear() + 543}`

            }
        } else if (dateStart.getFullYear() !== dateEnd.getFullYear()) {

            if (dateStart.getMonth() === dateEnd.getMonth()) {

                return `วันที่ ${dateStart.getDate()} - ${dateEnd.getDate()} ${this.onMonth(dateStart.getMonth() + 1)} ${dateStart.getFullYear() + 543}`

            } else if (dateStart.getMonth() !== dateEnd.getMonth()) {

                return `วันที่ ${dateStart.getDate()}  ${this.onMonth(dateStart.getMonth() + 1)} ${dateStart.getFullYear() + 543} - ${dateEnd.getDate()} ${this.onMonth(dateEnd.getMonth() + 1)} ${dateEnd.getFullYear() + 543}`

            }
        }
    }

    onMonth = (month) => {
        let data;
        switch (month) {
            case 1: data = 'มกราคม'; break;
            case 2: data = 'กุมภาพันธ์'; break;
            case 3: data = 'มีนาคม'; break;
            case 4: data = 'เมษายน'; break;
            case 5: data = 'พฤษภาคม'; break;
            case 6: data = 'มิถุนายน'; break;
            case 7: data = 'กรกฎาคม'; break;
            case 8: data = 'สิงหาคม'; break;
            case 9: data = 'กันยายน'; break;
            case 10: data = 'ตุลาคม'; break;
            case 11: data = 'พฤศจิกายน'; break;
            case 12: data = 'ธันวาคม'; break;
            default: data = 'Invalid month'; // Handle invalid month input
        }

        return data;
    }

    onAmphure = async (obj) => {
        await this.promisedSetState({ amphur: [] })

        this.data = []
        Thailand.filter((response) => response.id === obj.event)
            .map((province) => province.amphure.filter((amphur) => amphur.province_id === obj.event)
                .map((item) => this.data.push({ province: obj.event, amphur: item.id, label: item.name_th, value: item.name_th })))

        await this.promisedSetState(prevState => ({
            amphur: [...prevState.amphur, { id: obj.question, data: this.data }]
        }))
    }

    onTambon = async (obj) => {
        await this.promisedSetState({ tambon: [] })

        this.data = []
        Thailand.filter((response) => response.id === obj.province)
            .map((province) => province.amphure.filter((amphur) => amphur.province_id === obj.province)
                .map((amphure) => amphure.tambon.filter((tambon) => tambon.amphure_id === obj.amphur)
                    .map((item) => this.data.push({ id: item.id, label: item.name_th, value: item.name_th }))))

        await this.promisedSetState(prevState => ({
            tambon: [...prevState.tambon, { id: obj.question, data: this.data }]
        }))
    }

    onQuestions = (obj) => {

        // get Results
        const index = this.state.Result.findIndex(({ category, id }) => category === obj.category && id === obj.id);
        if (index === -1) {
            if (obj.type === 'short'
                || obj.type === 'long'
                || obj.type === 'number'
                || obj.type === 'dropdown'
                || obj.type === 'date'
                || obj.type === 'id_card_number'
            ) {
                this.state.Result.push({
                    index: obj.index,
                    id: obj.id,
                    default: obj.default,
                    class: obj.class,
                    category: obj.category,
                    type: obj.type,
                    required: obj.required,
                    status: obj.status,
                    result: obj.value !== null && obj.default.status === true ? obj.value[obj.default.key] : ''
                })
            } else if (obj.type === 'multiple_choice') {
                this.state.Result.push({
                    index: obj.index,
                    id: obj.id,
                    default: obj.default,
                    class: obj.class,
                    category: obj.category,
                    type: obj.type,
                    required: obj.required,
                    status: obj.status,
                    result: obj.value !== null && obj.default.status === true ? { value: JSON.parse(obj.value[obj.default.key]).value, option: [] } : [{ value: null, option: [] }]
                })
            } else if (obj.type === 'checkboxes') {
                this.state.Result.push({
                    index: obj.index,
                    id: obj.id,
                    default: obj.default,
                    class: obj.class,
                    category: obj.category,
                    type: obj.type,
                    required: obj.required,
                    status: obj.status,
                    result: obj.value !== null && obj.default.status === true ? [{ value: JSON.parse(obj.value[obj.default.key]).value, more: JSON.parse(obj.value[obj.default.key]).more }] : [{ value: null, option: [] }]
                })
            } else if (obj.type === 'address') {
                this.state.Result.push({
                    index: obj.index,
                    id: obj.id,
                    default: obj.default,
                    class: obj.class,
                    category: obj.category,
                    type: obj.type,
                    required: obj.required,
                    status: obj.status,
                    result: obj.value !== null && obj.default.status === true ? obj.value[obj.default.key] : {
                        house_no: '',
                        village_no: '',
                        village: '',
                        lane: '',
                        road: '',
                        tambon: '',
                        amphur: '',
                        province: ''
                    }
                })

                if (obj.value !== null && obj.default.status === true) {
                    if (obj.value[obj.default.key].amphur !== undefined || obj.value[obj.default.key].amphur === '') {
                        this.state.amphur.push({ id: obj.id, data: [{ province: 0, amphur: 0, label: obj.value[obj.default.key].amphur, value: obj.value[obj.default.key].amphur }] })
                    }
                }

                if (obj.value !== null && obj.default.status === true) {
                    if (obj.value[obj.default.key].tambon !== undefined || obj.value[obj.default.key].tambon === '') {
                        this.state.tambon.push({ id: obj.id, data: [{ label: obj.value[obj.default.key].tambon, value: obj.value[obj.default.key].tambon }] })
                    }
                }
            }
        }

        // types Elements
        this.data = []
        switch (obj.type) {
            case 'short': this.data = <Card className={obj.class}
                style={{ backgroundColor: '#FFF' }}
                bordered>
                <Card.Body>
                    <Card.Title>
                        <b>{obj.index + 1}. {obj.label} {obj.required === 1 && <span className="text-danger">*</span>}</b>
                    </Card.Title>
                    <Input name={obj.class}
                        defaultValue={this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result)}
                        onChange={(e) => this.onResult({
                            index: obj.index
                            , class: obj.class
                            , id: obj.id
                            , category: obj.category
                            , type: obj.type
                            , required: obj.required
                            , status: obj.status
                            , event: e
                        })} />
                </Card.Body>
            </Card>

                break;

            case 'long': this.data = <Card className={obj.class}
                style={{ backgroundColor: '#FFF' }}
                bordered>
                <Card.Body>
                    <Card.Title>
                        <b>{obj.index + 1}. {obj.label} {obj.required === 1 && <span className="text-danger">*</span>}</b>
                    </Card.Title>
                    <Input name={obj.class}
                        defaultValue={this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result)}
                        as="textarea"
                        rows={5}
                        onChange={(e) => this.onResult({
                            index: obj.index
                            , class: obj.class
                            , id: obj.id
                            , category: obj.category
                            , type: obj.type
                            , required: obj.required
                            , status: obj.status
                            , event: e
                        })} />
                </Card.Body>
            </Card>

                break;

            case 'number': this.data = <Card className={obj.class}
                style={{ backgroundColor: '#FFF' }}
                bordered>
                <Card.Body>
                    <Card.Title>
                        <b>{obj.index + 1}. {obj.label} {obj.required === 1 && <span className="text-danger">*</span>}</b>
                    </Card.Title>
                    <InputNumber name={obj.class}
                        defaultValue={this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result)}
                        step={1}
                        onChange={(e) => this.onResult({
                            index: obj.index
                            , class: obj.class
                            , id: obj.id
                            , category: obj.category
                            , type: obj.type
                            , required: obj.required
                            , status: obj.status
                            , event: e
                        })} />
                </Card.Body>
            </Card>

                break;

            case 'multiple_choice': const exists = this.state.multichoiceOptions.some(item => item.id === obj.id);

                // Push the new object only if it does not exist
                if (!exists) {
                    this.state.multichoiceOptions.push({ id: obj.id, value: null, option: [] });
                }

                this.data = <Card className={obj.class}
                    style={{ backgroundColor: '#FFF' }}
                    bordered>
                    <Card.Body>
                        <Card.Title>
                            <b>{obj.index + 1}. {obj.label} {obj.required === 1 && <span className="text-danger">*</span>}</b>
                        </Card.Title>
                        <RadioGroup name={obj.class}
                            defaultValue={`${this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result.value)[0]}`}
                            onChange={async (e) => {
                                // this.state.Result.filter((data) => data.class === obj.class).map(item => item.result)
                                // Create an array of promises from the map operation
                                const promises = this.state.multichoiceOptions.filter((data) => data.id === obj.id).map(async (item) => {
                                    item.value = e;
                                    // Assuming the item update is synchronous; if it's async, include it here.
                                });

                                // Wait for all promises to resolve
                                await Promise.all(promises);

                                // Call the onResult function after all promises are resolved
                                await this.onResult({
                                    index: obj.index,
                                    class: obj.class,
                                    id: obj.id,
                                    category: obj.category,
                                    type: obj.type,
                                    required: obj.required,
                                    status: obj.status,
                                    event: this.state.multichoiceOptions.filter((data) => data.id === obj.id).map(item => item)
                                });
                            }}>
                            {obj.opt.map((option, optionIndex) => {

                                this.state.multichoiceOptions.forEach(item => {
                                    // Check if the item with the given id already exists
                                    const exists = item.option.some(opt => opt.id === obj.id && opt.index === optionIndex);

                                    // Push the new item only if it does not exist
                                    if (!exists) {
                                        item.option.push({ id: obj.id, index: optionIndex, name: option.name, more: null });
                                    }
                                });

                                return (
                                    <>
                                        <Radio value={option.name}><Text weight="thin"><span style={{ lineHeight: 1.6 }}>{option.name}</span></Text></Radio>{' '}
                                        {option.more === 1 && (
                                            <>
                                                <Stack
                                                    spacing={6}
                                                    direction={'row'}
                                                    alignItems={'center'}
                                                    justifyContent={'flex-start'}
                                                >
                                                    <div><Text weight="thin">โปรดระบุ</Text></div>
                                                    <Input
                                                        defaultValue={`${this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result.value === option.name && item.result.more)[0] === false ? '' : this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result.value === option.name && item.result.more)[0]}`}
                                                        onChange={async e => {
                                                            try {
                                                                // Perform asynchronous operations inside the map function
                                                                await Promise.all(this.state.multichoiceOptions.filter((data) => data.id === obj.id).map(async res => {
                                                                    await Promise.all(res.option.filter(item => item.id === obj.id && item.index === optionIndex).map(async item => {
                                                                        // Assuming you might have an async operation here
                                                                        item.more = e;
                                                                        // If there were asynchronous operations to perform, you'd `await` them here
                                                                    }));
                                                                }));

                                                                // await this.state.multichoiceOptions.filter((data) => data.id === obj.id && data.index === optionIndex).map(item =>
                                                                //     item.value === null && (item.value = option.name)
                                                                // )

                                                                // Call the onResult function and await its completion
                                                                await this.onResult({
                                                                    index: obj.index,
                                                                    class: obj.class,
                                                                    id: obj.id,
                                                                    category: obj.category,
                                                                    type: obj.type,
                                                                    required: obj.required,
                                                                    status: obj.status,
                                                                    event: this.state.multichoiceOptions.filter((data) => data.id === obj.id).map(item => item)
                                                                });

                                                            } catch (error) {
                                                                console.error('Error handling result:', error);
                                                            }
                                                        }}
                                                    />
                                                </Stack>
                                            </>
                                        )}
                                    </>
                                )
                            })}
                        </RadioGroup>
                    </Card.Body>
                </Card>

                break;

            case 'checkboxes': const checkboxesExists = this.state.checkboxesOptions.some(item => item.id === obj.id);

                // Push the new object only if it does not exist
                if (!checkboxesExists) {
                    this.state.checkboxesOptions.push({ id: obj.id, value: null, option: [] });
                }

                this.data = <Card className={obj.class}
                    style={{ backgroundColor: '#FFF' }}
                    bordered>
                    <Card.Body>
                        <Card.Title>
                            <b>{obj.index + 1}. {obj.label} {obj.required === 1 && <span className="text-danger">*</span>}</b>
                        </Card.Title>
                        <CheckboxGroup name={obj.class}
                            defaultValue={this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result[0].value)[0]}
                            onChange={async (e) => {

                                // Create an array of promises from the map operation
                                const promises = this.state.checkboxesOptions.filter((data) => data.id === obj.id).map(async (item) => {
                                    item.value = e;
                                    item.more = this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result[0].more)[0];
                                    // Assuming the item update is synchronous; if it's async, include it here.
                                });

                                // Wait for all promises to resolve
                                await Promise.all(promises);

                                // Call the onResult function after all promises are resolved
                                await this.onResult({
                                    index: obj.index,
                                    class: obj.class,
                                    id: obj.id,
                                    category: obj.category,
                                    type: obj.type,
                                    required: obj.required,
                                    status: obj.status,
                                    event: this.state.checkboxesOptions.filter((data) => data.id === obj.id).map(item => item)
                                });
                            }}>
                            {obj.opt.map((option, optionIndex) => {
                                // Update the value of existing items
                                this.state.checkboxesOptions
                                    .filter(data => data.id === obj.id)
                                    .forEach(item => {
                                        item.value = this.state.Result
                                            .filter(data => data.class === obj.class)
                                            .map(data => data.result[0].value)[0];
                                    });

                                // Push to the option array only if the combination of id and optionIndex does not already exist
                                this.state.checkboxesOptions
                                    .filter(data => data.id === obj.id)
                                    .forEach(item => {
                                        // Check if the option with the same index already exists
                                        const optionExists = item.option.some(opt => opt.index === optionIndex);

                                        if (!optionExists) {
                                            item.option.push({
                                                index: optionIndex,
                                                name: option.name,
                                                more: this.state.Result
                                                    .filter(res => res.class === obj.class)
                                                    .map(data => data.result[0].option
                                                        .filter(opt => opt.index === optionIndex)
                                                        .map(items => items.more))[0]
                                            });
                                        }
                                    });

                                return (
                                    <>
                                        <Checkbox value={option.name}><Text weight="thin"><span style={{ lineHeight: 1.6 }}>{option.name}</span></Text></Checkbox>{' '}
                                        {option.more === 1 && (
                                            <>
                                                <Stack
                                                    spacing={6}
                                                    direction={'row'}
                                                    alignItems={'center'}
                                                    justifyContent={'flex-start'}
                                                >
                                                    <div><Text weight="thin">โปรดระบุ</Text></div>
                                                    <Input
                                                        defaultValue={`${this.state.Result
                                                            .filter((data) => data.class === obj.class) // Filter by the class
                                                            .map((res) => {
                                                                // Check if res.result has at least one element and res.result[0].value is not null
                                                                const value = res.result?.[0]?.value;
                                                                return value ? value.indexOf(option.name) !== -1 : false;
                                                            }) === false ? '' : this.state.Result.filter((data) => data.class === obj.class)
                                                                .map(((res) => {
                                                                    return this.getMoreValue(res.result?.[0]?.option, option.name)
                                                                }))}`}
                                                        onChange={async e => {
                                                            try {

                                                                // Perform asynchronous operations inside the map function
                                                                await Promise.all(this.state.checkboxesOptions.filter((data) => data.id === obj.id).map(async res => {
                                                                    await Promise.all(res.option.filter(item => item.index === optionIndex).map(async item => {
                                                                        // Assuming you might have an async operation here
                                                                        item.more = e;
                                                                        // If there were asynchronous operations to perform, you'd `await` them here
                                                                    }));
                                                                }));

                                                                //  await this.checkboxesOptions.map(item => 
                                                                //      item.value === null && (item.value = option.name)
                                                                //  )

                                                                // Call the onResult function and await its completion
                                                                await this.onResult({
                                                                    index: obj.index,
                                                                    class: obj.class,
                                                                    id: obj.id,
                                                                    category: obj.category,
                                                                    type: obj.type,
                                                                    required: obj.required,
                                                                    status: obj.status,
                                                                    event: this.state.checkboxesOptions.filter((data) => data.id === obj.id).map(item => item)
                                                                });
                                                            } catch (error) {
                                                                console.error('Error handling result:', error);
                                                            }
                                                        }}
                                                    />
                                                </Stack>
                                            </>
                                        )}
                                    </>
                                )
                            })}
                        </CheckboxGroup>
                    </Card.Body>
                </Card>

                break;

            case 'dropdown': this.data = <Card className={obj.class}
                style={{ backgroundColor: '#FFF' }}
                bordered>
                <Card.Body>
                    <Card.Title>
                        <b>{obj.index + 1}. {obj.label} {obj.required === 1 && <span className="text-danger">*</span>}</b>
                    </Card.Title>
                    <SelectPicker name={obj.class}
                        size='lg'
                        data={obj.opt.map((option) => ({ label: option.name, value: option.name }))}
                        defaultValue={this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result)[0]}
                        searchable={false}
                        style={{ width: 300 }}
                        placeholder="กรุณาเลือกรายการ"
                        onChange={(e) => this.onResult({
                            index: obj.index
                            , class: obj.class
                            , id: obj.id
                            , category: obj.category
                            , type: obj.type
                            , required: obj.required
                            , status: obj.status
                            , event: e
                        })}
                    />
                </Card.Body>
            </Card>

                break;

            case 'date': this.data = <Card className={obj.class}
                style={{ backgroundColor: '#FFF' }}
                bordered>
                <Card.Body>
                    <Card.Title>
                        <b>{obj.index + 1}. {obj.label} {obj.required === 1 && <span className="text-danger">*</span>}</b>
                    </Card.Title>
                    <DatePicker name={obj.class}
                        defaultValue={this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result)[0]}
                        oneTap
                        format="dd/MM/yyyy"
                        onChange={(e) => this.onResult({
                            index: obj.index,
                            class: obj.class,
                            id: obj.id,
                            category: obj.category,
                            type: obj.type,
                            required: obj.required,
                            status: obj.status,
                            event: e
                        })}
                        onClean={() => this.onResult({
                            index: obj.index,
                            class: obj.class,
                            id: obj.id,
                            category: obj.category,
                            type: obj.type,
                            required: obj.required,
                            status: obj.status,
                            event: ''
                        })} />
                </Card.Body>
            </Card>

                break;

            case 'id_card_number': this.data = <Card className={obj.class}
                style={{ backgroundColor: '#FFF' }}
                bordered>
                <Card.Body>
                    <Card.Title>
                        <b>{obj.index + 1}. {obj.label} {obj.required === 1 && <span className="text-danger">*</span>}</b>
                    </Card.Title>
                    <MaskedInput
                        mask={[/\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/]}
                        guide={true}
                        showMask={[/\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/]}
                        keepCharPositions={true}
                        style={{ width: 300 }}
                        defaultValue={this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result)[0]}
                        onChange={(e) => this.onResult({
                            index: obj.index
                            , class: obj.class
                            , id: obj.id
                            , category: obj.category
                            , type: obj.type
                            , required: obj.required
                            , status: obj.status
                            , event: e
                        })}
                    />
                </Card.Body>
            </Card>

                break;

            case 'address': this.data = <Card className={obj.class}
                style={{ backgroundColor: '#FFF' }}
                bordered>
                <Card.Body>
                    <Card.Title>
                        <b>{obj.index + 1}. {obj.label} {obj.required === 1 && <span className="text-danger">*</span>}</b>
                    </Card.Title>

                    <Stack spacing={6}>
                        <Row>
                            <Col><label><Text weight="thin">บ้านเลขที่</Text></label>
                                <Input name="house_no" size='sm'
                                    defaultValue={this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result['house_no'])}
                                    style={{ width: 80 }}
                                    onChange={(e) => this.onResult({
                                        index: obj.index,
                                        class: md5(`${obj.index}MERGE${obj.class}`),
                                        id: obj.id,
                                        category: obj.category,
                                        type: obj.type,
                                        required: obj.required,
                                        status: obj.status,
                                        event: { key: 'house_no', e: e }
                                    })} />
                            </Col>

                            <Col><label><Text weight="thin">หมู่ที่</Text></label>
                                <InputNumber name="village_no" size='md'
                                    defaultValue={this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result['village_no'])}
                                    min={0}
                                    formatter={this.toInterger}
                                    style={{ width: 80 }}
                                    onChange={(e) => this.onResult({
                                        index: obj.index,
                                        class: md5(`${obj.index}MERGE${obj.class}`),
                                        id: obj.id,
                                        category: obj.category,
                                        type: obj.type,
                                        required: obj.required,
                                        status: obj.status,
                                        event: { key: 'village_no', e: e }
                                    })} />
                            </Col>

                            <Col><label><Text weight="thin">หมู่บ้าน</Text></label>
                                <Input name="village" size='sm'
                                    defaultValue={this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result['village'])}
                                    style={{ width: 200 }}
                                    onChange={(e) => this.onResult({
                                        index: obj.index,
                                        class: md5(`${obj.index}MERGE${obj.class}`),
                                        id: obj.id,
                                        category: obj.category,
                                        type: obj.type,
                                        required: obj.required,
                                        status: obj.status,
                                        event: { key: 'village', e: e }
                                    })} />
                            </Col>

                            <Col><label><Text weight="thin">ตรอก/ซอย</Text></label>
                                <Input name="lane" size='sm'
                                    defaultValue={this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result['lane'])}
                                    style={{ width: 200 }}
                                    onChange={(e) => this.onResult({
                                        index: obj.index,
                                        class: md5(`${obj.index}MERGE${obj.class}`),
                                        id: obj.id,
                                        category: obj.category,
                                        type: obj.type,
                                        required: obj.required,
                                        status: obj.status,
                                        event: { key: 'lane', e: e }
                                    })} />
                            </Col>

                            <Col><label><Text weight="thin">ถนน</Text></label>
                                <Input name="road" size='sm'
                                    defaultValue={this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result['road'])}
                                    style={{ width: 200 }}
                                    onChange={(e) => this.onResult({
                                        index: obj.index,
                                        class: md5(`${obj.index}MERGE${obj.class}`),
                                        id: obj.id,
                                        category: obj.category,
                                        type: obj.type,
                                        required: obj.required,
                                        status: obj.status,
                                        event: { key: 'road', e: e }
                                    })} />
                            </Col>
                        </Row>
                    </Stack>

                    <Stack spacing={6}>
                        <Row>
                            <Col className='mt-2'>
                                <label><Text weight="thin">จังหวัด{' '}<span className="text-danger">*</span></Text></label>
                                <div><SelectPicker
                                    name="province"
                                    data={Thailand.map((province) => ({ id: province.id, label: province.name_th, value: province.name_th }))}
                                    defaultValue={this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result['province'])[0]}
                                    style={{ width: 240 }}
                                    placeholder="กรุณาเลือกจังหวัด"
                                    onSelect={async (v, e) => {
                                        await this.onAmphure({ question: obj.id, event: e.id })

                                        const tambonIndex = this.state.tambon.findIndex(({ id }) => id === obj.id);
                                        if (tambonIndex !== -1) {
                                            // Create a new array without modifying the original
                                            const updatedTambon = [...this.state.tambon];
                                            updatedTambon.splice(tambonIndex, 1);

                                            // Update state using setState with the new array
                                            this.setState({ tambon: updatedTambon });
                                        }

                                        this.onResult({
                                            index: obj.index,
                                            class: md5(`${obj.index}MERGE${obj.class}`),
                                            id: obj.id,
                                            category: obj.category,
                                            type: obj.type,
                                            required: obj.required,
                                            status: obj.status,
                                            event: { key: 'province', e: v }
                                        })
                                    }}
                                    onClean={() => {
                                        const amphurIndex = this.state.amphur.findIndex(({ id }) => id === obj.id);
                                        if (amphurIndex !== -1) {
                                            // Create a new array without modifying the original
                                            const updatedAmphur = [...this.state.amphur];
                                            updatedAmphur.splice(amphurIndex, 1);

                                            // Update state using setState with the new array
                                            this.setState({ amphur: updatedAmphur });
                                        }

                                        const tambonIndex = this.state.tambon.findIndex(({ id }) => id === obj.id);
                                        if (tambonIndex !== -1) {
                                            // Create a new array without modifying the original
                                            const updatedTambon = [...this.state.tambon];
                                            updatedTambon.splice(tambonIndex, 1);

                                            // Update state using setState with the new array
                                            this.setState({ tambon: updatedTambon });
                                        }

                                        this.onResult({
                                            index: obj.index,
                                            class: md5(`${obj.index}MERGE${obj.class}`),
                                            id: obj.id,
                                            category: obj.category,
                                            type: obj.type,
                                            required: obj.required,
                                            status: obj.status,
                                            event: { key: 'province', e: '' }
                                        })
                                    }}
                                />
                                </div>
                            </Col>

                            <Col className='mt-2'>
                                <label><Text weight="thin">อำเภอ/เขต{' '}<span className="text-danger">*</span></Text></label>
                                <div><SelectPicker
                                    name="amphur"
                                    data={this.state.amphur.filter(response => response.id === obj.id).map(item => item.data)[0]}
                                    defaultValue={this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result['amphur'])[0]}
                                    style={{ width: 240 }}
                                    placeholder="กรุณาเลือกอำเภอ/เขต"
                                    onSelect={(v, e) => {
                                        this.onTambon({ question: obj.id, province: e.province, amphur: e.amphur })
                                        this.onResult({
                                            index: obj.index,
                                            class: md5(`${obj.index}MERGE${obj.class}`),
                                            id: obj.id,
                                            category: obj.category,
                                            type: obj.type,
                                            required: obj.required,
                                            status: obj.status,
                                            event: { key: 'amphur', e: v }
                                        })
                                    }}
                                    onClean={() => {
                                        const tambonIndex = this.state.tambon.findIndex(({ id }) => id === obj.id);
                                        if (tambonIndex !== -1) {
                                            // Create a new array without modifying the original
                                            const updatedTambon = [...this.state.tambon];
                                            updatedTambon.splice(tambonIndex, 1);

                                            // Update state using setState with the new array
                                            this.setState({ tambon: updatedTambon });
                                        }

                                        this.onResult({
                                            index: obj.index,
                                            class: md5(`${obj.index}MERGE${obj.class}`),
                                            id: obj.id,
                                            category: obj.category,
                                            type: obj.type, event: { key: 'amphur', e: '' },
                                            required: obj.required,
                                            status: obj.status,
                                        })
                                    }}
                                />
                                </div>
                            </Col>

                            <Col className='mt-2'>
                                <label><Text weight="thin">ตำบล/แขวง{' '}<span className="text-danger">*</span></Text></label>
                                <div><SelectPicker
                                    name="tambon"
                                    data={this.state.tambon.filter(response => response.id === obj.id).map(item => item.data)[0]}
                                    defaultValue={this.state.Result.filter((data) => data.class === obj.class).map((item) => item.result['tambon'])[0]}
                                    style={{ width: 240 }}
                                    placeholder="กรุณาเลือกตำบล/แขวง"
                                    onSelect={(v, e) => {
                                        this.onResult({
                                            index: obj.index,
                                            class: md5(`${obj.index}MERGE${obj.class}`),
                                            id: obj.id,
                                            category: obj.category,
                                            type: obj.type, event: { key: 'tambon', e: v },
                                            required: obj.required,
                                            status: obj.status,
                                        })
                                    }}
                                    onClean={() => {
                                        this.onResult({
                                            index: obj.index,
                                            class: md5(`${obj.index}MERGE${obj.class}`),
                                            id: obj.id,
                                            category: obj.category,
                                            type: obj.type,
                                            event: { key: 'tambon', e: '' },
                                            required: obj.required,
                                            status: obj.status,
                                        })
                                    }}
                                />
                                </div>
                            </Col>
                        </Row>
                    </Stack>
                </Card.Body>
            </Card>

                break;

            default: this.data = 'Invalid';
        }

        return this.data
    }

    getMoreValue = (resultArray, searchName) => {
        if (resultArray === undefined || resultArray === '') {
            return ''
        } else {
            return resultArray.filter((item) => item.name === searchName).map(item => item.more);
        }
    };

    onResult = (obj) => {
        this.state.Result.forEach((item) => {
            if (item.category === obj.category && item.id === obj.id) {
                if (obj.type === 'address') {
                    item.result[obj.event.key] = obj.event.e;
                } else if (obj.type === 'multiple_choice') {
                    item.result = {
                        value: obj.event.map(eventItem => eventItem.value),
                        more: obj.event.map(eventItem =>
                            eventItem.option.filter(optionItem => optionItem.name === eventItem.value)
                                .map(filteredItem => filteredItem.more)[0]
                        )
                    };
                } else if (obj.type === 'checkboxes') {
                    item.result = obj.event;
                } else {
                    item.result = obj.event;
                }
            }
        });
    }

    Validate = (params, clss, cond) => {
        if (params === cond) {
            $('.' + clss).addClass("required")
            Swal.fire({
                title: "กรุณากรอกข้อมูลให้ครบถ้วน",
                icon: "warning",
                timer: 2000,
                timerProgressBar: true,
                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            });
            return false
        } else if (params !== cond) {
            $('.' + clss).removeClass("required")
            return true
        }
    }

    thaiIDCheck = (id, clss) => {
        let idCardNumber = id.replace(/[_-]/g, '')

        if (typeof (idCardNumber) === "number") {
            idCardNumber = idCardNumber.toString();
        }

        const regex = /^[0-9]\d*$/;
        if (idCardNumber.length === 13 && idCardNumber.match(regex) && idCardNumber !== null && idCardNumber !== "undefined") {
            if (this.idCardCheck(idCardNumber) === true) {
                $('.' + clss).removeClass("required")
                return true
            } else {
                $('.' + clss).addClass("required")
                Swal.fire({
                    title: "กรุณากรอกหมายเลขบัตรประชาชนให้ถูกต้อง",
                    icon: "warning",
                    timer: 2000,
                    timerProgressBar: true,
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
                return false
            }
        } else {
            $('.' + clss).addClass("required")
            Swal.fire({
                title: "กรุณากรอกข้อมูลให้ครบถ้วน",
                icon: "warning",
                timer: 2000,
                timerProgressBar: true,
                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            });
            return false
        }

    }

    idCardCheck = (idCardNumber) => {
        let sum = 0;
        for (var i = 0; i < idCardNumber.length - 1; i++) {
            sum += parseInt(idCardNumber[i]) * (13 - i);
        }

        let checkDigit = (11 - (sum % 11)) % 10;

        if (checkDigit === parseInt(idCardNumber[12])) {
            return true;
        } else {
            return false;
        }
    }

    thaiIdExisting = async ({ id_card_number, activity_id, forms_id, clss }) => {
        return axios.post(`${process.env.REACT_APP_API}/participants/idcardnumber`, {
            id_card_number: id_card_number,
            activity_id: activity_id,
            forms_id: forms_id
        }).then(async response => {
            if (response.data.count === 0) {
                return true;
            } else {
                $('.' + clss).addClass("required");
                Swal.fire({
                    title: "ไม่สามารถลงทะเบียนซ้ำได้",
                    icon: "warning",
                    timer: 2000,
                    timerProgressBar: true,
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
                return false;
            }
        }).catch(error => {
            console.error("Error checking thaiIdExisting:", error);
            return false;
        });
    }

    async processItems(obj) {
        this.data = true;
        let validationPromises = [];

        this.state.Result.filter((res) => res.category === obj.category && res.status === 1 && res.required === 1).forEach((item) => {
            if (item.type === 'short'
                || item.type === 'long'
                || item.type === 'number'
                || item.type === 'dropdown'
            ) {
                this.data = this.data && this.Validate(item.result, item.class, '');
            } else if (item.type === 'id_card_number') {
                this.data = this.data && this.thaiIDCheck(item.result, item.class);
                validationPromises.push(this.thaiIdExisting({
                    id_card_number: item.result,
                    activity_id: obj.activity_id,
                    forms_id: obj.forms_id,
                    clss: item.class
                }).then(result => {
                    this.data = this.data && result;
                }));
            } else if (item.type === 'date') {
                this.data = this.data && this.Validate(item.result, item.class, '');
            } else if (item.type === 'multiple_choice') {
                this.data = this.data && this.Validate(item.result.value, item.class, undefined);
            } else if (item.type === 'checkboxes') {
                this.data = this.data && this.Validate(item.result[0].value, item.class, null);
                this.data = this.data && this.Validate(item.result[0].value, item.class, '');
            } else if (item.type === 'address') {
                this.data = this.data && this.Validate(item.result['province'], item.class, '');
                this.data = this.data && this.Validate(item.result['amphur'], item.class, '');
                this.data = this.data && this.Validate(item.result['tambon'], item.class, '');
            }
        });

        // Wait for all validation promises to complete
        await Promise.all(validationPromises);

        return this.data;
    }

    onNext = async (obj) => {
        const checkValidate = await this.processItems(obj)

        if (checkValidate === true) {

            await this.promisedSetState({ loading: true }); // Start loading

            this.state.Result.filter(res => res.default.status === true && res.default.key === 'IdCardNumber').map(async item => {
                await axios.get(`${process.env.REACT_APP_API}/participants/idcardnumber/${item.result}`).then(async (response) => {
                    if (response.data) {
                        await this.onRenderContent({
                            mode: 'insert'
                            , value: {
                                prefix: response.data.prefix
                                , firstName: response.data.first_name
                                , lastName: response.data.last_name
                                , age: response.data.age
                                , address: {
                                    house_no: response.data.house_no,
                                    village_no: response.data.village_no,
                                    village: response.data.village,
                                    lane: response.data.lane,
                                    road: response.data.road,
                                    tambon: response.data.tambon,
                                    amphur: response.data.amphur,
                                    province: response.data.province
                                }
                                , tel: response.data.tel
                                , line: response.data.line
                            }
                        }).then(async () => {
                            await this.promisedSetState({ loading: false }); // Stop loading
                            await this.promisedSetState({ step: this.state.step + 1 })
                        })
                    } else {
                        this.promisedSetState({ step: this.state.step + 1 })
                        await this.promisedSetState({ loading: false }); // Stop loading
                    }
                })
            })
        }
    }

    onPrevious = () => {
        this.setState({ step: this.state.step - 1 })
    }

    onConfirm = async (obj) => {
        if (obj.method === 'insert') {
            const checkValidate = await this.processItems(obj)

            if (checkValidate === true) {

                await this.promisedSetState({ loading: true }); // Start loading

                const IdCardNumber = this.state.Result.filter(response => response.default.status === true && response.default.key === "IdCardNumber").map(item => item.result)
                const consent = this.state.Result.filter(response => response.default.status === true && response.default.key === "consent").map(item => item.result)
                const prefix = this.state.Result.filter(response => response.default.status === true && response.default.key === "prefix").map(item => item.result)
                const firstName = this.state.Result.filter(response => response.default.status === true && response.default.key === "firstName").map(item => item.result)
                const lastName = this.state.Result.filter(response => response.default.status === true && response.default.key === "lastName").map(item => item.result)
                const age = this.state.Result.filter(response => response.default.status === true && response.default.key === "age").map(item => item.result)
                const address = this.state.Result.filter(response => response.default.status === true && response.default.key === "address").map(item => item.result)
                const tel = this.state.Result.filter(response => response.default.status === true && response.default.key === "tel").map(item => item.result)
                const line = this.state.Result.filter(response => response.default.status === true && response.default.key === "line").map(item => item.result)

                const house_no = address.map(item => item.house_no)
                const village_no = address.map(item => item.village_no)
                const village = address.map(item => item.village)
                const lane = address.map(item => item.lane)
                const road = address.map(item => item.road)
                const tambon = address.map(item => item.tambon)
                const amphur = address.map(item => item.amphur)
                const province = address.map(item => item.province)

                await axios.get(`${process.env.REACT_APP_API}/activities/path/${this.props.params.id}`)
                    .then(async response => {
                        await axios.post(`${process.env.REACT_APP_API}/participants`, {
                            id_card_number: IdCardNumber[0],
                            consent: consent[0],
                            prefix: prefix[0],
                            first_name: firstName[0],
                            last_name: lastName[0],
                            age: age[0],
                            house_no: house_no[0],
                            village_no: village_no[0],
                            village: village[0],
                            lane: lane[0],
                            road: road[0],
                            tambon: tambon[0],
                            amphur: amphur[0],
                            province: province[0],
                            tel: tel[0],
                            line: line[0],
                            activity_id: response.data.map(item => item.id)[0],
                            activity_name: response.data.map(item => item.name)[0],
                            forms_id: response.data.map(item => item.forms_id)[0],
                            forms_key: response.data.map(item => item.key)[0],
                            forms_results: this.state.Result,
                        })
                            .then(async (response) => {
                                if (response.data.message === 'successfully') {
                                    await this.onRenderContent({ mode: 'successfully' })
                                    await this.promisedSetState({ loading: false }); // Stop loading
                                }
                            })
                            .catch(error => console.log(error))
                    }
                    )
            }
        }
    }

    render() {
        return (
            <div className='forms-page'>
                {this.state.Forms}

                {/* Full-Page Loader */}
                {this.state.loading && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999 // Ensure it’s on top of other content
                    }}>
                        <Loader content="Loading..." vertical />
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(Forms);