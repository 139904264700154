import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet
} from 'react-router-dom';

// import Rsuite Style
import './css/rsuite.css';

// import Bootstrap Style
import './css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

// import Sweetalert2 Style
import './css/sweetalert2.css';

// import Pages
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import ActivitiesPage from './pages/activities';
import EditPage from './pages/edit';
import DetailsPage from './pages/details';
import ManagePage from './pages/manage';
import ParticipantsPage from './pages/participants';
import AssessmentPage from './pages/assessment';
import ApplicationPage from './pages/application';
import QuestionsPage from './pages/questions';
import HistoryPage from './pages/history';
import ReportPage from './pages/report';
import AdminPage from './pages/admin';
import FormsPage from './pages/forms';

// Authentication Check Component
const Authentication = () => {
  const location = useLocation();
  
  // Retrieve and parse the token from localStorage
  const storedTokenString = localStorage.getItem('token');
  const storedTokenData = storedTokenString ? JSON.parse(storedTokenString) : null;

  // Redirect to login if token is not valid or missing
  if (!storedTokenData || storedTokenData.status !== 'successfully') {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  // Render child routes if token is valid
  return <Outlet />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<Authentication />}>
          <Route path="/" element={<HomePage />}>
            <Route path="/activities" element={<ActivitiesPage />} />
            <Route path="/activities/:id" element={<EditPage />} />
            <Route path="/activities/details/:id" element={<DetailsPage />} />
            <Route path="/activities/participants/:id" element={<ParticipantsPage />} />
            <Route path="/activities/assessment/:id" element={<AssessmentPage />} />
            <Route path="/application" element={<ApplicationPage />} />
            <Route path="/application/manage/:id" element={<ManagePage />} />
            <Route path="/application/questions" element={<QuestionsPage />} />
            <Route path="/history" element={<HistoryPage />} />
            <Route path="/report" element={<ReportPage />} />
            <Route path="/admin" element={<AdminPage />} />
          </Route>
        </Route>
        <Route path="/forms/:id" element={<FormsPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
