import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
    Table
    , Form
    , TagPicker
    , Button
    , Toggle
    , Text
} from 'rsuite';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Papa from 'papaparse';

// import Sweetalert2
import Swal from 'sweetalert2'

export default class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: JSON.parse(localStorage.getItem('token')).token,
            content: null,
            sortColumn: '',
            sortType: '',
            loading: false,
            data: [],
            defaultColumns: [],
            customColumns: [],
        };
    }

    promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

    async componentDidMount() {
        try {
            // Ensure token is available
            if (!this.state.token) {
                throw new Error('No authentication token found');
            }

            // Add authHeader to the request
            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            // Fetch questions list with authHeader
            const response = await axios.get(`${process.env.REACT_APP_API}/questions/list`, authHeader);

            // Check if the response data is in expected format
            if (!response.data || !Array.isArray(response.data)) {
                throw new Error('Unexpected response format');
            }

            // Process the response data
            const columns = response.data.map(data => ({
                key: data.id,
                label: data.questions,
                fixed: false,
                align: 'left',
                sortable: true,
                resizable: true
            }));

            // Fetch additional results and update state
            const results = await this.fetchResults();

            await this.promisedSetState({
                defaultColumns: columns,
                data: results
            });

            await this.updateContent({ height: false })

        } catch (error) {
            // Handle the error using handleAuthFailure
            this.handleAuthFailure(error);
        }
    }

    // Handle authentication failure and other errors
    handleAuthFailure = (error) => {
        if (error.response && error.response.data.message === 'AuthenticationFailed') {
            window.location.replace('/login');
        } else {
            Swal.fire({
                title: "เกิดข้อผิดพลาด",
                text: error.message,
                icon: "error",
                confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
            });
        }
    };

    fetchResults = async () => {
        try {
            // Reset the state to empty array
            this.setState({ csvData: [] });

            // Define the authHeader
            const authHeader = { headers: { 'Authorization': `Bearer ${this.state.token}` } };

            // Make the API request with the authHeader
            const response = await axios.get(`${process.env.REACT_APP_API}/participants`, authHeader);

            // Process the results
            const results = response.data.flatMap((form, index) => {
                let arr = [];
                const formArr = JSON.parse(form.forms_results);

                formArr.forEach(element => {
                    // Find the index of the item with the same participantsID
                    const idx = arr.findIndex(i => i.participantsID === form.id);

                    let resultData;

                    if (element.type === 'address') {
                        const { house_no = '', village_no = '', village = '', lane = '', road = '', tambon, amphur, province } = element.result;
                        resultData = `${house_no ? `บ้านเลขที่ ${house_no} ` : ''}${village_no ? `หมู่ที่ ${village_no} ` : ''}${village ? `หมู่บ้าน ${village} ` : ''}${lane ? `ตรอก/ซอย ${lane} ` : ''}${road ? `ถนน ${road} ` : ''}ตำบล/แขวง${tambon} อำเภอ/เขต${amphur} จังหวัด${province}`;
                    }
                    else if (element.type === 'multiple_choice') {

                        resultData = element.result.more && element.result.more[0] !== null ? `${element.result.value}/${element.result.more}` : element.result.value;
                    }
                    else if (element.type === 'checkboxes') {
                        let listItem = [];
                        element.result.forEach(item => {
                            if (item.value) {
                                item.value.forEach(name => {
                                    const moreData = element.result
                                        .flatMap(r => r.option
                                            .filter(option => option.name === name)
                                            .map(option => option.more))
                                        .filter(more => more); // Filter out undefined or null values

                                    listItem.push(moreData.length && JSON.stringify(moreData[0]) !== '[]' ? `${name}/${moreData.join('/')}` : name);
                                });
                            }
                        });
                        resultData = listItem.join(', ');
                    }
                    else if (element.type === 'date') {

                        resultData = this.onDate({ data: element.result });
                    }
                    else {
                        resultData = element.result;
                    }

                    if (resultData) {
                        const newData = { [element.id]: resultData };

                        if (idx === -1) {
                            arr.push({ participantsID: form.id, data: newData });
                        } else {
                            arr[idx].data = { ...arr[idx].data, ...newData };
                        }
                    }
                });

                // Update state with new data
                this.setState(prevState => ({
                    csvData: { ...prevState.csvData, [index + 1]: arr.map(res => res.data) }
                }));

                return arr.map(res => res.data);
            });

            return results;
        } catch (error) {
            // Handle authentication and other errors
            if (error.response && error.response.data.message === 'AuthenticationFailed') {
                window.location.replace('/login');
            } else {
                Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: `<div style="font-size: 1.2rem">ตกลง</div>`,
                });
            }
        }
    }

    onDate = ({ data }) => {
        const date = new Date(data);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    updateContent = (obj) => {
        this.setState({
            content: (
                <div>
                    <Row className='mt-4'>
                        <Breadcrumb size='lg' separator={<AngleRightIcon />}>
                            <Breadcrumb.Item><Link to="/"><i className="bi bi-house-fill"></i></Link></Breadcrumb.Item>
                            <Breadcrumb.Item active>รายงาน</Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <Form>
                                <Form.Group>
                                    <Form.ControlLabel>กรุณาเลือกรายการ</Form.ControlLabel>
                                    <TagPicker
                                        size='md'
                                        data={this.state.defaultColumns}
                                        labelKey="label"
                                        valueKey="key"
                                        cleanable={false}
                                        placeholder='รายการ'
                                        block
                                        onChange={(e) => this.onSelected({ event: e, height: obj.height })}
                                    />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Text muted><Toggle onChange={(e) => {
                                this.updateContent({ height: e })
                            }} />{' '}Auto Height</Text>
                        </Col>
                        <Col className='text-end'>
                            <Button onClick={this.handleDownloadCSV} color="green" appearance="primary" startIcon={<i className="bi bi-table"></i>}>
                                Download
                            </Button>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Table
                                autoHeight={obj.height}
                                data={this.state.data}
                                bordered
                                cellBordered
                                sortColumn={this.state.sortColumn}
                                sortType={this.state.sortType}
                                loading={this.state.loading}
                                className='rounded shadow-sm'
                            >
                                {this.state.customColumns.map(column => {
                                    const { key, label, fixed, align, sortable, resizable } = column;
                                    return (
                                        <Table.Column key={key} align={align} fixed={fixed} sortable={sortable} resizable={resizable}>
                                            <Table.HeaderCell>{label}</Table.HeaderCell>
                                            <Table.Cell dataKey={key} />
                                        </Table.Column>
                                    );
                                })}
                            </Table>
                        </Col>
                    </Row>
                </div>
            )
        });
    }

    handleDownloadCSV = () => {
        // Extract the data for the CSV
        const { data, customColumns } = this.state;

        // Format the data to match CSV columns
        const formattedData = data.map(row => {
            const formattedRow = {};
            customColumns.forEach(col => {
                const key = col.key;
                const label = col.label;
                formattedRow[label] = row[key] || ''; // Ensure empty strings if no value
            });
            return formattedRow;
        });

        // Convert the data to CSV
        const csv = Papa.unparse(formattedData, {
            header: true
        });

        // Add BOM (Byte Order Mark) for UTF-8 encoding
        const bom = "\uFEFF";
        const csvWithBOM = bom + csv;

        // Create a blob and trigger download
        const blob = new Blob([csvWithBOM], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.randString(5)}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    onSelected = async (obj) => {
        const selectedColumns = this.state.defaultColumns.filter(column => obj.event.includes(column.key));
        await this.promisedSetState({ customColumns: selectedColumns });
        this.updateContent({ height: obj.height });
    }

    randString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    render() {
        return (
            <>
                {this.state.content}
                <br />
            </>
        );
    }
}